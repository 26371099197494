import React, { useEffect, useState } from 'react';
import { Alert, Button, Col, Container, Form, Modal, Row, Spinner, Table } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import SRTable from '../../../components/SRTable';
import {createTimeOff, getReservations, updateTimeOff} from '../../../helpers/api';
import {
  dateToDateValue,
  dateToTimeValue,
  dateValueToDate, END_OF_DAY,
  formatDateValue,
  timeValueToDate
} from '../../../helpers/date';
import { DAYS_OF_WEEK } from '../../../helpers/enums';
import { useToastNotifications } from '../../../helpers/notifications';
import translate from '../../../helpers/translations';
import './CenterTimeOffModal.css';
import Select from "react-select";

interface Props {
  show: boolean;
  timeOff: TimeOffOld | null;
  sportsCenterId: number;
  openingHours: OpeningHoursOld[];
  onHide: () => any,
  refresh: () => any;
  courts: CourtOld[];
}

interface FormValues {
  fromDateTime: string;
  toDateTime: string;
  reason?: string;
  sport?: Sport;
  courts: CourtOld[];
}

const CenterTimeOffModal: React.FC<Props> = ({ show, timeOff, sportsCenterId, openingHours, onHide, refresh, courts }) => {
  const { newToastNotification } = useToastNotifications();
  const [form, setForm] = useState<FormValues>({ fromDateTime: '', toDateTime: '', courts: [] });
  const [affectedReservations, setAffectedReservations] = useState<Reservation[] | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const [sportOptions, setSportOptions] = useState<any>([]);

  useEffect(() => {
    setAffectedReservations(null);
    if (timeOff) {
      setForm({ fromDateTime: timeOff.from, toDateTime: timeOff.to, reason: timeOff.reason, sport: timeOff.sport, courts: timeOff.courts });
    } else {
      setForm({ fromDateTime: '', toDateTime: '', courts: [] })
    }
  }, [timeOff]);

  useEffect(() => {
    if(form.courts && form.courts.length > 0) {
      const selectedCourts = courts.filter(c => form.courts.map(co => co.id).includes(c.id))
      // Create an array to store the unique sports from the first Court object
      const uniqueSports: Sport[] = selectedCourts.length > 0 ? Array.from(new Set(selectedCourts[0].sports)) : [];
      // Find the unique sports that are common among all Court objects
      for (let i = 1; i < selectedCourts.length; i++) {
        const currentCourt = selectedCourts[i];
        uniqueSports.splice(
            0,
            uniqueSports.length,
            ...uniqueSports.filter((sport) => currentCourt.sports.includes(sport))
        );
      }

      setSportOptions([
        { label: translate('allSports'), value: undefined },
        ...uniqueSports.map(s => ({ label: translate(s), value: s }))
      ]);
    } else {
      const allSports = courts.flatMap(court => court.sports);
      const uniqueSports = Array.from(new Set(allSports));
      setSportOptions([
        { label: translate('allSports'), value: undefined },
        ...uniqueSports.map(s => ({ label: translate(s), value: s })),
      ]);
    }
  }, [form.courts]);

  const submit = () => {
    setErrorMsg(null);
    setLoading(true);
    if (affectedReservations === null) {
      getReservations(sportsCenterId, 0, 100, {fromDateTime: form.fromDateTime, toDateTime: form.toDateTime})
        .then(({ data }) => {
          const affectedReservations = data
            .filter(r => !r.status.startsWith('CANCELLED'))
            .sort((x1, x2) => {
              if (x1.fromDateTime > x2.fromDateTime) {
                return 1;
              } else if (x2.fromDateTime > x1.fromDateTime) {
                return -1;
              } else {
                return 0;
              }
            });

          if (affectedReservations.length === 0) {
            doSubmit();
          } else {
            setAffectedReservations(affectedReservations);
            setLoading(false);
          }
        })
        .catch(({ response: { data } }) => {
          setLoading(false);
          if (data && data.message) {
            setErrorMsg(data.message);
          } else {
            setErrorMsg(translate('unexpectedError') + '.');
          }
          setLoading(false);
        });
    } else {
      doSubmit();
    }
  }

  const doSubmit = () => {
    (timeOff ? updateTimeOff(sportsCenterId, timeOff.id, form) : createTimeOff(sportsCenterId, form))
      .then(() => {
        setLoading(false);
        onHide();
        refresh();
        newToastNotification(translate(timeOff ? 'editTimeOff' : 'addTimeOff'), translate('operationSuccessful'));
      })
      .catch(({ response: { data } }) => {
        setLoading(false);
        if (data && data.message) {
          setErrorMsg(data.message);
        } else {
          setErrorMsg(translate('unexpectedError'));
        }
      })
  }

  const isAllowedDate = (date: Date) => {
    const dayOfWeek = DAYS_OF_WEEK[(date.getDay() + 6) % 7];
    return openingHours.some(oh => oh.dayOfWeek === dayOfWeek);
  }

  const isAllowedDatetime = (datetime: Date) => {
    const dayOfWeek = DAYS_OF_WEEK[(datetime.getDay() + 6) % 7];
    const timeString = dateToTimeValue(datetime);
    return openingHours.some(oh => oh.dayOfWeek === dayOfWeek && timeString >= oh.from && timeString <= oh.to);
  }

  const atAllowedTime = (date: Date) => {
    const dayOfWeek = DAYS_OF_WEEK[(date.getDay() + 6) % 7];
    const openingHoursAtDayOfWeek = openingHours.filter(oh => oh.dayOfWeek === dayOfWeek);
    const timeString = dateToTimeValue(date);
    if (openingHoursAtDayOfWeek.some(oh => timeString >= oh.from && timeString <= oh.to)) {
      return date;
    } else {
      const timeParts = openingHoursAtDayOfWeek[0].from.split(":");
      date.setHours(parseInt(timeParts[0]));
      date.setMinutes(parseInt(timeParts[1]));
      return date;
    }
  }

  const courtSelectOptions = courts.map(c => ({ label: c.name, value: c.id }));


  return <Modal show={show} dialogClassName={affectedReservations && affectedReservations.length > 0 ? 'add-time-off-wide-modal' : ''}>
    <Modal.Header><strong>{translate(timeOff ? 'editTimeOff' : 'addTimeOff')}</strong></Modal.Header>
    <Modal.Body>
      {affectedReservations === null &&
        <Form>
          <Container>
            {errorMsg && <Row>
              <Col>
                <Alert variant='danger' className='mt-2' style={{ fontSize: '0.85rem' }}>
                  <div style={{ fontWeight: '700' }}>{errorMsg}</div>
                </Alert>
              </Col>
            </Row>}
            <Row>
              <Col xs={6}>
                <DatePicker
                  placeholderText={translate('from')}
                  selected={form.fromDateTime !== '' ? dateValueToDate(form.fromDateTime) : null}
                  onChange={date => setForm({ ...form, fromDateTime: date ? dateToDateValue(atAllowedTime(date)) : '' })}
                  showTimeSelect
                  filterDate={isAllowedDate}
                  filterTime={isAllowedDatetime}
                  timeFormat='HH:mm'
                  timeIntervals={30}
                  dateFormat='dd.MM.yyyy. HH:mm'
                  customInput={<Form.Control />}
                />
              </Col>
              <Col xs={6}>
                <DatePicker
                  placeholderText={translate('to')}
                  selected={form.toDateTime !== '' ? dateValueToDate(form.toDateTime) : null}
                  onChange={date => setForm({ ...form, toDateTime: date ? dateToDateValue(atAllowedTime(date)) : '' })}
                  showTimeSelect
                  filterDate={isAllowedDate}
                  filterTime={isAllowedDatetime}
                  timeFormat='HH:mm'
                  timeIntervals={30}
                  injectTimes={[END_OF_DAY]}
                  dateFormat='dd.MM.yyyy. HH:mm'
                  customInput={<Form.Control />}
                />
              </Col>
            </Row>
            {/*
            <Row className='mt-2'>
              <Col>
                <Form.Group>
                  <Form.Label>{translate('courts')}</Form.Label>
                  <Select
                      isMulti
                      placeholder={translate('allCourts')}
                      value={courtSelectOptions.filter(option => form.courts.map(co => co.id).includes(option.value))}
                      options={courtSelectOptions}
                      onChange={selected => setForm({ ...form, courts: courts.filter(c => selected.map(s => s.value).includes(c.id)), sport: undefined })} />
                </Form.Group>
              </Col>
            </Row>
            <Row className='mt-2'>
              <Col>
                <Form.Group>
                  <Form.Label>{translate('sport')}</Form.Label>
                  <Select
                      placeholder={translate('sport')}
                      value={sportOptions.find((so: any) => so.value == form.sport) || null}
                      options={sportOptions}
                      onChange={e => {
                        console.log(e)
                        setForm({...form, sport: e.value as Sport | undefined})
                      }} />
                </Form.Group>
              </Col>
            </Row>
            <Row className='mt-2'>
              <Col>
                <Form.Group controlId="form.reason">
                  <Form.Label>{translate('reason')}</Form.Label>
                  <Form.Control type="text" value={form.reason}
                                onChange={e => setForm({...form, reason: e.target.value})}/>
                </Form.Group>
              </Col>
            </Row>
            */}
          </Container>
        </Form>}
      {affectedReservations !== null && <div>
        {errorMsg && <Row>
          <Col>
            <Alert variant='danger' style={{ fontSize: '0.85rem' }}>
              <div style={{ fontWeight: '700' }}>{errorMsg}</div>
            </Alert>
          </Col>
        </Row>}
        <div>{translate('reservationsAlreadyScheduled')}.</div>
        <div>{translate('proceedAddingTimeOff')}?</div>
        <div className="add-time-off-wide-modal-body">
            <SRTable>
              <thead>
                <tr>
                  <th>{translate('from')}</th>
                  <th>{translate('to')}</th>
                  <th>{translate('price')}</th>
                  <th>{translate('court')}</th>
                  <th>{translate('personOrEmail')}</th>
                </tr>
              </thead>
              <tbody>
                {affectedReservations
                  .map(ar => <tr key={ar.id}>
                    <td>{formatDateValue(ar.fromDateTime)}</td>
                    <td>{formatDateValue(ar.toDateTime)}</td>
                    <td>{ar.price} {ar.currency}</td>
                    <td>{ar.court.name}</td>
                    {ar.user && <td>
                      {ar.user.firstName && ar.user.lastName && <div>{ar.user.firstName} {ar.user.lastName}</div>}
                      <div>{ar.user.email}</div>
                      {ar.user.phoneNumber && <div>{ar.user.phoneNumber}</div>}
                    </td>}
                    {!ar.user && <td>{ar.email || '-'}</td>}
                  </tr>)}
              </tbody>
            </SRTable>
        </div>
        <Form className='p-2'>
          <Form.Control as='textarea' placeholder={translate('addCancellationReason')} rows={3} value={form.reason} onChange={e => setForm({ ...form, reason: e.target.value })} />
        </Form>
      </div>}
    </Modal.Body>
    <Modal.Footer>
      {loading && <Spinner animation='border' variant='secondary' className='mx-auto' />}
      {!loading && <>
        <Button variant='secondary' onClick={() => { onHide(); setErrorMsg(null); setAffectedReservations(null); }}>{translate('close')}</Button>
        <Button variant='primary' onClick={() => submit()}>{translate(affectedReservations && affectedReservations.length > 0 ? 'proceed' : 'save')}</Button>
      </>}
    </Modal.Footer>
  </Modal>
}

export default CenterTimeOffModal;