import React, {useEffect, useState} from 'react'
import {Button, Modal, Form, Container, Row, Col, Alert, Spinner} from 'react-bootstrap';
import translate from "../../helpers/translations";
import {getSportsCentersLabelsAdmin, sendNotification} from "../../helpers/api";
import {useToastNotifications} from "../../helpers/notifications";
import ConfirmationModal from "../../components/ConfirmationModal";

export interface INotificationNewProps {
  onHide: () => any
}

const emptyForm: IFcmMessage = ({
  title: '',
  body: '',
});

const NotificationNew = (props: INotificationNewProps) => {
  const { newToastNotification } = useToastNotifications();
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const [notification, setNotification] = useState<IFcmMessage>(emptyForm);
  const [sportsCenters, setSportsCenters] = useState<SportsCenterLabel[]>([]);
  const [selectedScreenType, setSelectedScreenType] = useState<MobileAppScreen | null>(null);
  const [selectedSportsCenterId, setSelectedSportsCenterId] = useState<number | null>(null);
  const [show, setShow] = useState<boolean>(true);
  const [confirmation, setConfirmation] = useState<boolean>(false);

  useEffect(() => {
    getSportsCentersLabelsAdmin()
        .then(({ data }) => {
          setSportsCenters(data.filter(sc=> sc.visible == true));
        })
        .catch(() => { });
  }, []); // e

  const onHide = () => {
    props.onHide()
  }

  const handleSave = () => {
    let newArgs: Map<string, string> = new Map<string, string>()
    if(selectedScreenType) {
      newArgs.set('screenType', selectedScreenType)
    }
    if(selectedScreenType && selectedSportsCenterId) {
      newArgs.set('sportsCenterId', String(selectedSportsCenterId))
    }

    setErrorMsg(null);
    setLoading(true);
    sendNotification({...notification, args: Object.fromEntries(newArgs) })
        .then(() => {
          setLoading(false);
          onHide();
          setNotification(emptyForm);
        })
        .catch(({ response: { data } }) => {
          if (data && data.message) {
            setErrorMsg(data.message);
          } else {
            setErrorMsg(translate('unexpectedError') + '.');
          }
        })
        .finally(() => {
          setLoading(false)
        })
  }

  return <>
      <Modal show={show}>
      <Modal.Header>
        <strong>{translate('sendNotification')}</strong>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Container>
            {errorMsg && <Row>
              <Col>
                <Alert variant='danger' className='mt-2' style={{ fontSize: '0.85rem' }}>
                  <div style={{ fontWeight: '700' }}>{errorMsg}</div>
                </Alert>
              </Col>
            </Row>}
            <Row className='mt-2'>
              <Col>
                <Form.Label>{translate('title')}</Form.Label>
                <Form.Control value={notification ? notification.title : ''} onChange={e => notification && setNotification({ ...notification, title: e.target.value })} />
              </Col>
            </Row>
            <Row className='mt-2'>
              <Col>
                <Form.Label>{translate('text')}</Form.Label>
                <Form.Control as="textarea" value={notification ? notification.body : ''} onChange={e => notification && setNotification({ ...notification, body: e.target.value })} />
              </Col>
            </Row>
            <Row className='mt-2'>
            <Col>
              <Form.Label>{translate('image')}</Form.Label>
              <Form.Control value={notification ? notification.image : ''} onChange={e => notification && setNotification({ ...notification, image: e.target.value })} />
            </Col>
            </Row>
            <Row className='mt-2'>
              <Col>
                <Form.Label>{translate('screen')}</Form.Label>
                <Form.Select
                    value={selectedScreenType === null ? '' : selectedScreenType}
                    onChange={e => setSelectedScreenType(e.target.value as MobileAppScreen)}>
                  <option value={''}/>
                  <option value={'SPORTS_CENTER_DETAILS'}>{translate('SPORTS_CENTER_DETAILS')}</option>
                  <option value={'SPORTS_CENTER_SEARCH'}>{translate('SPORTS_CENTER_SEARCH')}</option>
                </Form.Select>
              </Col>
            </Row>
            {selectedScreenType &&
                <Row className='mt-2'>
                  <Col>
                    <Form.Label>{translate('sportsCenter')}</Form.Label>
                    <Form.Select
                        value={selectedSportsCenterId === null ? '' : selectedSportsCenterId}
                        onChange={e => setSelectedSportsCenterId(+e.target.value)}>
                      <option value={''}/>
                      {sportsCenters.map(sc => <option key={sc.id} value={`${sc.id}`}>{sc.name} ({translate('id')}={sc.id})</option>)}
                    </Form.Select>
                  </Col>
                </Row>
            }
          </Container>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        {loading && <Spinner animation='border' variant='secondary' className='mx-auto' />}
        {!loading && <>
          <Button variant='secondary' onClick={() => onHide()}>{translate('close')}</Button>
          <Button variant='primary' onClick={() => {
            setConfirmation(true)
            setShow(false)
          }}>{translate('save')}</Button>
        </>}
      </Modal.Footer>
    </Modal>
    {confirmation &&
        <ConfirmationModal
            title={translate('confirm')}
            body={translate('sureToSendNotificationToEveryone')}
            confirmButtonVariant='primary'
            onConfirm={() => handleSave()}
            onCancel={() => {
              setShow(true)
              setConfirmation(false);
            }}
        />
    }
  </>;

}

export default NotificationNew
