import React, { useEffect, useState } from 'react';
import {Button, Col, Form, Row} from 'react-bootstrap';
import ConfirmationModal from '../../../components/ConfirmationModal';
import {
  createOpeningHours,
  getReservations,
  getReservationsOutsideOpeningHours
} from '../../../helpers/api';
import {formatDateValue, openingHoursSorter, openingHoursSorterOld} from '../../../helpers/date';
import { useToastNotifications } from '../../../helpers/notifications';
import translate from '../../../helpers/translations';
import './CenterOpeningHours.css';
import CenterOpeningHoursModal from './CenterOpeningHoursModal';
import Splash from "../../Splash";
import SRTable from "../../../components/SRTable";

interface Props {
  sportsCenterId: number;
  openingHours: OpeningHoursOld[];
  refresh: () => any;
}

const CenterOpeningHours: React.FC<Props> = ({ sportsCenterId, openingHours, refresh }) => {
  const { newToastNotification } = useToastNotifications();
  const [openingHoursForm, setOpeningHoursForm] = useState<OpeningHours[]>(
      openingHours.map((oh) => ({
        id: oh.id,
        dayOfWeek: oh.dayOfWeek,
        fromTime: oh.from,
        toTime: oh.to
      }))
  );

  const [affectedReservations, setAffectedReservations] = useState<Reservation[] | null>(null);
  const [cancelReason, setCancelReason] = useState<string>('');
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [editingIndex, setEditingIndex] = useState<number | null>(null);
  const [editingOpeningHours, setEditingOpeningHours] = useState<OpeningHours | null>(null);
  const [deletingIndex, setDeletingIndex] = useState<number | null>(null);
  const [isModified, setIsModified] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const handleDelete = (index: number) => {
    // Delete the opening hour locally by filtering the list
    const updatedOpeningHours = [...openingHoursForm];
    updatedOpeningHours.splice(index, 1);
    setOpeningHoursForm(updatedOpeningHours.sort(openingHoursSorter));
    setDeletingIndex(null);
  };

  const handleEdit = (index: number, editedHour: OpeningHours) => {
    // Edit the opening hour locally by replacing the item at the specified index
    const updatedOpeningHours = [...openingHoursForm];
    updatedOpeningHours[index] = editedHour;
    setOpeningHoursForm(updatedOpeningHours.sort(openingHoursSorter));
    setEditingIndex(null);
    setEditingOpeningHours(null);
    setShowEditModal(false);
  };

  const handleAdd = (newOpeningHour: OpeningHours) => {
    // Add a new opening hour locally
    const updatedOpeningHours = [...openingHoursForm, newOpeningHour];
    setOpeningHoursForm(updatedOpeningHours.sort(openingHoursSorter));
    setShowEditModal(false);
  };

  useEffect(() => {
    const sortedOpeningHours1 = [...openingHours];
    const sortedOpeningHours2 = [...openingHoursForm];

    sortedOpeningHours1.sort(openingHoursSorterOld);
    sortedOpeningHours2.sort(openingHoursSorter);

    setIsModified(
        !(sortedOpeningHours1.length === sortedOpeningHours2.length &&
            sortedOpeningHours1.every((oh1, index) =>
                oh1.dayOfWeek === sortedOpeningHours2[index].dayOfWeek &&
                oh1.from === sortedOpeningHours2[index].fromTime &&
                oh1.to === sortedOpeningHours2[index].toTime
            )
        )
    )
  }, [openingHoursForm]);

  const checkReservationsAndSave = () => {
    setLoading(true)
    getReservationsOutsideOpeningHours(sportsCenterId, openingHoursForm)
        .then(({ data }) => {
          if(data.length === 0) {
            handleSave()
          } else {
            setAffectedReservations(data)
            setLoading(false)
          }
        })
        .catch(({ response: { data } }) => {
          if (data && data.message) {
            newToastNotification('Error', data.message)
          } else {
            newToastNotification('Error', translate('unexpectedError'))
          }
          setLoading(false)
        })
  }

  const handleSave = () => {
    setLoading(true)
    createOpeningHours(sportsCenterId,
        openingHoursForm.map(ohf => {
          ohf.reason = cancelReason
          return ohf
        })
    )
        .then(() => {
          setLoading(false);
          refresh();
          newToastNotification(translate('save'), translate('operationSuccessful') + '.');
        })
        .catch(({ response: { data } }) => {
          if (data && data.message) {
            newToastNotification('Error', data.message)
          } else {
            newToastNotification('Error', translate('unexpectedError'))
          }
        })
        .finally(() => setLoading(false))
  }



  const handleReset = () => {
    setOpeningHoursForm([
        ...openingHours.map((oh) => ({
          id: oh.id,
          dayOfWeek: oh.dayOfWeek,
          fromTime: oh.from,
          toTime: oh.to
        }))]
    )
  }

  useEffect(() => {
    const oh = openingHours.find(oh => oh.id === deletingIndex);
    if (oh) {
      getReservations(sportsCenterId, 0, 1000, {dayOfWeek: oh.dayOfWeek, fromTime: oh.from, toTime: oh.to})
        .then(({ data }) => {
          const affectedReservations =
            data
              .filter(r => !r.status.startsWith('CANCELLED'))
              .sort((x1, x2) => {
                if (x1.fromDateTime > x2.fromDateTime) {
                  return 1;
                } else if (x2.fromDateTime > x1.fromDateTime) {
                  return -1;
                } else {
                  return 0;
                }
              });
          setAffectedReservations(affectedReservations);
        })
        .catch(() => {
          setDeletingIndex(null);
          newToastNotification('Error', translate('unexpectedError'));
        });
    } else {
      setAffectedReservations(null);
    }
  }, [sportsCenterId, openingHours, deletingIndex]);

  return <div className='d-flex flex-column'>
    {loading && <Splash/>}
    {openingHoursForm.length === 0 && <h4 className='text-muted my-5 text-center'>{translate('noOpeningHours')}</h4>}
    {openingHoursForm.length > 0 &&
        <SRTable>
          <thead>
          <tr>
            <th style={{width: '25%'}}>{translate('dayOfWeek')}</th>
            <th style={{width: '25%', textAlign: 'center'}}>{translate('from')}</th>
            <th style={{width: '25%', textAlign: 'center'}}>{translate('to')}</th>
            <th style={{width: '25%'}}></th>
          </tr>
          </thead>
          <tbody>
          {openingHoursForm
              .sort(openingHoursSorter)
              .map((oh, index) => <tr key={index}>
                <td>{translate(oh.dayOfWeek)}</td>
                <td style={{textAlign: 'center'}}>{oh.fromTime}</td>
                <td style={{textAlign: 'center'}}>{oh.toTime}</td>
                <td className='text-end' style={{whiteSpace: 'nowrap'}}>
                  <Button size="sm" variant='secondary' className='mx-1' onClick={() => {
                    setEditingOpeningHours(oh);
                    setEditingIndex(index);
                    setShowEditModal(true);
                  }}>{translate('edit')}</Button>
                  <Button size="sm" variant='danger' className='mx-1' onClick={() => {
                    setDeletingIndex(index);
                  }}>{translate('delete')}</Button>
                </td>
              </tr>)}
          </tbody>
        </SRTable>
    }


    <Row>
      <Col className='p-1' lg={4} md={0}/>
      <Col className='p-1' lg={4} md={12} style={{textAlign: 'center'}}>
        <Button className='px-4' variant='secondary' onClick={() => {
          setEditingOpeningHours(null);
          setShowEditModal(true);
        }}>{translate('addOpeningHours')}</Button>
      </Col>
      <Col className='p-1' lg={4} md={12} style={{textAlign: 'center'}}>
        <Button disabled={!isModified} className='px-5 mx-1' variant='secondary'
                onClick={() => handleReset()}>
          {translate('close')}
        </Button>
        <Button disabled={!isModified} className='px-5' variant='primary'
                onClick={checkReservationsAndSave}>
          {translate('save')}
        </Button>
      </Col>
    </Row>

    {affectedReservations && affectedReservations.length > 0 &&
        <ConfirmationModal
            title='Confirm deleting'
            dialogClassName={'delete-opening-hours-wide-modal'}
            body={<div>
              {translate('confirmEditingOpeningHours')}?<br/>{translate('reservationsAlreadyScheduledDelete')}.
              <div className="delete-opening-hours-wide-modal-body">
                <SRTable>
                  <thead>
                  <tr>
                    <th>{translate('from')}</th>
                    <th>{translate('to')}</th>
                    <th>{translate('price')}</th>
                    <th>{translate('court')}</th>
                    <th>{translate('personOrEmail')}</th>
                  </tr>
                  </thead>
                  <tbody>
                  {affectedReservations.map(ar => <tr key={ar.id}>
                    <td>{formatDateValue(ar.fromDateTime)}</td>
                    <td>{formatDateValue(ar.toDateTime)}</td>
                    <td>{ar.price} {ar.currency}</td>
                    <td>{ar.court.name}</td>
                    {ar.user && <td>
                      {ar.user.firstName && ar.user.lastName &&
                          <div>{ar.user.firstName} {ar.user.lastName}</div>}
                      <div>{ar.user.email}</div>
                      {ar.user.phoneNumber && <div>{ar.user.phoneNumber}</div>}
                    </td>}
                    {!ar.user && <td>{ar.email || '-'}</td>}
                  </tr>)}
                  </tbody>
                </SRTable>
              </div>
              <Form className='p-2'>
                <Form.Control as='textarea' placeholder={translate('addCancellationReason')} rows={3}
                              value={cancelReason}
                              onChange={e => setCancelReason(e.target.value)}/>
              </Form>
            </div>}
            confirmButtonVariant='danger'
            onConfirm={handleSave}
            onCancel={() => setAffectedReservations([])}
        />}


    <CenterOpeningHoursModal
        show={showEditModal}
        openingHours={editingOpeningHours}
        onHide={() => setShowEditModal(false)}
        editingIndex={editingIndex}
        handleAdd={handleAdd}
        handleEdit={handleEdit}
        refresh={refresh}
    />

    {deletingIndex !== null && <ConfirmationModal
        title='Confirm deleting'
        body={translate('confirmDeletingOpeningHours')}
        confirmButtonVariant='danger'
        onConfirm={() => handleDelete(deletingIndex)}
        onCancel={() => setDeletingIndex(null)}
    />}

  </div>;
}

export default CenterOpeningHours;