import React, {useEffect, useState} from 'react';
import {Alert, Button, Col, Form, Row} from "react-bootstrap";
import translate from "../../../helpers/translations";
import {allTimezones, useTimezoneSelect} from "react-timezone-select";
import {getSportsCenter, updateSportsCenterAdmin} from "../../../helpers/api";
import {useToastNotifications} from "../../../helpers/notifications";
import {useNavigate} from "react-router-dom";

interface Props {
    sportsCenterId: number;
    refresh: () => any;
}

const toForm = (sc: SportsCenter): UpdateSportsCenterRequest => {
    return {
        id: sc.id,
        visible: sc.visible,
        name: sc.name,
        description: sc.description,
        latitude: sc.latitude,
        longitude: sc.longitude,
        address: sc.address,
        neighborhoodId: sc.neighborhood ? sc.neighborhood.id : null,
        cityId: sc.city ? sc.city.id : null,
        countryId: sc.country ? sc.country.id : null,
        parking: sc.parking,
        bar: sc.bar != undefined ? sc.bar : false,
        rating: sc.rating,
        stripeAccountId: sc.stripeAccountId,
        requiredPhoneNumber: sc.requiredPhoneNumber,
        halfHourSlotSupported: sc.halfHourSlotSupported,
        phoneNumber: sc.phoneNumber,
        website: sc.website,
        timeZone: sc.timeZone,
        galleryEnabled: sc.galleryEnabled,
        maskUsersEmail: sc.maskUsersEmail,
    }
};


const CenterInformation: React.FC<Props> = ({ refresh, sportsCenterId }) => {
    const { newToastNotification } = useToastNotifications();
    const navigate = useNavigate();

    const { options } = useTimezoneSelect({ labelStyle: 'original', timezones: allTimezones })
    const [errorMsg, setErrorMsg] = useState<string | null>(null);
    const [sportsCenter, setSportsCenter] = useState<SportsCenter>();
    const [form, setForm] = useState<UpdateSportsCenterRequest>({
        address: undefined,
        bar: false,
        cityId: undefined,
        countryId: undefined,
        description: undefined,
        galleryEnabled: false,
        halfHourSlotSupported: false,
        id: undefined,
        latitude: undefined,
        longitude: undefined,
        name: undefined,
        neighborhoodId: undefined,
        parking: false,
        phoneNumber: undefined,
        rating: undefined,
        requiredPhoneNumber: false,
        stripeAccountId: undefined,
        timeZone: undefined,
        visible: false,
        website: undefined,
        maskUsersEmail: false
    });

    useEffect(() => {
        fetchSportsCenter(sportsCenterId)
    }, []);

    const fetchSportsCenter = (id: number) => {
        getSportsCenter(id)
            .then(({data}) => {
                setSportsCenter(data)
                setForm(toForm(data))
            })
            .catch(({ response: { data } }) => {
                if (data && data.message) {
                    setErrorMsg(data.message);
                } else {
                    setErrorMsg(translate('unexpectedError') + '.');
                }
                navigate('/centers');
            })
    }

    const isModified = sportsCenter && (
        sportsCenter.rating !== form.rating ||
        sportsCenter.stripeAccountId !== form.stripeAccountId ||
        sportsCenter.timeZone !== form.timeZone ||
        sportsCenter.visible !== form.visible ||
        sportsCenter.galleryEnabled !== form.galleryEnabled ||
        sportsCenter.halfHourSlotSupported !== form.halfHourSlotSupported ||
        sportsCenter.maskUsersEmail !== form.maskUsersEmail
    );

    const onSubmit = () => {
        updateSportsCenterAdmin(sportsCenterId, form)
            .then(() => {
                setErrorMsg(null)
                refresh()
                newToastNotification(translate('save'), translate('operationSuccessful') + '.');
            })
            .catch(({ response: { data } }) => {
                if (data && data.message) {
                    setErrorMsg(data.message);
                } else {
                    setErrorMsg(translate('unexpectedError') + '.');
                }
            })
    }

    return <Form className='p-2'>
                {errorMsg &&
                    <Row>
                        <Col>
                            <Alert variant='danger' className='mt-2 shadow-sm' style={{ fontSize: '0.85rem' }}>
                                <div style={{ fontWeight: '700' }}>{errorMsg}</div>
                            </Alert>
                        </Col>
                    </Row>
                }
                <Row>
                    <Col className='mb-2'>
                        <Form.Group>
                            <Form.Label className='text-muted'>{translate('rating')}</Form.Label>
                            <Form.Control type='number' value={form.rating === null ? '' : form.rating} onChange={e => setForm({ ...form, rating: e.target.value === '' ? null : parseFloat(e.target.value) })} />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col className='mb-2'>
                        <Form.Group>
                            <Form.Label className='text-muted'>{translate('stripeAccountId')}</Form.Label>
                            <Form.Control value={form.stripeAccountId === null ? '' : form.stripeAccountId} onChange={e => setForm({ ...form, stripeAccountId: e.target.value || null })} />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col className='mb-2'>
                        <Form.Group>
                            <Form.Label className='text-muted'>{translate('timeZone')}</Form.Label>
                            <Form.Select
                                value={form.timeZone === null ? '' : form.timeZone}
                                onChange={e => setForm({...form, timeZone: e.target.value})}
                            >
                                <option key='' value=''>{translate('selectCity')}</option>
                                {options.map((option, index) => (
                                    <option value={option.value}>{option.label}</option>
                                ))}
                            </Form.Select>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Form.Label className='text-muted'/>
                    <Col className='mb-2'>
                        <Form.Check // prettier-ignore
                            type="switch"
                            label={translate('visible')}
                            checked={form.visible || false}
                            onChange={e => setForm({ ...form, visible: ((e.target as HTMLInputElement).checked) })}
                        />
                    </Col>
                    <Col className='mb-2'>
                        <Form.Check // prettier-ignore
                            type="switch"
                            label={translate('gallery')}
                            checked={form.galleryEnabled || false}
                            onChange={e => setForm({ ...form, galleryEnabled: ((e.target as HTMLInputElement).checked) })}
                        />
                    </Col>
                    <Col className='mb-2'>
                        <Form.Check // prettier-ignore
                            type="switch"
                            label={translate('halfHourSlot')}
                            checked={form.halfHourSlotSupported || false}
                            onChange={e => setForm({ ...form, halfHourSlotSupported: ((e.target as HTMLInputElement).checked) })}
                        />
                    </Col>
                    <Col className='mb-2'>
                        <Form.Check // prettier-ignore
                            type="switch"
                            label={translate('maskUsersEmail')}
                            checked={form.maskUsersEmail || false}
                            onChange={e => setForm({ ...form, maskUsersEmail: ((e.target as HTMLInputElement).checked) })}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className='d-flex justify-content-end'>
                            <Button disabled={!(isModified === true)} variant='primary' style={{ width: '8rem' }} onClick={onSubmit}>{translate('save')}</Button>
                        </div>
                    </Col>
                </Row>
            </Form>

}

export default CenterInformation;