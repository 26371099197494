import React, { useEffect, useState } from 'react';
import { Alert, Button, Col, Container, Form, Modal, Row, Spinner, Table } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { RiAddFill, RiCloseFill } from 'react-icons/ri';
import Select from 'react-select';
import { createOffer, updateOffer } from '../../../helpers/api';
import {dateToDateValue, dateToTimeValue, dateValueToDate, END_OF_DAY, timeValueToDate} from '../../../helpers/date';
import { CURRENCIES, DAYS_OF_WEEK } from '../../../helpers/enums';
import { useToastNotifications } from '../../../helpers/notifications';
import translate from '../../../helpers/translations';
import './CenterOfferModal.css';

interface Props {
  show: boolean;
  offer: Offer | null;
  sportsCenterId: number;
  courts: CourtOld[];
  openingHours: OpeningHoursOld[];
  onHide: () => any;
  refresh: () => any;
}

interface FormValues {
  price: number | null;
  currency: Currency;
  sport: Sport | null;
  courts: number[];
  hours: { dayOfWeek: DayOfWeek, fromTime: string, toTime: string }[]
  startDate?: string;
  endDate?: string;
}

const CenterOfferModal: React.FC<Props> = ({ show, offer, sportsCenterId, courts, openingHours, onHide, refresh }) => {
  const { newToastNotification } = useToastNotifications();
  const [form, setForm] = useState<FormValues>({ price: 0.0, currency: 'EUR', courts: [], hours: [], sport: null });
  const [loading, setLoading] = useState<boolean>(false);
  const [sportOptions, setSportOptions] = useState<any>([]);
  const [errorMsg, setErrorMsg] = useState<string | null>(null);

  useEffect(() => {
    if (offer) {
      setForm({
        price: offer.price,
        currency: offer.currency,
        courts: offer.courts.map(c => c.id),
        hours: offer.hours.map((oh) => ({
          id: oh.id,
          dayOfWeek: oh.dayOfWeek,
          fromTime: oh.from,
          toTime: oh.to
        })),
        sport: offer.sport,
        startDate: offer.startDate,
        endDate: offer.endDate
      });
    } else {
      setForm({ price: 0.0, currency: 'EUR', courts: [], hours: [], sport: null })
    }
    setLoading(false);
    setErrorMsg(null);
  }, [offer]);

  useEffect(() => {
    if(form.courts) {
      const selectedCourts = courts.filter(c => form.courts.includes(c.id))
      // Create an array to store the unique sports from the first Court object
      const uniqueSports: Sport[] = selectedCourts.length > 0 ? Array.from(new Set(selectedCourts[0].sports)) : [];
      // Find the unique sports that are common among all Court objects
      for (let i = 1; i < selectedCourts.length; i++) {
        const currentCourt = selectedCourts[i];
        uniqueSports.splice(
            0,
            uniqueSports.length,
            ...uniqueSports.filter((sport) => currentCourt.sports.includes(sport))
        );
      }

      setSportOptions(uniqueSports.map(s => ({ label: translate(s), value: s })))
    }
  }, [form.courts]);

  const submit = () => {
    setLoading(true);
    (offer ? updateOffer(sportsCenterId, offer.id, form) : createOffer(sportsCenterId, form))
      .then(() => {
        setLoading(false);
        onHide();
        refresh();
        newToastNotification(translate(offer ? 'editOffer' : 'addOffer'), translate('operationSuccessful') + '.');
      })
      .catch(({ response: { data } }) => {
        setLoading(false);
        if (data && data.message) {
          setErrorMsg(data.message);
        } else {
          setErrorMsg(translate('unexpectedError') + '.');
        }
      })
  }

  const formatDate = (value: string | undefined) => {
    if (!value) return '';
    if (value.length === 4) {
      return `${value.slice(0, 2)}.${value.slice(2)}.`;
    }
    return value.replace(/(\d{2})(\d{0,2})/, '$1.$2');
  };

  const isValidDate = (value: string) => {
    if(value.length == 1) {
      const day = parseInt(value.slice(0, 1), 10);
      if (day < 0 || day > 3) return false;
    }
    if(value.length == 2) {
      const day = parseInt(value.slice(0, 2), 10);
      if (day < 1 || day > 31) return false;
    }
    if(value.length == 3) {
      const month = parseInt(value.slice(2, 3), 10);
      if (month < 0 || month > 1) return false;
    }
    if(value.length == 4) {
      const month = parseInt(value.slice(2, 4), 10);
      if (month < 1 || month > 12) return false;
    }
    return true;
  };

  const handleStartDateChange = (e: any) => {
    let input = e.target.value.replace(/\D/g, ''); // Remove non-numeric characters
    if (input.length > 4) input = input.substring(0, 4); // Limit to 4 digits
    if (!isValidDate(input)) return; // Prevent invalid date entry
    setForm({ ...form, startDate: input }); // Store raw ddmm format
  };

  const handleEndDateChange = (e: any) => {
    let input = e.target.value.replace(/\D/g, ''); // Remove non-numeric characters
    if (input.length > 4) input = input.substring(0, 4); // Limit to 4 digits
    if (!isValidDate(input)) return; // Prevent invalid date entry
    setForm({ ...form, endDate: input }); // Store raw ddmm format
  };

  const handleKeyDown = (e: any) => {
    if (e.key === "Backspace") {
      let value = e.target.value;
      if (value.endsWith(".")) {
        e.target.value = value.slice(0, -1); // Remove the dot manually
      }
    }
  };

  const courtSelectOptions = courts.map(c => ({ label: c.name, value: c.id }));

  // @ts-ignore
  return <Modal show={show} dialogClassName='offer-modal'>
    <Modal.Header><strong>{translate(offer ? 'editOffer' : 'addOffer')}</strong></Modal.Header>
    <Modal.Body className='offer-modal-body'>
      <Form>
        <Container>
          {errorMsg && <Row>
            <Col>
              <Alert variant='danger' className='mt-2' style={{ fontSize: '0.85rem' }}>
                <div style={{ fontWeight: '700' }}>{errorMsg}</div>
              </Alert>
            </Col>
          </Row>}
          <Row className='mt-2'>
            <Col xs={12}>
              <Form.Label>{translate('dateRange')}</Form.Label>
            </Col>
            <Col sm={6}>
              <Form.Group>
                <Form.Control
                    type='text'
                    value={formatDate(form.startDate)}
                    onChange={handleStartDateChange}
                    onKeyDown={handleKeyDown}
                    placeholder='dd.mm.'
                />
              </Form.Group>
            </Col>
            <Col sm={6}>
              <Form.Group>
                <Form.Control
                    type='text'
                    value={formatDate(form.endDate)}
                    onChange={handleEndDateChange}
                    onKeyDown={handleKeyDown}
                    placeholder='dd.mm.'
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className='mt-2'>
            <Col>
              <Form.Group>
                <Form.Label>{translate('pricePerHour')}</Form.Label>
                <Form.Control type='number' value={form.price === null ? '' : form.price} onChange={e => setForm({ ...form, price: e.target.value === '' ? null : parseFloat(e.target.value) })} />
              </Form.Group>
            </Col>
          </Row>
          <Row className='mt-2'>
            <Col>
              <Form.Group>
                <Form.Label>{translate('currency')}</Form.Label>
                <Form.Select value={form.currency} onChange={e => setForm({ ...form, currency: (e.target.value as Currency) })}>
                  {CURRENCIES.map(currency => <option key={currency} value={currency}>{currency}</option>)}
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
          <Row className='mt-2'>
            <Col>
              <Form.Group>
                <Form.Label>{translate('courts')}</Form.Label>
                <Select
                  isMulti
                  placeholder={translate('courts')}
                  value={courtSelectOptions.filter(option => form.courts.includes(option.value))}
                  options={courtSelectOptions}
                  onChange={selected => setForm({ ...form, courts: selected.map(s => s.value), sport: null })} />
              </Form.Group>
            </Col>
          </Row>
            <Row className='mt-2'>
              <Col>
                <Form.Group>
                  <Form.Label>{translate('sport')}</Form.Label>
                  <Select
                    placeholder={translate('sport')}
                    value={sportOptions.find((so: any) => so.value == form.sport) || null}
                    options={sportOptions}
                    onChange={e => {
                      console.log(e)
                      setForm({...form, sport: e.value as Sport | null})
                    }} />
                </Form.Group>
              </Col>
            </Row>
            <hr />
          <Row className='mt-2'>
            <Col>
              <Form.Label>{translate('hours')}</Form.Label>
              {form.hours.length === 0 && <div className='text-muted'>{translate('noHoursAdded')}</div>}
              {form.hours.length !== 0 && <Table responsive borderless>
                <tbody>
                  {form.hours.map((oh, index) => <tr key={index}>
                    <td>
                      <Form.Select
                        value={oh.dayOfWeek}
                        onChange={e => {
                          form.hours[index] = {dayOfWeek: (e.target.value as DayOfWeek), fromTime: '', toTime: ''};
                          setForm({ ...form });
                        }}>
                        {DAYS_OF_WEEK.map(dow => <option key={dow} value={dow}>{translate(dow.substring(0, 3))}</option>)}
                      </Form.Select>
                    </td>
                    <td>
                      <DatePicker
                        placeholderText={translate('from')}
                        selected={oh.fromTime ? timeValueToDate(oh.fromTime) : null}
                        onChange={date => {
                          form.hours[index].fromTime = date ? dateToTimeValue(date) : '';
                          setForm({ ...form });
                        }}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={30}
                        dateFormat='HH:mm'
                        timeFormat='HH:mm'
                        filterTime={time => {
                          const dayOfWeek = form.hours[index].dayOfWeek;
                          const timeString = dateToTimeValue(time);
                          return openingHours.some(oh => oh.dayOfWeek === dayOfWeek && timeString >= oh.from && timeString <= oh.to);
                        }}
                        customInput={<Form.Control />}
                      />
                    </td>
                    <td>
                      <DatePicker
                        placeholderText={translate('to')}
                        selected={oh.toTime ? timeValueToDate(oh.toTime) : null}
                        onChange={date => {
                          form.hours[index].toTime = date ? dateToTimeValue(date) : '';
                          setForm({ ...form });
                        }}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={30}
                        dateFormat='HH:mm'
                        timeFormat='HH:mm'
                        filterTime={time => {
                          const dayOfWeek = form.hours[index].dayOfWeek;
                          const timeString = dateToTimeValue(time);
                          return openingHours.some(oh => oh.dayOfWeek === dayOfWeek && timeString >= oh.from && timeString <= oh.to);
                        }}
                        injectTimes={[END_OF_DAY]}
                        customInput={<Form.Control />}
                      />
                    </td>
                    <td><Button variant='outline-danger' onClick={() => {
                      form.hours.splice(index, 1);
                      setForm({ ...form })
                    }}><RiCloseFill /></Button></td>
                  </tr>)}
                </tbody>
              </Table>}
              <div className='d-flex justify-content-center'>
                <Button className='mx-auto' variant='outline-secondary' onClick={() => {
                  form.hours.push({ dayOfWeek: (DAYS_OF_WEEK[0] as DayOfWeek), fromTime: '', toTime: '' });
                  setForm({ ...form })
                }}><RiAddFill /></Button>
              </div>
            </Col>
          </Row>
        </Container>
      </Form>
    </Modal.Body>
    <Modal.Footer>
      {loading && <Spinner animation='border' variant='secondary' className='mx-auto' />}
      {!loading && <>
        <Button variant='secondary' onClick={() => onHide()}>{translate('close')}</Button>
        <Button variant='primary' onClick={() => submit()}>{translate('save')}</Button>
      </>}
    </Modal.Footer>
  </Modal>;
}

export default CenterOfferModal;