import React, {useEffect, useRef, useState} from 'react';
import {Alert, Button, Col, Form, Row} from 'react-bootstrap';
import {getReservations, getReservationsAdmin, getSportsCentersLabelsAdmin} from '../../../helpers/api';
import DatePicker from 'react-datepicker';
import translate from '../../../helpers/translations';
import {dateToDateValue, dateValueToDate, formatDate, formatTime,} from "../../../helpers/date";
import {sportIcons} from "../../../helpers/sportIcons";
import Splash from "../../Splash";
import ReservationDetailsModal from "./ReservationDetailsModal";
import {useNavMenu} from "../../../components/NavMenu/NavMenuProvider";
import SRTable from "../../../components/SRTable";
import Iphone from "../../../components/Icons/Iphone";
import CreditCard from "../../../components/Icons/CreditCard";
import Checkmark from "../../../components/Icons/Checkmark";
import Repeat from "../../../components/Icons/Repeat";
import Person from "../../../components/Icons/Person";

interface Props {
    selected?: boolean,
    sportsCenter?: SportsCenterOld,
    sports: Sport[]
}

const dateFrom = new Date(new Date().setHours(0, 0, 0, 0));
const dateTo = new Date(new Date(dateFrom).setDate(dateFrom.getDate() + 7));

const SearchReservations: React.FC<Props> = ({selected, sportsCenter, sports}) => {
    const [selectedReservation, setSelectedReservation] = useState<Reservation | null>(null);
    const [reservations, setReservations] = useState<Reservation[]>([]);
    const [pageNumber, setPageNumber] = useState<number>(0);
    const [totalCount, setTotalCount] = useState<number>(0);
    const [errorMsg, setErrorMsg] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [oldFilter, setOldFilter] = useState<ReservationFilter>(
        {
            sportsCenterId: sportsCenter ? sportsCenter.id : undefined,
            status: 'RESERVED',
            fromDateTime: dateToDateValue(dateFrom),
            toDateTime: dateToDateValue(dateTo),
        });
    const [filter, setFilter] = useState<ReservationFilter>(oldFilter);
    const [searchString, setSearchString] = useState(filter.user);
    const [sportsCenters, setSportsCenters] = useState<SportsCenterLabel[]>([]);

    const {setActiveHeaderKey, setActiveSidebarKey, setSportsCenter} = useNavMenu();

    useEffect(() => {
        if(!sportsCenter) {
            setActiveHeaderKey('reservations')
            setActiveSidebarKey(undefined)
            setSportsCenter(undefined)
        }

        if (!sportsCenter) {
            getSportsCentersLabelsAdmin()
                .then(({data}) => {
                    const sportsCenters = data.sort((sc1, sc2) => sc1.name.localeCompare(sc2.name));
                    setSportsCenters(sportsCenters);
                })
                .catch(() => {
                });
        }
    }, []);

    const isMounted = useRef(false);
    useEffect(() => {
        if (isMounted.current) {
            const timeOutId = setTimeout(() => setFilter({...filter, user: searchString}), 500);
            return () => clearTimeout(timeOutId);
        } else {
            isMounted.current = true;
        }
    }, [searchString]);

    useEffect(() => {
        if (selected == undefined || selected) {
            search();
        }
    }, [selected, filter]);

    const fetchReservations = (page: number) => sportsCenter ?
        getReservations(sportsCenter.id, page, 50, filter) :
        getReservationsAdmin(page, 50, filter)

    const search = () => {
        setLoading(true)
        fetchReservations(0)
            .then(({data, headers}) => {
                setOldFilter({...filter})
                setReservations(data)
                setPageNumber(0)
                setTotalCount(parseInt(headers['x-total-count']));
                setErrorMsg(null)
            })
            .catch(({response: {data}}) => {
                setTotalCount(0);
                if (data && data.message) {
                    setErrorMsg(data.message);
                } else {
                    setErrorMsg(translate('errorFetchingResults') + '.');
                }
            })
            .finally(() => setLoading(false))
    }

    const submit = (e: any) => {
        e.preventDefault()
        search()
    }

    const loadMoreResults = () => {
        setLoading(true)
        fetchReservations(pageNumber + 1)
            .then(({data, headers}) => {
                setReservations(reservations.concat(data));
                setPageNumber(pageNumber + 1);
                setTotalCount(parseInt(headers['x-total-count']));
            })
            .catch(({response: {data}}) => {
                setReservations([]);
                setTotalCount(0);
                if (data && data.message) {
                    setErrorMsg(data.message);
                } else {
                    setErrorMsg(translate('errorFetchingResults') + '.');
                }
            })
            .finally(() => setLoading(false))
    }

    const getUserString = (reservation: Reservation) => {
        let userString = '-';
        if (reservation.user && (reservation.user.firstName || reservation.user.lastName)) {
            userString = `${reservation.user.firstName ? reservation.user.firstName : ''} ${reservation.user.lastName ? reservation.user.lastName : ''}`;
        } else if (reservation.fullName) {
            userString = `${reservation.fullName}`;
        } else if(reservation.user && reservation.user.email) {
            userString = `${reservation.user.email}`
        } else if(reservation.email) {
            userString = `${reservation.email}`
        }
        return userString;
    };

    return (
        <>
            {errorMsg &&
                <Row>
                    <Col>
                        <Alert variant='danger' style={{fontSize: '0.85rem'}}>
                            <div style={{fontWeight: '700'}}>{errorMsg}</div>
                        </Alert>
                    </Col>
                </Row>
            }
            <Form onSubmit={submit}>
                {loading && <Splash/>}
                <Row>
                    <Col md={"3"}>
                        <Form.Group controlId="form.search">
                            <Form.Label style={{fontSize: '12px', margin: 0}}>{translate('USER')}</Form.Label>
                            <Form.Control type="text" placeholder={translate('search')}
                                          value={searchString}
                                          onChange={e => setSearchString(e.target.value)}
                            />
                        </Form.Group>
                    </Col>
                    {!sportsCenter &&
                        <Col md="3">
                            <Form.Group controlId="form.sportsCenter">
                                <Form.Label
                                    style={{fontSize: '12px', margin: 0}}>{translate('sportsCenter')}</Form.Label>
                                <Form.Select value={filter.sportsCenterId ? filter.sportsCenterId : ""}
                                             onChange={e => setFilter({
                                                 ...filter,
                                                 sportsCenterId: e.target.value ? +e.target.value : undefined
                                             })}
                                >
                                    <option value={undefined}>{translate('allSportsCenter')}</option>
                                    {sportsCenters.map(sc => <option key={sc.id} value={sc.id}>{sc.name}</option>)}
                                </Form.Select>
                            </Form.Group>
                        </Col>
                    }
                    {sportsCenter &&
                        <Col md="3">
                            <Form.Group controlId="form.sportsCenter">
                                <Form.Label
                                    style={{fontSize: '12px', margin: 0}}>{translate('court')}</Form.Label>
                                <Form.Select value={filter.courtId ? filter.courtId : ""}
                                             onChange={e => setFilter({
                                                 ...filter,
                                                 courtId: e.target.value ? +e.target.value : undefined
                                             })}
                                >
                                    <option value={undefined}>{translate('allCourts')}</option>
                                    {sportsCenter.courts.map(c => <option key={c.id} value={c.id}>{c.name}</option>)}
                                </Form.Select>
                            </Form.Group>
                        </Col>
                    }
                    <Col md="3">
                        <Form.Group controlId="form.from">
                            <Form.Label style={{fontSize: '12px', margin: 0}}>{translate('from')}</Form.Label>
                            <DatePicker
                                required
                                placeholderText={translate('from')}
                                selected={filter.fromDateTime ? dateValueToDate(filter.fromDateTime) : null}
                                onChange={date => setFilter({
                                    ...filter,
                                    fromDateTime: date ? dateToDateValue(date) : undefined
                                })}
                                dateFormat='dd.MM.yyyy.'
                                timeFormat='dd.MM.yyyy.'
                                customInput={<Form.Control/>}
                            />
                        </Form.Group>
                    </Col>
                    <Col md="3">
                        <Form.Group controlId="form.to">
                            <Form.Label style={{fontSize: '12px', margin: 0}}>{translate('to')}</Form.Label>
                            <DatePicker
                                required
                                placeholderText={translate('to')}
                                selected={filter.toDateTime ? dateValueToDate(filter.toDateTime) : null}
                                onChange={date => setFilter({
                                    ...filter,
                                    toDateTime: date ? dateToDateValue(date) : undefined
                                })}
                                dateFormat='dd.MM.yyyy.'
                                timeFormat='dd.MM.yyyy.'
                                customInput={<Form.Control/>}
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="ps-1 pe-1">
                    <Col md="3">
                        <Form.Group controlId="form.sport">
                            <Form.Label style={{fontSize: '12px', margin: 0}}>{translate('sport')}</Form.Label>
                            <Form.Select
                                name="sport"
                                placeholder={translate('select')}
                                value={filter.sport}
                                onChange={e => setFilter({
                                    ...filter,
                                    sport: e.target.value ? e.target.value as Sport : undefined
                                })}
                            >
                                <option value="">{translate('allSports')}</option>
                                {sports.map(s => <option key={s} value={s}>{translate(s)}</option>)}
                            </Form.Select>
                        </Form.Group>
                    </Col>
                    <Col md="3">
                        <Form.Group controlId="form.paymentMethod">
                            <Form.Label style={{fontSize: '12px', margin: 0}}>{translate('paymentMethod')}</Form.Label>
                            <Form.Select value={filter.paymentMethod} onChange={e => setFilter({
                                ...filter,
                                paymentMethod: e.target.value ? e.target.value as PaymentMethod : undefined
                            })}>
                                <option value="">{translate('resFilterAllPaymentMethods')}</option>
                                <option value="ON_SITE">{translate('resFilterCash')}</option>
                                <option value="CARD">{translate('resFilterCard')}</option>
                            </Form.Select>
                        </Form.Group>
                    </Col>
                    <Col md="3">
                        <Form.Group controlId="form.reservationType">
                            <Form.Label
                                style={{fontSize: '12px', margin: 0}}>{translate('labelReservationType')}</Form.Label>
                            <Form.Select value={filter.type}
                                         onChange={e => setFilter({
                                             ...filter,
                                             type: e.target.value ? e.target.value as ReservationType : undefined
                                         })}>
                                <option value="">{translate('resFilterAllReservation')}</option>
                                <option value="RECURRING">{translate('resFilterRecurring')}</option>
                                <option value="SPORTS_CENTER">{translate('resFilterFromAdmin')}</option>
                                <option value="MOBILE">{translate('resFilterFromMobile')}</option>
                            </Form.Select>
                        </Form.Group>
                    </Col>
                    <Col md="3">
                        <Form.Group controlId="form.labelReservationStatus">
                            <Form.Label
                                style={{fontSize: '12px', margin: 0}}>{translate('labelReservationStatus')}</Form.Label>
                            <Form.Select
                                value={filter.status}
                                onChange={e => setFilter({
                                    ...filter,
                                    canceledByRole: undefined,
                                    status: e.target.value ? e.target.value as ReservationStatusNew : undefined
                                })}>
                                <option value="">{translate('allStatuses')}</option>
                                <option value="RESERVED">{translate('reserved')}</option>
                                <option value="CANCELED">{translate('CANCELED')}</option>
                                <option value="COMPLETED">{translate('COMPLETED')}</option>
                                <option value="NO_SHOW">{translate('noShow')}</option>
                            </Form.Select>
                        </Form.Group>
                    </Col>
                </Row>
                {filter.status == 'CANCELED' &&
                    <Row className="ps-1 pe-1">
                        <Col md="3">
                            <Form.Group controlId="form.canceledBy">
                                <Form.Label
                                    style={{fontSize: '12px', margin: 0}}>{translate('cancelledBy')}</Form.Label>
                                <Form.Select
                                    value={filter.canceledByRole}
                                    onChange={e => setFilter({
                                        ...filter,
                                        canceledByRole: e.target.value ? e.target.value as UserRole : undefined
                                    })}>
                                    <option value="">{translate('anyone')}</option>
                                    <option value="USER">{translate('USER')}</option>
                                    <option value="SPORTS_CENTER">{translate('SPORTS_CENTER')}</option>
                                    <option value="ADMINISTRATOR">{translate('ADMINISTRATOR')}</option>
                                </Form.Select>
                            </Form.Group>
                        </Col>
                    </Row>
                }

            </Form>
            <div className='d-flex flex-column'>
                {reservations.length !== 0 &&
                    <SRTable tableHover>
                        <thead>
                        <tr>
                            <th>#</th>
                            <th>{translate('sport')}</th>
                            <th>{translate('USER')}</th>
                            <th>{translate('court')}</th>
                            <th>{translate('sportsCenter')}</th>
                            <th>{translate('time')}</th>
                            <th>{translate('price')}</th>
                            <th/>
                        </tr>
                        </thead>
                        <tbody>
                        {reservations.length !== 0 &&
                            reservations.map((r, index) =>
                                <tr key={index}
                                    onClick={() => setSelectedReservation(r)}>
                                    <td style={{backgroundColor: r.status == 'CANCELED' || r.status == 'NO_SHOW' ? '#fae3e3' : ''}}>{index + 1}</td>
                                    <td style={{backgroundColor: r.status == 'CANCELED' || r.status == 'NO_SHOW' ? '#fae3e3' : '', whiteSpace: 'nowrap'}}>
                                        <img height={20} width={20}
                                             src={sportIcons[r.sport.toLowerCase().replaceAll('-', '_') as keyof typeof sportIcons]}
                                             alt={r.sport} style={{margin: '1px'}}/>&nbsp;&nbsp;{translate(r.sport)}

                                    </td>
                                    <td style={{
                                        backgroundColor: r.status == 'CANCELED' || r.status == 'NO_SHOW' ? '#fae3e3' : '',
                                        maxWidth: '150px',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis'
                                    }}>{getUserString(r)}</td>
                                    <td style={{backgroundColor: r.status == 'CANCELED' || r.status == 'NO_SHOW' ? '#fae3e3' : ''}}>{r.court.name}</td>
                                    <td style={{backgroundColor: r.status == 'CANCELED' || r.status == 'NO_SHOW' ? '#fae3e3' : ''}}>{r.sportsCenter.name}</td>
                                    <td style={{backgroundColor: r.status == 'CANCELED' || r.status == 'NO_SHOW' ? '#fae3e3' : ''}}>{formatDate(r.fromDateTime)} {formatTime(r.fromDateTime)}-{formatTime(r.toDateTime)}</td>
                                    <td style={{backgroundColor: r.status == 'CANCELED' || r.status == 'NO_SHOW' ? '#fae3e3' : ''}}>{r.price} {r.currency}</td>
                                    <td style={{
                                        backgroundColor: r.status == 'CANCELED' || r.status == 'NO_SHOW' ? '#fae3e3' : '',
                                        paddingLeft: 0,
                                        textAlign: 'center',
                                        whiteSpace: 'nowrap'
                                    }}>
                                        {r.type === "MOBILE" ?
                                            <Iphone style={{marginRight: '3px', verticalAlign: 'text-bottom'}}/> : null}
                                        {r.paymentMethod === "CARD" ?
                                            <CreditCard style={{marginRight: '3px', verticalAlign: 'text-bottom'}}/> : null}
                                        {r.paymentMethod !== "CARD" && r.isPaid ?
                                            <Checkmark style={{marginRight: '3px', verticalAlign: 'text-bottom'}}/> : null}
                                        {r.type === 'RECURRING' ?
                                            <Repeat style={{marginRight: '3px', verticalAlign: 'text-bottom'}}/> : null}
                                        {r.type === "SPORTS_CENTER" ?
                                            <Person style={{marginRight: '3px', verticalAlign: 'text-bottom'}}/> : null}
                                    </td>
                                </tr>)}
                        </tbody>
                    </SRTable>}
                {reservations.length === 0 && <h4 className='text-muted my-5 text-center'>{translate('noResults')}</h4>}
                {reservations.length < totalCount &&
                    <span>
                    <Row>
                        <Col className='d-flex justify-content-center'>
                            {reservations.length} / {totalCount}
                        </Col>
                    </Row>
                    <Row>
                        <Col className='d-flex justify-content-center'>
                          <Button disabled={loading} variant='link' className='mt-1 mb-0'
                                  onClick={() => loadMoreResults()}>{translate('seeMoreResults')}</Button>
                        </Col>
                    </Row>
                </span>
                }
                {reservations.length >= totalCount && reservations.length > 0 && <Row>
                    <Col className='d-flex justify-content-center'>
                        <div className='mt-3 mb-5'>{translate('thatsAllResults')}</div>
                    </Col>
                </Row>}
                {selectedReservation &&
                    <ReservationDetailsModal showSportsCenter={!sportsCenter}
                                             reservation={selectedReservation}
                                             onHide={() => setSelectedReservation(null)}
                    />
                }
            </div>
        </>
    )
}

export default SearchReservations;