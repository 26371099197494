import React, {useEffect, useState} from 'react';
import {Alert, Button, Col, Form, Row} from 'react-bootstrap';
import translate from '../../../helpers/translations';
import {getPaymentMethods, updatePaymentMethods} from '../../../helpers/api';
import SRTable from "../../../components/SRTable";

interface Props {
  sportsCenterId: number;
  allowCardPayment: boolean;
  refresh: () => any;
}

const PaymentMethods: React.FC<Props> = ({ sportsCenterId, allowCardPayment, refresh }) => {
  const [isModified, setIsModified] = useState<boolean>(false);
  const [form, setForm] = useState<AllowedPaymentMethods[]>([]);
  const [originalData, setOriginalData] = useState<AllowedPaymentMethods[]>([]);
  const [errorMsg, setErrorMsg] = useState<string | null>(null);

  useEffect(() => {
    getPaymentMethods(sportsCenterId)
        .then(({ data }) => {
          setOriginalData(data);
          setForm(data);
        })
        .catch(() => setErrorMsg(translate('unexpectedError')));
    }, [sportsCenterId]);

  useEffect(() => {
    setIsModified(!arraysEqual(originalData, form))
  }, [originalData, form]);

  const compareSportsCenterPaymentMethod = (a: AllowedPaymentMethods, b: AllowedPaymentMethods) => {
    return a.sport.localeCompare(b.sport);
  }

  function arraysEqual(a: AllowedPaymentMethods[], b: AllowedPaymentMethods[]) {
    for (let i = 0; i < a.length; i++) {
      a[i].allowedPaymentMethods = a[i].allowedPaymentMethods.sort()
    }
    for (let i = 0; i < a.length; i++) {
      b[i].allowedPaymentMethods = b[i].allowedPaymentMethods.sort()
    }
    a.sort(compareSportsCenterPaymentMethod)
    b.sort(compareSportsCenterPaymentMethod)
    return JSON.stringify(a) === JSON.stringify(b)
  }

  const handleOnChange = (sport: Sport, paymentMethod: PaymentMethod, isChecked: boolean) => {
    setForm(prevForm => prevForm
        .map(rcd => rcd.sport === sport
            ? { ...rcd, allowedPaymentMethods: isChecked ? [...rcd.allowedPaymentMethods.filter(pm => pm != paymentMethod), paymentMethod] : rcd.allowedPaymentMethods.filter(pm => pm != paymentMethod) }
            : rcd
        ));
  }

  const handleSave = () => {
    for (let i = 0; i < form.length; i++) {
      if(form[i].allowedPaymentMethods.length == 0) {
        setErrorMsg(translate('unexpectedError') + '.');
        return;
      }
    }

    updatePaymentMethods(sportsCenterId, form)
        .then(() => refresh())
        .catch(({ response: { data } }) => {
          if (data && data.message) {
            setErrorMsg(data.message);
          } else {
            setErrorMsg(translate('unexpectedError') + '.');
          }
        })
  }

  return <div className='d-flex flex-column'>
    {originalData.length !== 0 && <>
      {errorMsg &&
          <Row>
            <Col>
              <Alert variant='danger' className='mt-2' style={{ fontSize: '0.85rem' }}>
                <div style={{ fontWeight: '700' }}>{errorMsg}</div>
              </Alert>
            </Col>
          </Row>
      }
        <SRTable>
          <thead>
            <tr>
              <th>{translate('sport')}</th>
              <th style={{textAlign: 'center'}}>{translate('ON_SITE')}</th>
              <th style={{textAlign: 'center'}}>{translate('CARD')}</th>
            </tr>
          </thead>
          <tbody>
            {form.sort((a, b) => a.sport.localeCompare(b.sport)).map(f =>
                <tr key={f.sport}>
                  <td>{translate(f.sport)}</td>
                  <td style={{textAlign: 'center'}}>
                    <Form.Check checked={f.allowedPaymentMethods.includes('ON_SITE')}
                                onChange={e => handleOnChange(f.sport, 'ON_SITE', e.target.checked)}
                    />
                  </td>
                  <td style={{textAlign: 'center'}}>
                    <Form.Check checked={f.allowedPaymentMethods.includes('CARD')}
                                onChange={e => handleOnChange(f.sport, 'CARD', e.target.checked)}
                                disabled={!allowCardPayment}
                    />
                  </td>
                </tr>)
            }
          </tbody>
        </SRTable>
      <Row>
        <Col>
          <div className='d-flex justify-content-end mb-5'>
            <Button onClick={handleSave} disabled={!isModified} variant='primary' style={{ width: '8rem' }} >{translate('save')}</Button>
          </div>
        </Col>
      </Row>
    </>}
    {originalData.length === 0 && <h4 className='text-muted my-5 text-center'>{translate('noResults')}</h4>}
  </div>;
}

export default PaymentMethods;