import React, { useEffect, useState, useCallback } from 'react';
import { Alert, Button, Col, Form, Row } from 'react-bootstrap';
import translate from '../../../helpers/translations';
import { getReservationCancellationDeadline, updateReservationCancellationDeadline } from '../../../helpers/api';
import { useToastNotifications } from '../../../helpers/notifications';
import SRTable from '../../../components/SRTable';

interface Props {
    sportsCenterId: number;
    refresh: () => void;
}

const ReservationCancellationDeadline: React.FC<Props> = ({ sportsCenterId, refresh }) => {
    const { newToastNotification } = useToastNotifications();
    const [form, setForm] = useState<ReservationCancellationDeadline[]>([]);
    const [originalData, setOriginalData] = useState<ReservationCancellationDeadline[]>([]);
    const [errorMsg, setErrorMsg] = useState<string | null>(null);

    useEffect(() => {
        getReservationCancellationDeadline(sportsCenterId).then(({ data }) => {
            setOriginalData(data);
            setForm(data);
        }).catch(() => setErrorMsg(translate('unexpectedError')));
    }, [sportsCenterId]);

    const isModified = JSON.stringify(form) !== JSON.stringify(originalData);

    const handleOnDeadlineChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, sport: string) => {
        setForm(prevForm => prevForm.map(rcd => rcd.sport === sport ? { ...rcd, reservationCancellationDeadline: +e.target.value } : rcd));
    };

    const handleSave = useCallback(() => {
        updateReservationCancellationDeadline(sportsCenterId, form).then(() => {
            refresh();
            newToastNotification(translate('save'), translate('operationSuccessful'));
            setOriginalData(form);
        }).catch(({ response }) => {
            setErrorMsg(response?.data?.message || translate('unexpectedError'));
        });
    }, [form, sportsCenterId, refresh, newToastNotification]);

    return (
        <div className='d-flex flex-column p-2'>
            {errorMsg && (
                <Row>
                    <Col>
                        <Alert variant='danger' className='mt-2' style={{ fontSize: '0.85rem', fontWeight: 700 }}>{errorMsg}</Alert>
                    </Col>
                </Row>
            )}

            {form.length > 0 ? (
                <>
                    <SRTable>
                        <thead>
                        <tr>
                            <th>{translate('sport')}</th>
                            <th>{translate('timeBeforeStart')}</th>
                        </tr>
                        </thead>
                        <tbody>
                        {form.sort((a, b) => a.sport.localeCompare(b.sport)).map(({ sport, reservationCancellationDeadline }) => (
                            <tr key={sport}>
                                <td>{translate(sport)}</td>
                                <td className="p-1">
                                    <Form.Control type='number' min={0} value={reservationCancellationDeadline} onChange={e => handleOnDeadlineChange(e, sport)} />
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </SRTable>
                    <Row className='mt-2'>
                        <Col className='d-flex justify-content-end'>
                            <Button onClick={handleSave} disabled={!isModified} variant='primary' style={{ width: '8rem' }}>{translate('save')}</Button>
                        </Col>
                    </Row>
                </>
            ) : (
                <h4 className="text-muted my-5 text-center">{translate('noResults')}</h4>
            )}
        </div>
    );
};

export default ReservationCancellationDeadline;
