import React, {useEffect, useState} from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import {useNavigate, useParams, useSearchParams} from 'react-router-dom';
import {getSportsCenterOld} from '../../helpers/api';
import {useAuth} from '../../helpers/auth';
import {useConfiguration} from '../../helpers/configuration';
import {SPORTS} from '../../helpers/enums';
import translate from '../../helpers/translations';
import Error from '../Error';
import Splash from '../Splash';
import CenterCourts from './Courts/CenterCourts';
import CoverImage from './Images/CoverImage';
import CenterImages from './Images/CenterImages';
import CenterOffers from './Offers/CenterOffers';
import CenterOpeningHours from './OpeningHours/CenterOpeningHours';
import CenterReservations from './Reservations/CenterReservations';
import CenterTimesOff from './TimesOff/CenterTimesOff';
import PaymentMethods from "./PaymentMethods/PaymentMethods";
import RecurringReservations from "./RecurringReservations/RecurringReservations";
import CenterReservationsHalfHour from "./Reservations/CenterReservationsHalfHour";
import './Center.css'
import ReservationColors from "./ReservationColors/ReservationColors";
import Configuration from "./Configuration/Configuration";
import SearchReservations from "./SearchReservations/SearchReservations";
import {useNavMenu} from "../../components/NavMenu/NavMenuProvider";
import CenterUsers from "./CenterUsers/CenterUsers";
import CenterNotificationRequests from "./CenterNotificationRequests/CenterNotificationRequests";
import CenterInformation from "./Information/CenterInformation";

interface Props {
}

const Center: React.FC<Props> = () => {
    const {person} = useAuth();
    const navigate = useNavigate();
    const configuration = useConfiguration();
    const navigation = useNavMenu()
    const params = useParams();
    const id = ((params.id && /^[0-9]*$/.test(params.id)) ? parseInt(params.id) : null);
    const [searchParams] = useSearchParams();
    const [isError, setIsError] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [sportsCenter, setSportsCenter] = useState<SportsCenterOld | null>(null);
    const [activeCourts, setActiveCourts] = useState<CourtOld[]>([]);

    useEffect(() => {
        const action = searchParams.get("action") != null ? searchParams.get("action")?.toString() : "reservations-table"

        if (action == null || !Array.of(
            'new', 'about-general', 'about-working-time', 'about-time-off',
            'reservations-table', 'reservations-search', 'reservations-recurring', 'reservations-color-settings',
            'courts-offers', 'courts-payment-method', 'center-users', 'center-notification-requests',
            'admin-configuration', 'admin-courts', 'admin-gallery', 'admin-cover-image'
        ).includes(action)) {
            navigate("/", {replace: true})
        }

        navigation.setActiveHeaderKey(undefined)
        navigation.setActiveSidebarKey(action != 'new' ? action : undefined)
    }, [searchParams]);

    useEffect(() => {
        if (id && person && person.role == 'SPORTS_CENTER' && person.sportsCenters && person.sportsCenters.find(sc => sc.id === id) == undefined) {
            navigate("/center/" + person.sportsCenters[0].id, {replace: true})
        }
    }, [person]);

    useEffect(() => {
        navigation.setSportsCenter(sportsCenter)
    }, [sportsCenter]);

    const fetchSportsCenter = () => {
        if (id && (person?.role == 'ADMINISTRATOR' || (person?.role == 'SPORTS_CENTER' && person?.sportsCenters?.find(sc => sc.id == id) != undefined))) {
            setLoading(true);
            getSportsCenterOld(id)
                .then(({data}) => {
                    data.courts.sort((c1, c2) => {
                        const c1Index = Math.min(...c1.sports.map(courtSport => SPORTS.findIndex(sport => sport === courtSport)));
                        const c2Index = Math.min(...c2.sports.map(courtSport => SPORTS.findIndex(sport => sport === courtSport)));
                        if (c1Index !== c2Index) {
                            return c1Index - c2Index;
                        } else if (c1.number && c2.number) {
                            return c1.number - c2.number;
                        } else {
                            return 0;
                        }
                    })
                    setSportsCenter(data);
                    setActiveCourts(data.courts.filter(c => c.active))
                    setLoading(false);
                })
                .catch(() => {
                    setIsError(true);
                    setLoading(false);
                    navigate("/", {replace: true});
                });
        }
    }

    useEffect(fetchSportsCenter, [id, configuration]);

    if (isError) {
        return <Error children={translate('couldNotFetchSportsCenterDetails')}/>
    }

    if (!configuration || (id && sportsCenter === null) || loading) {
        return <>
            <Splash/>
        </>;
    }

    const renderComponent = (key?: NavigationSidebarKey) => {
        if (!sportsCenter || !id || !key) {
            return null;
        }
        switch (key) {
            case "about-general":
                return <CenterInformation sportsCenterId={id}/>
            case "about-working-time":
                return (
                    <CenterOpeningHours
                        sportsCenterId={id}
                        openingHours={sportsCenter.openingHours}
                        refresh={fetchSportsCenter}
                    />
                );
            case "about-time-off":
                return (
                    <CenterTimesOff
                        sportsCenterId={id}
                        openingHours={sportsCenter.openingHours}
                        timesOff={sportsCenter.timesOff}
                        refresh={fetchSportsCenter}
                        courts={sportsCenter.courts}
                    />
                );
            case "reservations-table":
                if (activeCourts.length > 0 && sportsCenter) {
                    if (sportsCenter.halfHourSlot) {
                        return <CenterReservationsHalfHour selected={true} sportsCenter={sportsCenter}
                                                           refresh={fetchSportsCenter}/>
                    } else {
                        return <CenterReservations selected={true} sportsCenter={sportsCenter}
                                                   refresh={fetchSportsCenter}/>
                    }
                } else {
                    return <h4 className='text-muted mt-5 text-center'>{translate('noReservationsAvailable')}.</h4>
                }
            case "reservations-search":
                return (
                    <SearchReservations
                        selected={true}
                        sportsCenter={sportsCenter}
                        sports={Array.from(new Set(
                            sportsCenter.offers
                                .flatMap(o => o.courts)
                                .flatMap(c => c.sports)
                        ))}
                    />
                );
            case "reservations-recurring":
                return (
                    <RecurringReservations
                        sportsCenter={sportsCenter}
                        refresh={fetchSportsCenter}
                    />
                );
            case "reservations-color-settings":
                return (
                    <ReservationColors
                        sportsCenterId={id}
                        reservationColors={sportsCenter.reservationColors}
                        refresh={fetchSportsCenter}
                    />
                );
            case "courts-offers":
                return (
                    <CenterOffers
                        sportsCenterId={id}
                        offers={sportsCenter.offers}
                        courts={sportsCenter.courts}
                        openingHours={sportsCenter.openingHours}
                        refresh={fetchSportsCenter}
                    />
                );
            case "courts-payment-method":
                return (
                    <PaymentMethods
                        sportsCenterId={sportsCenter.id}
                        allowCardPayment={!!sportsCenter.stripeAccountId}
                        refresh={fetchSportsCenter}
                    />
                );
            case "center-users":
                return (
                    <CenterUsers sportsCenterId={sportsCenter.id}/>
                );
            case "center-notification-requests":
                return (
                    <CenterNotificationRequests sportsCenterId={sportsCenter.id} sportsCenterGalleryEnabled={sportsCenter.galleryEnabled}/>
                );
            case "admin-configuration":
                return (
                    <Configuration
                        sportsCenter={sportsCenter}
                        refresh={fetchSportsCenter}
                    />
                );
            case "admin-courts":
                return (
                    <CenterCourts
                        sportsCenterId={id}
                        courts={sportsCenter.courts}
                        refresh={fetchSportsCenter}
                    />
                );
            case "admin-gallery":
                return (
                    <CenterImages sportsCenterId={id}/>
                );
            case "admin-cover-image":
                return (
                    <CoverImage sportsCenterId={id} coverImage={sportsCenter.coverImage} refresh={fetchSportsCenter}/>
                );
        }

        return null
    }

    return <>
        <div>
            <Row>
                <h4 className='my-0'>{sportsCenter ? (sportsCenter.visible ? `${sportsCenter.name}` : `[${translate('hidden').toUpperCase()}] ${sportsCenter.name}`) : translate('addNewCenter')}</h4>
                {id && <div className='text-muted'>{translate('sportsCenter')} {translate('id')}: {id}</div>}
            </Row>
            <hr/>
            {id && sportsCenter && renderComponent(navigation.activeSidebarKey)}
            <Row><Col style={{minHeight: '100px'}}></Col></Row>
        </div>
    </>;
}

export default Center;