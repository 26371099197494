import React, { useState } from 'react';
import { Alert, Button, Col, Row, Spinner, Table } from 'react-bootstrap';
import {deleteCourt,} from '../../../helpers/api';
import { useToastNotifications } from '../../../helpers/notifications';
import translate from '../../../helpers/translations';
import CenterCourtModal from './CenterCourtModal';
import ConfirmationModal from "../../../components/ConfirmationModal";

interface Props {
  sportsCenterId: number;
  courts: CourtOld[];
  refresh: () => any;
}

const CenterCourts: React.FC<Props> = ({ sportsCenterId, courts, refresh }) => {
  const { newToastNotification } = useToastNotifications();
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [editingCourt, setEditingCourt] = useState<CourtOld | null>(null);
  const [deletingId, setDeletingId] = useState<number | null>(null);
  const [deleteInProgress, setDeleteInProgress] = useState<boolean>(false);

  const [errorMsg, setErrorMsg] = useState<string | null>(null);

  const doDelete = () => {
    setDeleteInProgress(true);
    if (deletingId !== null) {
      deleteCourt(sportsCenterId, deletingId)
          .then(() => {
            setDeletingId(null);
            setDeleteInProgress(false);
            newToastNotification(translate('delete'), translate('operationSuccessful') + '!');
            refresh();
          })
          .catch(() => {
            setDeletingId(null);
            setDeleteInProgress(false);
            newToastNotification(translate('delete'), translate('error') + '.', 'danger');
          })
    }
  }

  return <>
    {errorMsg && <Row>
      <Col className='mt-2'>
        <Alert variant='danger' className='mt-2' style={{ fontSize: '0.85rem' }}>
          <div style={{ fontWeight: '700' }}>{errorMsg}</div>
        </Alert>
      </Col>
    </Row>}
    <div className='d-flex flex-column'>
      {courts.length !== 0 &&
          <div className='my-4 table-responsive card shadow sr-table'>
            <Table className='m-0 align-middle'>
              <thead>
              <tr>
                <th>{translate('id')}</th>
                <th>{translate('name')}</th>
                <th style={{textAlign: 'center'}}>{translate('number')}</th>
                <th style={{textAlign: 'center'}}>{translate('dimensions')}</th>
                <th style={{textAlign: 'center'}}>{translate('courtType')}</th>
                <th style={{textAlign: 'center'}}>{translate('surfaceType')}</th>
                <th style={{textAlign: 'center'}}>{translate('sports')}</th>
                <th/>
              </tr>
              </thead>
              <tbody>
              {courts.length !== 0 &&
                  courts
                      .map(c => <tr key={c.id}>
                        <td>{c.id}</td>
                        <td>{c.name}</td>
                        <td style={{textAlign: 'center'}}>{c.number || '-'}</td>
                        <td style={{textAlign: 'center'}}>{c.length || ''}{c.length && c.width && 'x'}{c.width || ''}</td>
                        <td style={{textAlign: 'center'}}>{translate(c.courtType)}</td>
                        <td style={{textAlign: 'center'}}>{translate(c.surfaceType)}</td>
                        <td style={{textAlign: 'center'}}>
                          {c.sports.map(s => <div key={s}>{translate(s)}</div>)}
                        </td>
                        <td className='text-end' style={{whiteSpace: 'nowrap'}}>
                          <Button size="sm" variant='secondary' className='mx-1' onClick={() => {
                            setEditingCourt(c);
                            setShowEditModal(true);
                          }}>{translate('edit')}</Button>
                          <Button size="sm" variant='danger' onClick={() => setDeletingId(c.id)}>
                            {translate('delete')}
                          </Button>
                        </td>
                      </tr>)}
              </tbody>
            </Table>
          </div>
      }
      {courts.length === 0 && <h4 className='text-muted my-5 text-center'>{translate('noCourtsAvailable')}</h4>}
      <Button className='mx-auto px-5' variant='secondary' onClick={() => { setEditingCourt(null); setShowEditModal(true); }}>{translate('addCourt')}</Button>
      <CenterCourtModal
        show={showEditModal}
        court={editingCourt}
        sportsCenterId={sportsCenterId}
        onHide={() => setShowEditModal(false)}
        refresh={refresh} />
    </div>
    {deletingId != null &&
        <ConfirmationModal
            title={translate('confirmDeleting')}
            body={translate('sureToDelete') + '?'}
            confirmButtonVariant='danger'
            inProgress={deleteInProgress}
            onConfirm={doDelete}
            onCancel={() => {
              setDeletingId(null);
              setDeleteInProgress(false);
            }}
        />}
  </>;
}

export default CenterCourts;