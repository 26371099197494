import React, { useEffect, useState } from 'react';
import { Alert, Button, Col, Form, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import {getSportsCenter, updateSportsCenter} from '../../../helpers/api';
import { useToastNotifications } from '../../../helpers/notifications';
import translate from '../../../helpers/translations';
import Splash from '../../Splash';
import {useConfiguration} from "../../../helpers/configuration";

interface Props {
  sportsCenterId: number;
}

const toForm = (sc: SportsCenter): UpdateSportsCenterRequest => {
  return {
    id: sc.id,
    visible: sc.visible,
    name: sc.name,
    description: sc.description,
    latitude: sc.latitude,
    longitude: sc.longitude,
    address: sc.address,
    neighborhoodId: sc.neighborhood ? sc.neighborhood.id : null,
    cityId: sc.city ? sc.city.id : null,
    countryId: sc.country ? sc.country.id : null,
    parking: sc.parking,
    bar: sc.bar != undefined ? sc.bar : false,
    rating: sc.rating,
    stripeAccountId: sc.stripeAccountId,
    requiredPhoneNumber: sc.requiredPhoneNumber,
    halfHourSlotSupported: sc.halfHourSlotSupported,
    phoneNumber: sc.phoneNumber,
    website: sc.website,
    timeZone: sc.timeZone,
    galleryEnabled: sc.galleryEnabled,
  }
};

const CenterInformation: React.FC<Props> = ({ sportsCenterId }) => {
  const configuration = useConfiguration();

  const { newToastNotification } = useToastNotifications();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const [countries, setCountries] = useState<any>([])
  const [cities, setCities] = useState<any>([])
  const [neighborhoods, setNeighborhoods] = useState<any>([])

  const [sportsCenter, setSportsCenter] = useState<SportsCenter>();
  const [form, setForm] = useState<UpdateSportsCenterRequest>({
    address: undefined,
    bar: false,
    cityId: undefined,
    countryId: undefined,
    description: undefined,
    galleryEnabled: false,
    halfHourSlotSupported: false,
    id: undefined,
    latitude: undefined,
    longitude: undefined,
    name: undefined,
    neighborhoodId: undefined,
    parking: false,
    phoneNumber: undefined,
    rating: undefined,
    requiredPhoneNumber: false,
    stripeAccountId: undefined,
    timeZone: undefined,
    visible: false,
    website: undefined
  });

  useEffect(() => {
    if(configuration) {
      setCountries(configuration.countries);
      // @ts-ignore
      const cities = form.countryId ? (countries.find(c => c.id === form.countryId)?.cities || []) : configuration.countries.flatMap(c => c.cities);
      setCities(cities)
      // @ts-ignore
      const neighborhoods = form.cityId ? (cities.find(c => c.id === form.cityId)?.neighborhoods || []) : cities.flatMap(c => c.neighborhoods);
      setNeighborhoods(neighborhoods)
    }
  }, [configuration, form.countryId, form.cityId]);

  useEffect(() => {
    fetchSportsCenter(sportsCenterId)
  }, []);

  const fetchSportsCenter = (id: number) => {
    getSportsCenter(id)
        .then(({data}) => {
          setSportsCenter(data)
          setForm(toForm(data))
        })
        .catch(({ response: { data } }) => {
          setLoading(false);
          if (data && data.message) {
            setErrorMsg(data.message);
          } else {
            setErrorMsg(translate('unexpectedError') + '.');
          }
          navigate('/centers');
        })
  }

  const onSubmit = () => {
    setLoading(true);
    updateSportsCenter(sportsCenterId, form)
      .then(() => {
        setLoading(false);
        setErrorMsg(null)
        fetchSportsCenter(sportsCenterId)
        newToastNotification(translate('editCenter'), translate('operationSuccessful') + '.');
      })
      .catch(({ response: { data } }) => {
        setLoading(false);
        if (data && data.message) {
          setErrorMsg(data.message);
        } else {
          setErrorMsg(translate('unexpectedError') + '.');
        }
      })
  }

  const isModified = sportsCenter && (
      sportsCenter.name !== form.name ||
      sportsCenter.description !== form.description ||
      sportsCenter.latitude !== form.latitude ||
      sportsCenter.longitude !== form.longitude ||
      sportsCenter.address !== form.address ||
      sportsCenter.neighborhood?.id !== form.neighborhoodId ||
      sportsCenter.city?.id !== form.cityId ||
      sportsCenter.country?.id !== form.countryId ||
      sportsCenter.parking !== form.parking ||
      sportsCenter.bar !== form.bar ||
      sportsCenter.requiredPhoneNumber !== form.requiredPhoneNumber ||
      sportsCenter.phoneNumber !== form.phoneNumber ||
      sportsCenter.galleryEnabled !== form.galleryEnabled ||
      sportsCenter.halfHourSlotSupported !== form.halfHourSlotSupported ||
      sportsCenter.rating !== form.rating ||
      sportsCenter.stripeAccountId !== form.stripeAccountId ||
      sportsCenter.timeZone !== form.timeZone ||
      sportsCenter.visible !== form.visible ||
      sportsCenter.website !== form.website
  )

  if (loading) {
    return <Splash />;
  }

  // @ts-ignore
  return <>
    <Form>
      {errorMsg && <Alert variant='danger' className='mt-4' style={{fontSize: '0.85rem'}}>
        <div style={{fontWeight: '700'}}>{translate('unexpectedError')}.</div>
        {errorMsg}
      </Alert>}
      <Row>
        <Col>
          <Form.Group>
            <Form.Label className='text-muted'>{translate('name')}</Form.Label>
            <Form.Control value={form.name === null ? '' : form.name}
                          onChange={e => setForm({...form, name: e.target.value || null})}/>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col className='mb-2'>
          <Form.Group>
            <Form.Label className='text-muted'>{translate('description')}</Form.Label>
            <Form.Control as='textarea' placeholder={translate('addDescription')} rows={4}
                          value={form.description === null ? '' : form.description}
                          onChange={e => setForm({...form, description: e.target.value || null})}/>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col className='mb-2'>
          <Form.Group>
            <Form.Label className='text-muted'>{translate('address')}</Form.Label>
            <Form.Select value={form.countryId === null ? '' : form.countryId} onChange={e => setForm({
              ...form,
              countryId: e.target.value === '' ? null : parseInt(e.target.value),
              cityId: null,
              neighborhoodId: null
            })}>
              <option key='' value=''>{translate('selectCountry')}</option>
              {// @ts-ignore
                countries?.map(c => <option key={c.id} value={c.id}>{c.name}</option>)
              }
            </Form.Select>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col className='mb-2'>
          <Form.Select value={form.cityId === null ? '' : form.cityId} onChange={e => setForm({
            ...form,
            cityId: e.target.value === '' ? null : parseInt(e.target.value),
            neighborhoodId: null
          })}>
            <option key='' value=''>{translate('selectCity')}</option>
            {// @ts-ignore
              cities?.map(c => <option key={c.id} value={c.id}>{c.name}</option>)
            }
          </Form.Select>
        </Col>
      </Row>
      <Row>
        <Col className='mb-2'>
          <Form.Select value={form.neighborhoodId === null ? '' : form.neighborhoodId} onChange={e => setForm({
            ...form,
            neighborhoodId: e.target.value === '' ? null : parseInt(e.target.value)
          })}>
            <option key='' value=''>{translate('selectNeighborhood')}</option>
            {// @ts-ignore
              neighborhoods?.map(n => <option key={n.id} value={n.id}>{n.name}</option>)
            }
          </Form.Select>
        </Col>
      </Row>
      <Row>
        <Col className='mb-2'>
          <Form.Control placeholder={translate('address')} value={form.address === null ? '' : form.address}
                        onChange={e => setForm({...form, address: e.target.value || null})}/>
        </Col>
      </Row>
      <Row>
        <Col md={6} className='mb-2'>
          <Form.Group>
            <Form.Label className='text-muted'>{translate('latitude')}</Form.Label>
            <Form.Control type='number' value={form.latitude === null ? '' : form.latitude} onChange={e => setForm({
              ...form,
              latitude: e.target.value === '' ? null : parseFloat(e.target.value)
            })}/>
          </Form.Group>
        </Col>
        <Col md={6} className='mb-2'>
          <Form.Group>
            <Form.Label className='text-muted'>{translate('longitude')}</Form.Label>
            <Form.Control type='number' value={form.longitude === null ? '' : form.longitude} onChange={e => setForm({
              ...form,
              longitude: e.target.value === '' ? null : parseFloat(e.target.value)
            })}/>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group>
            <Form.Label className='text-muted'>{translate('phoneNumber')}</Form.Label>
            <Form.Control type='text' value={form.phoneNumber === null ? '' : form.phoneNumber} onChange={e => setForm({
              ...form,
              phoneNumber: e.target.value === '' ? null : e.target.value
            })}/>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group>
            <Form.Label className='text-muted'>{translate('website')}</Form.Label>
            <Form.Control type='text' value={form.website === null ? '' : form.website}
                          onChange={e => setForm({...form, website: e.target.value === '' ? null : e.target.value})}/>
          </Form.Group>
        </Col>
      </Row>
      <Form.Label className='text-muted'/>
      <Row>
        <Col>
          <Form.Check checked={form.parking || false} label={translate('parking')}
                      onChange={e => setForm({...form, parking: ((e.target as HTMLInputElement).checked)})}/>
        </Col>
        <Col>
          <Form.Check checked={form.bar || false} label={translate('bar')}
                      onChange={e => setForm({...form, bar: ((e.target as HTMLInputElement).checked)})}/>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Check checked={form.requiredPhoneNumber || false} label={translate('reservationPhoneNumberRequired')}
                      onChange={e => setForm({...form, requiredPhoneNumber: (e.target as HTMLInputElement).checked})}/>
        </Col>
      </Row>
    </Form>
    <Row>
      <Col>
        <div className='d-flex justify-content-end mb-5'>
          <Button disabled={!(isModified === true)} variant='primary' style={{width: '8rem'}}
                  onClick={onSubmit}>{translate('save')}</Button>
        </div>
      </Col>
    </Row>
  </>
}

export default CenterInformation;