import React, { useEffect, useState, useCallback } from 'react';
import { Alert, Button, Col, Form, Row } from 'react-bootstrap';
import translate from '../../../helpers/translations';
import { getHalfHourSports, updateHalfHourSports } from '../../../helpers/api';
import SRTable from '../../../components/SRTable';

interface Props {
  sportsCenterId: number;
  refresh: () => void;
}

const dayOrder: DayOfWeek[] = ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY'];

const HalfHourReservations: React.FC<Props> = ({ sportsCenterId, refresh }) => {
  const [originalData, setOriginalData] = useState<HalfHourSport[]>([]);
  const [form, setForm] = useState<HalfHourSport[]>([]);
  const [errorMsg, setErrorMsg] = useState<string | null>(null);

  useEffect(() => {
    getHalfHourSports(sportsCenterId)
        .then(({ data }) => {
          setOriginalData(data);
          setForm(data);
        })
        .catch(() => setErrorMsg(translate('unexpectedError')));
  }, [sportsCenterId]);

  const isModified = JSON.stringify(originalData) !== JSON.stringify(form);

  const toggleSport = (sport: Sport, isChecked: boolean) => {
    setForm((prev) =>
        prev.map((item) =>
            item.sport === sport
                ? { ...item, halfHourReservationDays: isChecked ? [...dayOrder] : [] }
                : item
        )
    );
  };

  const toggleDay = (sport: Sport, day: DayOfWeek, isChecked: boolean) => {
    setForm((prev) =>
        prev.map((item) =>
            item.sport === sport
                ? {
                  ...item,
                  halfHourReservationDays: isChecked
                      ? [...item.halfHourReservationDays, day]
                      : item.halfHourReservationDays.filter((d) => d !== day),
                }
                : item
        )
    );
  };

  const handleSave = useCallback(() => {
    updateHalfHourSports(sportsCenterId, form)
        .then(refresh)
        .catch(({ response: { data } }) =>
            setErrorMsg(data?.message || translate('unexpectedError'))
        );
  }, [sportsCenterId, form, refresh]);

  return (
      <div className='d-flex flex-column p-2'>
        {errorMsg && (
            <Row>
              <Col>
                <Alert variant='danger' className='mt-2' style={{ fontSize: '0.85rem' }}>
                  <strong>{errorMsg}</strong>
                </Alert>
              </Col>
            </Row>
        )}

        {form.length > 0 ? (
            <>
              <SRTable>
                <thead>
                <tr>
                  <th>{translate('sport')}</th>
                  <th>{translate('enabled')}</th>
                  {dayOrder.map((day) => (
                      <th key={day}>{translate(day)}</th>
                  ))}
                </tr>
                </thead>
                <tbody>
                {form.sort((a, b) => a.sport.localeCompare(b.sport)).map((sportData) => (
                        <tr key={sportData.sport}>
                          <td>{translate(sportData.sport)}</td>
                          <td>
                            <Form.Check
                                type="switch"
                                checked={sportData.halfHourReservationDays.length > 0}
                                onChange={(e) => toggleSport(sportData.sport, e.target.checked)}
                            />
                          </td>
                          {dayOrder.map((day) => (
                              <td key={day}>
                                {sportData.halfHourReservationDays.length > 0 && (
                                    <Form.Check
                                        checked={sportData.halfHourReservationDays.includes(day)}
                                        onChange={(e) => toggleDay(sportData.sport, day, e.target.checked)}
                                    />
                                )}
                              </td>
                          ))}
                        </tr>
                    ))}
                </tbody>
              </SRTable>

              <Row className="mt-2">
                <Col className="d-flex justify-content-end mt-2">
                  <Button onClick={handleSave} disabled={!isModified} variant="primary" style={{ width: '8rem' }}>
                    {translate('save')}
                  </Button>
                </Col>
              </Row>
            </>
        ) : (
            <h4 className="text-muted my-5 text-center">{translate('noResults')}</h4>
        )}
      </div>
  );
};

export default HalfHourReservations;
