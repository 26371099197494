import React, {useEffect, useRef, useState} from 'react';
import {Alert, Button, Col, Form, Row, Spinner} from 'react-bootstrap';
import translate from '../../../helpers/translations';
import {getRecurringReservations, deleteRecurringReservations, getSportsCenterUsers} from '../../../helpers/api';
import {DAYS_OF_WEEK_SHORT} from "../../../helpers/enums";
import ConfirmationModal from "../../../components/ConfirmationModal";
import {useToastNotifications} from "../../../helpers/notifications";
import RecurringReservationModal from "./RecurringReservationModal";
import {formatDate} from "../../../helpers/date";
import SRTable from "../../../components/SRTable";

const PAGE_SIZE = 50;

interface Props {
  sportsCenter: SportsCenterOld
  refresh: () => any;
}

const RecurringReservations: React.FC<Props> = ({ sportsCenter, refresh }) => {
  const { newToastNotification } = useToastNotifications();
  const [recurringReservations, setRecurringReservations] = useState<RecurringReservation[]>([])
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const [deleteInProgress, setDeleteInProgress] = useState<boolean>(false);
  const [deletingId, setDeletingId] = useState<number | null>(null)
  const [selectedRecurringReservation, setSelectedRecurringReservation] = useState<RecurringReservation | null>(null)
  const [showAddRecurring, setShowAddRecurring] = useState<boolean>(false);
  const openingHours: OpeningHoursOld[] = sportsCenter.openingHours
  const courts: CourtOld[] = sportsCenter.courts.filter(c => c.active)
  const sports: Sport[] = Array.from(new Set(
      sportsCenter.offers
          .flatMap(o => o.courts)
          .flatMap(c => c.sports)
  ))

  const [loading, setLoading] = useState<boolean>(false);
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [totalCount, setTotalCount] = useState<number>(0);

  const [userFilter, setUserFilter] = useState<string>('')
  const [filter, setFilter] = useState<RecurringReservationFilter>({user: '', active: true, sport: sports && sports.length == 1 ? sports[0] : undefined})

  const search = () => {
    setLoading(true);
    setErrorMsg(null);
    setPageNumber(0);
    getRecurringReservations(sportsCenter.id, 0, PAGE_SIZE, filter)
        .then(({ data, headers }) => {
          setLoading(false);
          setRecurringReservations(data);
          setTotalCount(parseInt(headers['x-total-count']));
        })
        .catch(({ response: { data } }) => {
          setLoading(false);
          setRecurringReservations([]);
          setTotalCount(0);
          if (data && data.message) {
            setErrorMsg(data.message);
          } else {
            setErrorMsg(translate('errorFetchingResults') + '.');
          }
        })
  }

  const submit = (e: any) => {
    e.preventDefault()
    search()
  }

  const loadMoreResults = () => {
    setLoading(true);
    getRecurringReservations(sportsCenter.id, pageNumber + 1, PAGE_SIZE, filter)
        .then(({ data, headers }) => {
          setRecurringReservations(recurringReservations.concat(data));
          setPageNumber(pageNumber + 1);
          setTotalCount(parseInt(headers['x-total-count']));
        })
        .catch(({ response: { data } }) => {
          setRecurringReservations([]);
          setTotalCount(0);
          if (data && data.message) {
            setErrorMsg(data.message);
          } else {
            setErrorMsg(translate('errorFetchingResults') + '.');
          }
        })
        .finally(() => setLoading(false))
  }

  const isMounted = useRef(false);
  useEffect(() => {
    if (isMounted.current) {
      const timeOutId = setTimeout(() => {
        if(filter.user != userFilter.trimStart()) {
          setFilter({...filter, user: userFilter.trimStart()})
        }
      }, 500);
      return () => clearTimeout(timeOutId);
    } else {
      isMounted.current = true;
    }
  }, [userFilter]);

  useEffect(search, [filter]);

  const getUserString = (reservation: RecurringReservation) => {
    let userString = '-';
    if(reservation) {
      if (reservation.fullName) {
        userString = reservation.fullName;
      } else if (reservation.email) {
        userString = reservation.email;
      }
    }
    return userString;
  };

  const doDelete = () => {
    if (deletingId) {
      setErrorMsg(null);
      setDeleteInProgress(true);
      deleteRecurringReservations(sportsCenter.id, deletingId)
          .then(() => {
            setDeleteInProgress(false);
            setDeletingId(null);
            refresh();
            newToastNotification(translate('deleteRecurringReservation'), translate('operationSuccessful'))
          })
          .catch(({ response: { data } }) => {
            setDeleteInProgress(false);
            if (data && data.message) {
              setErrorMsg(data.message);
            } else {
              setErrorMsg(translate('unexpectedError') + '.');
            }
          });
    }
  }

  return <div className='d-flex flex-column'>
    <Form onSubmit={submit}>
      <Row>
        <Col sm={12} md={6} xl={5}>
          <Form.Group controlId="form.sport">
            <Form.Label style={{fontSize: '12px', margin: 0}}>{translate('USER')}</Form.Label>
            <Form.Control type="text" placeholder={translate('search')}
                          value={userFilter}
                          onChange={e => setUserFilter(e.target.value)}
            />
          </Form.Group>
        </Col>
        <Col sm="6" md="6" xl={2}>
          <Form.Group controlId="form.sport">
            <Form.Label style={{fontSize: '12px', margin: 0}}>{translate('sport')}</Form.Label>
            <Form.Select
                disabled={sports && sports.length < 2}
                name="sport"
                placeholder={translate('select')}
                value={filter.sport}
                onChange={e => setFilter({...filter, sport: e.target.value as Sport})}
            >
              <option value="">{translate('allSports')}</option>
              {sports.map(s => <option key={s} value={s}>{translate(s)}</option>)}
            </Form.Select>
          </Form.Group>
        </Col>
        <Col sm="6" md="6" xl={2}>
          <Form.Group controlId="form.status">
            <Form.Label style={{fontSize: '12px', margin: 0}}>{translate('status')}</Form.Label>
            <Form.Select
                name="sport"
                placeholder={translate('select')}
                value={filter.active ? 'true' : 'false'}
                onChange={e => setFilter({...filter, active: e.target.value === 'true'})}
            >
              <option value="true">{translate('recurringStatusActive')}</option>
              <option value="false">{translate('recurringStatusExpired')}</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col sm="12" md="6" xl={3} style={{textAlign: 'end'}}>
          <Form.Group controlId="form.add">
            <Form.Label style={{fontSize: '12px', margin: 0}}/>
            <div>
              <Button variant='primary'
                      onClick={() => {
                        setShowAddRecurring(true)
                        setSelectedRecurringReservation(null)
                      }}>{translate('addRecurringReservation')}
              </Button>
            </div>

          </Form.Group>
        </Col>
      </Row>
    </Form>
    {
      recurringReservations && recurringReservations.length > 0 ?
          (
              <>
                <SRTable>
                    <thead>
                    <tr>
                      <th>#</th>
                      <th>{translate('USER')}</th>
                      <th style={{textAlign: 'center'}}>{translate('sport')}</th>
                      <th style={{textAlign: 'center'}}>{translate('period')}</th>
                      <th style={{textAlign: 'center'}}>{translate('court')}</th>
                      <th style={{textAlign: 'center'}}>{translate('days')}</th>
                      <th style={{textAlign: 'center'}}>{translate('time')}</th>
                      <th style={{textAlign: 'center'}}>{translate('phoneNumber')}</th>
                      <th style={{textAlign: 'center'}}>{translate('repetition')}</th>
                      <th/>
                    </tr>
                    </thead>
                    <tbody>
                    {recurringReservations.map((rr, index) =>
                        <tr key={rr.id}>
                          <td>{index + 1}</td>
                          <td>{getUserString(rr)}</td>
                          <td style={{textAlign: 'center'}}>
                            {translate(rr.sport)}
                          </td>
                          <td style={{whiteSpace: 'nowrap', textAlign: 'center'}}>{formatDate(rr.startDate) + ' - ' + formatDate(rr.endDate) }</td>
                          <td style={{textAlign: 'center'}}>{rr.courtName}</td>
                          <td style={{textAlign: 'center'}}>{rr.days.map((d, i) =>
                              translate(d) + (rr.days.length !== i + 1 ? ', ' : ''))}
                          </td>
                          <td style={{whiteSpace: 'nowrap', textAlign: 'center'}}>{rr.fromTime + ' - ' + rr.toTime}</td>
                          <td style={{textAlign: 'center'}}>{rr.phoneNumber ? rr.phoneNumber : '-'}</td>
                          <td style={{textAlign: 'center'}}>{translate("everyWeek" + rr.recurringEveryWeeks)}</td>
                          <td className='text-end' style={{whiteSpace: 'nowrap'}}>
                            <Button size="sm" onClick = {() => {
                              setShowAddRecurring(true)
                              setSelectedRecurringReservation(rr)
                            }}
                                    variant='secondary' className='mx-1' >{translate('edit')}</Button>
                            <Button size="sm" onClick = {() => setDeletingId(rr.id)}
                                    variant='danger' className='mx-1'>
                              {translate('delete')}
                            </Button>
                          </td>
                        </tr>)
                    }
                    </tbody>
                </SRTable>
                {recurringReservations.length < totalCount && <Row>
                  <Col className='d-flex justify-content-center'>
                    <Button variant='link' className='mt-3 mb-5' onClick={loadMoreResults}>{translate('seeMoreResults')}</Button>
                  </Col>
                </Row>}
                {recurringReservations.length === totalCount && <Row>
                  <Col className='d-flex justify-content-center'>
                    <div className='mt-3 mb-5'>{translate('thatsAllResults')}</div>
                  </Col>
                </Row>}
              </>
          ) : (
              <h4 className='text-muted mt-5 text-center'>{translate('noRecurringReservationsAvailable')}.</h4>
          )
    }
    {
      showAddRecurring &&
        <RecurringReservationModal
            recurringReservation={selectedRecurringReservation}
            sportsCenterId={sportsCenter.id}
            halfHourSlot={sportsCenter.halfHourSlot}
            courts={courts}
            openingHours={openingHours}
            onHide={() => {
              setShowAddRecurring(false)
              setSelectedRecurringReservation(null)
            }}
            refresh={() => refresh()}
        />
    }
    {deletingId && <ConfirmationModal
        title={translate('confirmDeleting')}
        body={<>
          {errorMsg && <Alert variant='danger' style={{ fontSize: '0.85rem' }}>
            <div style={{ fontWeight: '700' }}>{errorMsg}</div>
          </Alert>}
          {translate('sureToDeleteRecurringReservation')}?
        </>}
        confirmButtonVariant='danger'
        inProgress={deleteInProgress}
        onConfirm={doDelete}
        onCancel={() => {
          setDeletingId(null);
          setDeleteInProgress(false);
        }}
    />}
    {loading && <Spinner animation='border' variant='secondary' className='mx-auto' />}
  </div>;
}

export default RecurringReservations;