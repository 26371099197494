import React, {useEffect, useState} from 'react';
import {Alert, Badge, Button, Col, Form, Row, Table} from 'react-bootstrap';
import {useNavMenu} from "../../components/NavMenu/NavMenuProvider";
import {
    getAllSportsCenterNotificationRequestsAdmin,
    getSportsCentersLabelsAdmin,
    updateNotificationRequestStatus
} from "../../helpers/api";
import translate from "../../helpers/translations";
import Splash from "../Splash";
import SRTable from "../../components/SRTable";
import {formatDateValue} from "../../helpers/date";
import NotificationRequestDetailsModal from "./NotificationRequestDetailsModal";
import ConfirmationModal from "../../components/ConfirmationModal";
import NotificationNew from "../Users/NotificationNew";

const PAGE_SIZE = 50;

const NotificationRequests: React.FC<{}> = () => {
    const [showSendNotification, setShowSendNotification] = useState<boolean>(false);
    const [sportsCenters, setSportsCenters] = useState<SportsCenterLabel[]>([]);
    const [selectedRequest, setSelectedRequest] = useState<SportsCenterNotificationRequests | null>(null);
    const [requests, setRequests] = useState<SportsCenterNotificationRequests[]>([]);
    const [pageNumber, setPageNumber] = useState<number>(0);
    const [totalCount, setTotalCount] = useState<number>(0);
    const [errorMsg, setErrorMsg] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [filter, setFilter] = useState<SportsCenterNotificationRequestsFilter>({})

    const [approvingRequest, setApprovingRequest] = useState<SportsCenterNotificationRequests | null>(null)
    const [rejectingRequest, setRejectingRequest] = useState<SportsCenterNotificationRequests | null>(null)
    const [reason, setReason] = useState<string>('')

    const {setActiveHeaderKey, setActiveSidebarKey, setSportsCenter} = useNavMenu();

    useEffect(() => {
        setActiveHeaderKey('notification-requests')
        setActiveSidebarKey(undefined)
        setSportsCenter(undefined)

        getSportsCentersLabelsAdmin()
            .then(({data}) => {
                const sportsCenters = data.sort((sc1, sc2) => sc1.name.localeCompare(sc2.name));
                setSportsCenters(sportsCenters);
            })
            .catch(() => {
            });

    }, [])


    useEffect(() => {
        search();
    }, [filter]);

    const search = () => {
        setLoading(true);
        setErrorMsg(null);
        setPageNumber(0);
        getAllSportsCenterNotificationRequestsAdmin(0, PAGE_SIZE, filter)
            .then(({ data, headers }) => {
                setLoading(false);
                setRequests(data);
                setTotalCount(parseInt(headers['x-total-count']));
            })
            .catch(({ response: { data } }) => {
                setLoading(false);
                setRequests([]);
                setTotalCount(0);
                if (data && data.message) {
                    setErrorMsg(data.message);
                } else {
                    setErrorMsg(translate('errorFetchingResults') + '.');
                }
            })
    }

    const submit = (e: any) => {
        e.preventDefault()
        search()
    }

    const loadMoreResults = () => {
        setLoading(true);
        getAllSportsCenterNotificationRequestsAdmin(pageNumber + 1, PAGE_SIZE, filter)
            .then(({ data, headers }) => {
                setRequests(requests.concat(data));
                setPageNumber(pageNumber + 1);
                setTotalCount(parseInt(headers['x-total-count']));
            })
            .catch(({ response: { data } }) => {
                setRequests([]);
                setTotalCount(0);
                if (data && data.message) {
                    setErrorMsg(data.message);
                } else {
                    setErrorMsg(translate('errorFetchingResults') + '.');
                }
            })
            .finally(() => setLoading(false))
    }

    const getStatusBadge = (status: string) => {
        switch (status) {
            case 'PENDING_APPROVAL':
                return <Badge bg='info'>{translate(status)}</Badge>
            case 'CANCELED':
                return <Badge bg='warning'>{translate(status)}</Badge>
            case 'APPROVED':
                return <Badge bg='primary'>{translate(status)}</Badge>
            case 'REJECTED':
                return <Badge bg='danger'>{translate(status)}</Badge>
            case 'NOTIFICATIONS_SENT':
                return <Badge bg='success'>{translate(status)}</Badge>
            case 'EXPIRED':
                return <Badge bg='secondary'>{translate(status)}</Badge>
            default:
                return <Badge bg='secondary'>{translate(status)}</Badge>
        }
    }

    const handleStatusChange = (sportsCenterId: number, requestId: number, newStatus: string) => {
        updateNotificationRequestStatus(sportsCenterId, requestId, newStatus, reason)
            .then(() => {
                setRequests((prevRequests) =>
                    prevRequests.map((request) =>
                        request.id === requestId ? { ...request, status: newStatus, reason: reason } : request
                    )
                );
            })
            .finally(() => setLoading(false))
    }

    const changeStatusButton = (request: SportsCenterNotificationRequests) => {
        switch (request.status) {
            case 'PENDING_APPROVAL':
                return <div style={{whiteSpace: 'nowrap'}}>
                    <Button onClick={e => {
                        setApprovingRequest(request)
                        e.stopPropagation()
                    }} size="sm" variant='primary'>{translate('approve')}</Button>&nbsp;
                    <Button onClick={e => {
                        setRejectingRequest(request)
                        e.stopPropagation()
                    }} size="sm" variant='danger'>{translate('reject')}</Button>
                </div>
            case 'APPROVED':
                return <Button onClick={e => {
                    setRejectingRequest(request)
                    e.stopPropagation()
                }} size="sm" variant='danger'>{translate('reject')}</Button>
            default:
                return null
        }
    }



    return (
        <>
            {approvingRequest != null &&
                <ConfirmationModal
                    title={translate('confirmation')}
                    body={translate('sureApproveSendingNotification') + '?'}
                    confirmButtonVariant='primary'
                    onConfirm={() => {
                        handleStatusChange(approvingRequest?.sportsCenterId, approvingRequest?.id, 'APPROVED')
                        setApprovingRequest(null);
                    }}
                    onCancel={() => {
                        setApprovingRequest(null);
                    }}
                />}
            {rejectingRequest !=null &&
                <ConfirmationModal
                    title={translate('confirmation')}
                    body={<div>
                        {translate('sureRejectSendingNotification') + '?'}
                        <Form className='pt-4'>
                            <Form.Control as='textarea' placeholder={translate('rejectionReason')} rows={3}
                                          value={reason}
                                          onChange={e => setReason(e.target.value)}/>
                        </Form>
                    </div>}
                    confirmButtonVariant='danger'
                    onConfirm={() => {
                        setReason('')
                        handleStatusChange(rejectingRequest?.sportsCenterId, rejectingRequest?.id, 'REJECTED')
                        setRejectingRequest(null)
                    }}
                    onCancel={() => {
                        setReason('')
                        setRejectingRequest(null)
                    }}
                />}
            {errorMsg &&
                <Row>
                    <Col>
                        <Alert variant='danger' style={{fontSize: '0.85rem'}}>
                            <div style={{fontWeight: '700'}}>{errorMsg}</div>
                        </Alert>
                    </Col>
                </Row>
            }
            {loading && <Splash/>}
            <Form onSubmit={submit}>
                <Row>
                    <Col md={4} sm={6}>
                        <Form.Group controlId="form.sportsCenter">
                            <Form.Label
                                style={{fontSize: '12px', margin: 0}}>{translate('sportsCenter')}</Form.Label>
                            <Form.Select value={filter.sportsCenterId ? filter.sportsCenterId : ""}
                                         onChange={e => setFilter({
                                             ...filter,
                                             sportsCenterId: e.target.value ? +e.target.value : undefined
                                         })}
                            >
                                <option value={undefined}>{translate('allSportsCenter')}</option>
                                {sportsCenters.map(sc => <option key={sc.id} value={sc.id}>{sc.name}</option>)}
                            </Form.Select>
                        </Form.Group>
                    </Col>
                    <Col md={4} sm={6}>
                        <Form.Group controlId="form.status">
                            <Form.Label style={{fontSize: '12px', margin: 0}}>{translate('status')}</Form.Label>
                            <Form.Select value={filter.status}
                                         onChange={e => setFilter({...filter, status: (e.target.value)})}
                            >
                                <option value=''>{translate('allStatuses')}</option>
                                <option value='PENDING_APPROVAL'>{translate('PENDING_APPROVAL')}</option>
                                <option value='APPROVED'>{translate('APPROVED')}</option>
                                <option value='CANCELED'>{translate('CANCELED')}</option>
                                <option value='REJECTED'>{translate('REJECTED')}</option>
                                <option value='NOTIFICATIONS_SENT'>{translate('NOTIFICATIONS_SENT')}</option>
                                <option value='EXPIRED'>{translate('EXPIRED')}</option>
                            </Form.Select>
                        </Form.Group>
                    </Col>
                    <Col md={4} sm={12} style={{textAlign: 'end'}}>
                        <Form.Group controlId="form.status">
                            <Form.Label/>
                            <div>
                                <Button className='mx-1' variant='primary' onClick={() => setShowSendNotification(true)}>{translate('sendNotification')}</Button>
                            </div>
                        </Form.Group>
                    </Col>
                </Row>
            </Form>
            <div className='d-flex flex-column'>
                {requests.length !== 0 &&
                    <SRTable tableHover>
                        <thead>
                        <tr>
                            <th>#</th>
                            <th>{translate('sportsCenter')}</th>
                            <th>{translate('title')}</th>
                            <th>{translate('createdAt')}</th>
                            <th>{translate('lastModifiedAt')}</th>
                            <th>{translate('status')}</th>
                            <th/>
                        </tr>
                        </thead>
                        <tbody>
                        {requests.length !== 0 &&
                            requests.map((r, index) =>
                                <tr key={r.id} onClick={() => setSelectedRequest(r)}>
                                    <td className='mx-2'>{totalCount - index}</td>
                                    <td>{r.sportsCenter?.name}</td>
                                    <td>{r.title}</td>
                                    <td style={{whiteSpace: 'nowrap'}}>{formatDateValue(r.createdAt)}</td>
                                    <td style={{whiteSpace: 'nowrap'}}>{formatDateValue(r.lastModifiedAt)}</td>
                                    <td>{getStatusBadge(r.status)}</td>
                                    <td className='text-end'>{changeStatusButton(r)}</td>
                                </tr>)}
                        </tbody>
                    </SRTable>}
                {requests.length === 0 && <h4 className='text-muted my-5 text-center'>{translate('noResults')}</h4>}
                {requests.length < totalCount &&
                    <span>
                    <Row>
                        <Col className='d-flex justify-content-center'>
                            {requests.length} / {totalCount}
                        </Col>
                    </Row>
                    <Row>
                        <Col className='d-flex justify-content-center'>
                          <Button disabled={loading} variant='link' className='mt-1 mb-0'
                                  onClick={() => loadMoreResults()}>{translate('seeMoreResults')}</Button>
                        </Col>
                    </Row>
                </span>
                }
                {requests.length >= totalCount && requests.length > 0 && <Row>
                    <Col className='d-flex justify-content-center'>
                        <div className='mt-3 mb-5'>{translate('thatsAllResults')}</div>
                    </Col>
                </Row>}
                {selectedRequest &&
                    <NotificationRequestDetailsModal request={selectedRequest}
                                             onHide={() => setSelectedRequest(null)}
                    />
                }
                {showSendNotification &&
                    <NotificationNew onHide={() => setShowSendNotification(false)}/>
                }
            </div>
        </>
    )
}

export default NotificationRequests;