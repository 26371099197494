import React, { useEffect, useState } from 'react';
import { Alert, Button, Col, Form, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import {useConfiguration} from "../../helpers/configuration";
import {useToastNotifications} from "../../helpers/notifications";
import {createSportsCenterAdmin} from "../../helpers/api";
import Splash from "../Splash";
import translate from "../../helpers/translations";


const NewCenter: React.FC = () => {
  const configuration = useConfiguration();

  const { newToastNotification } = useToastNotifications();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const [countries, setCountries] = useState<any>([])
  const [cities, setCities] = useState<any>([])
  const [neighborhoods, setNeighborhoods] = useState<any>([])

  const [form, setForm] = useState<CreateSportsCenterRequest>({
    name: null,
    description: null,
    countryId: null,
    cityId: null,
    neighborhoodId: null,
    address: null,
    latitude: null,
    longitude: null,
    phoneNumber: null,
    website: null,
    parking: false,
    bar: false,
    requiredPhoneNumber: false,
  });

  useEffect(() => {
    if(configuration) {
      setCountries(configuration.countries);
      // @ts-ignore
      const cities = form.countryId ? (countries.find(c => c.id === form.countryId)?.cities || []) : configuration.countries.flatMap(c => c.cities);
      setCities(cities)
      // @ts-ignore
      const neighborhoods = form.cityId ? (cities.find(c => c.id === form.cityId)?.neighborhoods || []) : cities.flatMap(c => c.neighborhoods);
      setNeighborhoods(neighborhoods)
    }
  }, [configuration, form.countryId, form.cityId]);

  if (loading) {
    return <Splash />;
  }


  const onSubmit = () => {
    setLoading(true);
    createSportsCenterAdmin(form)
      .then(() => {
        setLoading(false);
        navigate('/centers');
        newToastNotification(translate('addCenter'), translate('operationSuccessful') + '.');
      })
      .catch(({ response: { data } }) => {
        setLoading(false);
        if (data && data.message) {
          setErrorMsg(data.message);
        } else {
          setErrorMsg(translate('unexpectedError') + '.');
        }
      })
  }


  // @ts-ignore
  return <>
    <h4 className='my-0'>{translate('addNewCenter')}</h4>
    <hr/>
    <Form>
      {errorMsg && <Alert variant='danger' className='mt-4' style={{ fontSize: '0.85rem' }}>
        <div style={{ fontWeight: '700' }}>{translate('unexpectedError')}.</div>
        {errorMsg}
      </Alert>}
      <Row>
        <Col>
          <Form.Group>
            <Form.Label className='text-muted'>{translate('name')}</Form.Label>
            <Form.Control value={form.name === null ? '' : form.name} onChange={e => setForm({ ...form, name: e.target.value || null })} />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col className='mb-2'>
          <Form.Group>
            <Form.Label className='text-muted'>{translate('description')}</Form.Label>
            <Form.Control as='textarea' placeholder={translate('addDescription')} rows={4} value={form.description === null ? '' : form.description} onChange={e => setForm({ ...form, description: e.target.value || null })} />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col className='mb-2'>
          <Form.Group>
            <Form.Label className='text-muted'>{translate('address')}</Form.Label>
            <Form.Select value={form.countryId === null ? '' : form.countryId} onChange={e => setForm({ ...form, countryId: e.target.value === '' ? null : parseInt(e.target.value), cityId: null, neighborhoodId: null })}>
              <option key='' value=''>{translate('selectCountry')}</option>
              {// @ts-ignore
                countries?.map(c => <option key={c.id} value={c.id}>{c.name}</option>)
              }
            </Form.Select>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col className='mb-2'>
          <Form.Select value={form.cityId === null ? '' : form.cityId} onChange={e => setForm({ ...form, cityId: e.target.value === '' ? null : parseInt(e.target.value), neighborhoodId: null })}>
            <option key='' value=''>{translate('selectCity')}</option>
            {// @ts-ignore
              cities?.map(c => <option key={c.id} value={c.id}>{c.name}</option>)
            }
          </Form.Select>
        </Col>
      </Row>
      <Row>
        <Col className='mb-2'>
          <Form.Select value={form.neighborhoodId === null ? '' : form.neighborhoodId} onChange={e => setForm({ ...form, neighborhoodId: e.target.value === '' ? null : parseInt(e.target.value) })}>
            <option key='' value=''>{translate('selectNeighborhood')}</option>
            {// @ts-ignore
              neighborhoods?.map(n => <option key={n.id} value={n.id}>{n.name}</option>)
            }
          </Form.Select>
        </Col>
      </Row>
      <Row>
        <Col className='mb-2'>
          <Form.Control placeholder={translate('address')} value={form.address === null ? '' : form.address} onChange={e => setForm({ ...form, address: e.target.value || null })} />
        </Col>
      </Row>
      <Row>
        <Col md={6} className='mb-2'>
          <Form.Group>
            <Form.Label className='text-muted'>{translate('latitude')}</Form.Label>
            <Form.Control type='number' value={form.latitude === null ? '' : form.latitude} onChange={e => setForm({ ...form, latitude: e.target.value === '' ? null : parseFloat(e.target.value) })} />
          </Form.Group>
        </Col>
        <Col md={6} className='mb-2'>
          <Form.Group>
            <Form.Label className='text-muted'>{translate('longitude')}</Form.Label>
            <Form.Control type='number' value={form.longitude === null ? '' : form.longitude} onChange={e => setForm({ ...form, longitude: e.target.value === '' ? null : parseFloat(e.target.value) })} />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group>
            <Form.Label className='text-muted'>{translate('phoneNumber')}</Form.Label>
            <Form.Control type='text' value={form.phoneNumber === null ? '' : form.phoneNumber} onChange={e => setForm({ ...form, phoneNumber: e.target.value === '' ? null : e.target.value })} />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group>
            <Form.Label className='text-muted'>{translate('website')}</Form.Label>
            <Form.Control type='text' value={form.website === null ? '' : form.website} onChange={e => setForm({ ...form, website: e.target.value === '' ? null : e.target.value })} />
          </Form.Group>
        </Col>
      </Row>
      <Form.Label className='text-muted'/>
      <Row>
        <Col>
          <Form.Check checked={form.parking || false} label={translate('parking')} onChange={e => setForm({ ...form, parking: ((e.target as HTMLInputElement).checked ? true : null) })} />
        </Col>
        <Col>
          <Form.Check checked={form.bar || false} label={translate('bar')} onChange={e => setForm({ ...form, bar: ((e.target as HTMLInputElement).checked ? true : null) })} />
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Check checked={form.requiredPhoneNumber || false} label={translate('reservationPhoneNumberRequired')} onChange={e => setForm({ ...form, requiredPhoneNumber: (e.target as HTMLInputElement).checked })} />
        </Col>
      </Row>
    </Form>
    <Row>
      <Col>
        <div className='d-flex justify-content-end mb-5'>
          <Button variant='primary' style={{ width: '8rem' }} onClick={onSubmit}>{translate('save')}</Button>
        </div>
      </Col>
    </Row>
  </>
}

export default NewCenter;