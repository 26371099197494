import React, { useEffect, useState } from 'react';
import { Alert, Button, Col, Container, Form, Modal, Row, Spinner } from 'react-bootstrap';
import { RiAddFill, RiSubtractFill } from 'react-icons/ri';
import { createReservation } from '../../../helpers/api';
import { dateToDateValue, formatDateValue } from '../../../helpers/date';
import { useToastNotifications } from '../../../helpers/notifications';
import translate from '../../../helpers/translations';
import {sportIcons} from "../../../helpers/sportIcons";

interface Props {
  sportsCenterId: number;
  show: boolean;
  courts: CourtOld[];
  halfHourSlot: boolean;
  allowedDurations: number[];
  form: ReservationForm | null;
  setForm: (form: ReservationForm) => any;
  refresh: () => any;
  onHide: () => any;
}

const fixDate = (form: ReservationForm) => {
  const to = new Date(form.toDateTime);
  if (to.getHours() === 0) {
    to.setHours(to.getHours() - 1);
    to.setMinutes(59);
    to.setSeconds(0);
    return { ...form, to: dateToDateValue(to) };
  } else {
    return form;
  }
}

const CenterReservationModal: React.FC<Props> = ({ sportsCenterId, show, courts, allowedDurations, halfHourSlot, form, setForm, refresh, onHide }) => {
  const { newToastNotification } = useToastNotifications();
  const [duration, setDuration] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const court: CourtOld | undefined = courts.find(c => c.id == form?.courtId);

  const updateDuration = (amount: number) => {
    if (form) {
      const dateTo = new Date(form.fromDateTime);
      if(dateTo.getMinutes() == 0) {
        dateTo.setMinutes((duration + amount) % 1 == 0 ? 0 : 30);
        dateTo.setHours((duration + amount) % 1 == 0 ? dateTo.getHours() + duration + amount : dateTo.getHours() + duration + amount - 0.5);
      } else {
        dateTo.setMinutes((duration + amount) % 1 != 0 ? 0 : 30);
        dateTo.setHours((duration + amount) % 1 != 0 ? dateTo.getHours() + duration + amount + 0.5 : dateTo.getHours() + duration + amount);
      }

      setForm({ ...form, toDateTime: dateToDateValue(dateTo) });
      setDuration(duration + amount);
    }
  }

  const createDisabled = () => {
    return !form || (
        !form.fullName &&
        !form.email &&
        !form.phoneNumber);
  }

  const submit = () => {
    if (form) {
      setErrorMsg(null);
      setLoading(true);
      createReservation(sportsCenterId, fixDate(form))
        .then(() => {
          setLoading(false);
          onHide();
          refresh();
          newToastNotification(translate('addReservation'), translate('operationSuccessful') + '.');
        })
        .catch(({ response: { data } }) => {
          setLoading(false);
          if (data && data.message) {
            setErrorMsg(data.message);
          } else {
            setErrorMsg(translate('unexpectedError') + '.');
          }
        })
    }
  }

  useEffect(() => {
    setDuration(allowedDurations.find(d => d == 1) || 0.5);
  }, [allowedDurations]);

  useEffect(() => {
    setErrorMsg(null);
  }, [form])

  return <Modal show={show}>
    <Modal.Header className='flex-column justify-content-start align-items-start'>
      <strong>{translate('addReservation')}</strong>
      {form && <div className='mt-1 text-center text-muted'>
        {formatDateValue(form.fromDateTime)} - {formatDateValue(form.toDateTime)}
      </div>}
      <div className='mt-auto mb-1'>
        {court && court.name},&nbsp;
        {court && court.sports && court.sports.length === 1 ? translate(court.sports[0]) : translate('multipleSports')} &nbsp;
        {court && court.sports
            .map(s => s.toLowerCase().replaceAll('-', '_'))
            .map(name =>
                <span key={name}>
                  <img height={20} width={20} src={sportIcons[name as keyof typeof sportIcons]} alt={name} style={{ margin: '1px' }} />
                </span>
            )}
      </div>
    </Modal.Header>
    <Modal.Body>
      <Form>
        <Container>
          {errorMsg && <Row>
            <Col>
              <Alert variant='danger' className='mt-2' style={{ fontSize: '0.85rem' }}>
                <div style={{ fontWeight: '700' }}>{errorMsg}</div>
              </Alert>
            </Col>
          </Row>}
          {court && court.sports.length > 1 && <Row className='mb-3'>
            <Col>
              <Form.Label>{translate('sport')}</Form.Label>
              <Form.Select onChange={e => form && setForm({ ...form, sport: e.target.value })}>
                {court && court.sports.map(s => <option key={s} value={s}>{translate(s)}</option>)}
              </Form.Select>
            </Col>
          </Row>}
          <Row className='mb-3'>
            <Col>
              <Form.Label>{translate('duration')}</Form.Label>
              <div className='mx-auto d-flex justify-content-center align-items-center border rounded' style={{ width: 'min-content' }}>
                <Button
                  variant='link'
                  className='text-secondary'
                  disabled={!allowedDurations.includes(duration - (halfHourSlot ? 0.5 : 1))}
                  onClick={() => updateDuration(halfHourSlot ? -0.5 : -1)}
                >
                  <RiSubtractFill />
                </Button>
                <div className='px-3 text-secondary fs-5'>{duration}</div>
                <Button
                  variant='link'
                  className='text-secondary'
                  disabled={!allowedDurations.includes(duration + (halfHourSlot ? 0.5 : 1))}
                  onClick={() => updateDuration(halfHourSlot ? +0.5 : +1)}
                >
                  <RiAddFill />
                </Button>
              </div>
            </Col>
          </Row>
          <Row className='mb-3'>
            <Col>
              <Form.Label>{translate('email')}</Form.Label>
              <Form.Control value={form ? form.email : ''} onChange={e => form && setForm({ ...form, email: e.target.value })} />
            </Col>
          </Row>
          <Row className='mb-3'>
            <Col>
              <Form.Label>{translate('fullName')}</Form.Label>
              <Form.Control value={form ? form.fullName : ''} onChange={e => form && setForm({ ...form, fullName: e.target.value })} />
            </Col>
          </Row>
          <Row className='mb-3'>
            <Col>
              <Form.Label>{translate('phoneNumber')}</Form.Label>
              <Form.Control value={form ? form.phoneNumber : ''} onChange={e => form && setForm({ ...form, phoneNumber: e.target.value })} />
            </Col>
          </Row>
          <Row className='mb-3'>
            <Col md={12} className='my-2 d-flex'>
              <Form.Label>{translate('paymentSuccessful')}&nbsp;&nbsp;</Form.Label>
              <Form.Check checked={form ? form.isPaid : false} onChange={e => form && setForm({ ...form, isPaid: ((e.target as HTMLInputElement).checked) })} />
            </Col>
          </Row>
        </Container>
      </Form>
    </Modal.Body>
    <Modal.Footer>
      {loading && <Spinner animation='border' variant='secondary' className='mx-auto' />}
      {!loading && <>
        <Button variant='secondary' onClick={() => onHide()}>{translate('close')}</Button>
        <Button variant='primary' disabled={createDisabled()} onClick={() => submit()}>{translate('save')}</Button>
      </>}
    </Modal.Footer>
  </Modal>;
}

export default CenterReservationModal;