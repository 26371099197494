import React, { useEffect, useState } from 'react';
import { Alert, Button, Col, Container, Form, Modal, Row, Spinner } from 'react-bootstrap';
import { updateReservation } from '../../../helpers/api';
import {
  dateStringEquals,
  dateToDateValue,
  dateToTimeValue,
  dateValueToDate, END_OF_DAY,
  formatDateValue, subtractMinutes, addMinutes
} from '../../../helpers/date';
import { useToastNotifications } from '../../../helpers/notifications';
import translate from '../../../helpers/translations';
import DatePicker from "react-datepicker";

interface Props {
  show: boolean;
  courts: CourtOld[];
  sportsCenterId: number;
  reservation: Reservation;
  openingHours: OpeningHoursOld[];
  halfHourSlot: boolean
  refresh: () => any;
  onHide: () => any;
}

const CenterReservationUpdateModal: React.FC<Props> = ({ show, courts, sportsCenterId, reservation, refresh, onHide, openingHours, halfHourSlot}) => {
  const { newToastNotification } = useToastNotifications();
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const [sports, setSports] = useState<Sport[]>([]);
  const [isReservationStarted, setIsReservationStarted] = useState(new Date(reservation.fromDateTime) < new Date());
  const [form, setForm] = useState<ReservationUpdateForm>({
    id: reservation.id,
    email: reservation.user ? reservation.user.email : reservation.email,
    fullName: reservation.fullName,
    phoneNumber: reservation.phoneNumber,
    isPaid: reservation.isPaid,
    courtId: reservation.court.id,
    fromDateTime: reservation.fromDateTime,
    toDateTime: reservation.toDateTime,
    sport: reservation.sport,
    paymentMethod: reservation.paymentMethod,
    noShow: reservation.status == 'NO_SHOW'
  });

  const submit = () => {
    if (form) {
      setErrorMsg(null);
      setLoading(true);
      updateReservation(sportsCenterId, form)
        .then(() => {
          setLoading(false);
          onHide();
          refresh();
          newToastNotification(translate('editReservation'), translate('operationSuccessful') + '.');
        })
        .catch(({ response: { data } }) => {
          setLoading(false);
          if (data && data.message) {
            setErrorMsg(data.message);
          } else {
            setErrorMsg(translate('unexpectedError') + '.');
          }
        })
    }
  }

  useEffect(() => {
    // Check every second if fromDateTime is in the past
    const interval = setInterval(() => {
      setIsReservationStarted(new Date(form.fromDateTime) < new Date());
    }, 1000); // Check every second

    return () => clearInterval(interval); // Cleanup on unmount
  }, [form.fromDateTime]); // Re-run effect when fromDateTime changes

  useEffect(() => {
    setErrorMsg(null);
  }, [form])

  useEffect(() => {
    if (form) {
      const court = courts.find(c => c.id === form.courtId);
      setSports(court ? court.sports : []);
    } else {
      setSports([]);
    }
  }, [courts, form]);

  return <Modal show={show}>
    <Modal.Header className='flex-column justify-content-start align-items-start'>
      <strong>{translate('editReservation')}</strong>
      {reservation && <div className='mt-1 text-center text-muted'>
        {formatDateValue(reservation.fromDateTime)} - {formatDateValue(reservation.toDateTime)}
      </div>}
    </Modal.Header>
    <Modal.Body>
      <Form>
        <Container>
          {errorMsg && <Row>
            <Col>
              <Alert variant='danger' className='mt-2' style={{ fontSize: '0.85rem' }}>
                <div style={{ fontWeight: '700' }}>{errorMsg}</div>
              </Alert>
            </Col>
          </Row>}
          {reservation.type != 'MOBILE' &&
              <>
                <Row className='mb-3'>
                  <Col sm={6}><Form.Label>{translate('from')}</Form.Label></Col>
                  <Col sm={6}><Form.Label>{translate('to')}</Form.Label></Col>
                  <Col sm={6}>
                    <DatePicker
                        selected={form.fromDateTime ? dateValueToDate(form.fromDateTime) : null}
                        showTimeSelect
                        onChange={date => setForm({ ...form, fromDateTime: date ? dateToDateValue(date) : ''})}
                        dateFormat='dd.MM.yyyy. HH:mm'
                        customInput={<Form.Control />}
                        timeIntervals={halfHourSlot ? 30 : 60}
                        filterTime={time => {
                          const timeString = dateToTimeValue(time);
                          return openingHours.some(oh =>  timeString >= oh.from && timeString <= oh.to);
                        }}
                    />
                  </Col>
                  <Col sm={6}>
                    <DatePicker
                        selected={form.fromDateTime && form.toDateTime
                            ? (dateStringEquals(form.fromDateTime, form.toDateTime) || (addMinutes(END_OF_DAY, 1) !== dateValueToDate(form.toDateTime))
                                ? dateValueToDate(form.toDateTime)
                                : subtractMinutes(dateValueToDate(form.toDateTime), 1))
                            : null}
                        showTimeSelect
                        onChange={date => setForm({ ...form, toDateTime: date ? dateToDateValue(date) : ''})}
                        dateFormat='dd.MM.yyyy. HH:mm'
                        customInput={<Form.Control />}
                        timeIntervals={halfHourSlot ? 30 : 60}
                        injectTimes={[END_OF_DAY]}
                        filterTime={time => {
                          const timeString = dateToTimeValue(time);
                          return openingHours.some(oh =>  timeString >= oh.from && timeString <= oh.to);
                        }}
                    />
                  </Col>
                </Row>
                <Row className='mb-3'>
                  <Col>
                    <Form.Label>{translate('email')}</Form.Label>
                    <Form.Control value={form.email ? form.email : ''} onChange={e => form && setForm({ ...form, email: e.target.value })} />
                  </Col>
                </Row>
                <Row className='mb-3'>
                  <Col>
                    <Form.Label>{translate('fullName')}</Form.Label>
                    <Form.Control value={form.fullName ? form.fullName : ''} onChange={e => form && setForm({ ...form, fullName: e.target.value })} />
                  </Col>
                </Row>
                <Row className='mb-3'>
                  <Col>
                    <Form.Label>{translate('phoneNumber')}</Form.Label>
                    <Form.Control value={form.phoneNumber ? form.phoneNumber : ''} onChange={e => form && setForm({ ...form, phoneNumber: e.target.value })} />
                  </Col>
                </Row>
              </>
          }
          <Row className='mb-3'>
            <Form.Label>{translate('court')}</Form.Label>
            <Col>
              <Form.Select value={form.courtId} onChange={e => setForm({ ...form, courtId: parseInt(e.target.value) })}>
                {courts.filter(c => c.sports.findIndex(s => s.valueOf() === form.sport) !== -1).map(c => <option key={c.id} value={c.id}>{c.name}</option>)}
              </Form.Select>
            </Col>
          </Row>
          {reservation.type != 'MOBILE' &&
              <Row className='mb-3'>
                <Form.Label>{translate('sport')}</Form.Label>
                <Col>
                  <Form.Select value={form.sport} onChange={e => form && setForm({ ...form, sport: e.target.value })}>
                    {sports.map(s => <option key={s} value={s}>{translate(s)}</option>)}
                  </Form.Select>
                </Col>
              </Row>
          }
          <Row className='mb-3'>
            {reservation.paymentMethod === "ON_SITE" &&
                <Col md={6} className='my-2 d-flex'>
                  <Form.Check checked={form.isPaid} onChange={e => form && setForm({ ...form, isPaid: ((e.target as HTMLInputElement).checked) })} />&nbsp;&nbsp;
                  <Form.Label>{translate('paymentSuccessful')}</Form.Label>
                </Col>
            }
            {isReservationStarted && (
                <Col md={6} className="my-2 d-flex">
                  <Form.Check
                      checked={form.noShow}
                      onChange={e =>
                          form && setForm({ ...form, noShow: (e.target as HTMLInputElement).checked })
                      }
                  />
                  &nbsp;&nbsp;
                  <Form.Label>{translate('userDidNotAppear')}</Form.Label>
                </Col>
            )}
          </Row>
        </Container>
      </Form>
    </Modal.Body>
    <Modal.Footer>
      {loading && <Spinner animation='border' variant='secondary' className='mx-auto' />}
      {!loading && <>
        <Button variant='secondary' onClick={() => onHide()}>{translate('close')}</Button>
        <Button variant='primary'  onClick={() => submit()}>{translate('save')}</Button>
      </>}
    </Modal.Footer>
  </Modal>;
}

export default CenterReservationUpdateModal;