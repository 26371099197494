const en: { [key: string]: string } = {
  activate: 'Activate',
  activateCourt: 'Activate court',
  addCancellationReason: 'Add cancellation reason here',
  addCenter: 'Add center',
  addCourt: 'Add court',
  addDescription: 'Add description',
  addedReservations: 'Added ?1 reservation(s)',
  addNewCenter: 'Add new center',
  addOffer: 'Add offer',
  addOpeningHours: 'Add opening hours',
  addPremiumUser: 'Add premium reservation',
  addRecurringReservation: 'Add recurring reservation',
  addReservation: 'Add reservation',
  address: 'Address',
  addSportsCenterAdmin: 'Add sports center administrator',
  addTimeOff: 'Add time off',
  ADMINISTRATOR: 'Administrator',
  superAdmins: 'Super-administrators',
  allCities: 'All cities',
  allCountries: 'All countries',
  allNeighborhoods: 'All neighborhoods',
  allSports: 'All sports',
  APRIL: 'April',
  ARTIFICIAL_GRASS: 'Artificial grass',
  AUGUST: 'August',
  BADMINTON: 'Badminton',
  bar: 'Bar',
  BASKETBALL: 'Basketball',
  BOWLING: 'Bowling',
  CAGEBALL: 'Cageball',
  cancel: 'Cancel',
  cancellationReason: 'Cancellation reason',
  CARD: 'Card',
  cardReservationDeleteWarning: 'Warning!\n This reservation is prepaid. If you want to cancel this reservation, you must ensure replacement appointment to the end user.',
  centers: 'Centers',
  CLAY: 'Clay',
  CLIMBING: 'Climbing',
  close: 'Close',
  CONCRETE: 'Concrete',
  confirm: 'Confirm',
  confirmDeleting: 'Confirm deleting',
  confirmDeletingOpeningHours: 'Are you sure you want to delete opening hours',
  confirmEditing: 'Confirm editing',
  confirmEditingOpeningHours: 'Are you sure you want to proceed with editing opening hours',
  confirmSavingOpeningHours: 'Are you sure you want to proceed with saving opening hours',
  configuration: 'Configuration',
  consoleOnlyAvailableToAdmins: 'Console is only available to administrators',
  couldNotFetchSportsCenterDetails: 'Could not fetch details for sports center.',
  couldNotLogYouIn: 'Could not log you in',
  court: 'Court',
  courts: 'Courts',
  allCourts: 'All courts',
  courtType: 'Court type',
  coverImage: 'Cover image',
  currency: 'Currency',
  dateRange: 'Date range',
  dayOfWeek: 'Day of week',
  days: 'Days',
  deactivate: 'Deactivate',
  deactivateCourt: 'Deactivate court',
  DECEMBER: 'December',
  delete: 'Delete',
  deleteImage: 'Delete image',
  deleteCoverImage: 'Delete cover image',
  deleteOffer: 'Delete offer',
  deleteOpeningHours: 'Delete opening hours',
  deleteRecurringReservation: 'Delete recurring reservation',
  deleteReservation: 'Delete reservation',
  deleteSportsCenter: 'Delete sports center',
  deleteTimeOff: 'Delete time off',
  deleteUser: 'Delete user',
  description: 'Description',
  dimensions: 'Dimensions',
  duration: 'Duration',
  edit: 'Edit',
  editCenter: 'Edit center',
  editCourt: 'editCourt',
  editOffer: 'Edit offer',
  editOpeningHours: 'Edit opening hours',
  editReservation: 'Edit reservation',
  editTimeOff: 'Edit time off',
  email: 'Email',
  end: 'End',
  errorDeletingSportsCenter: 'There was an error deleting sports center',
  errorDeletingUser: 'There was an error deleting user. please try later',
  errorFetchingResults: 'Unexpected error fetching results',
  errorOccurred: 'An error occurred',
  everyWeek1: 'Every week',
  everyWeek2: 'Every second week',
  everyWeek3: 'Every third week',
  FEBRUARY: 'February',
  firstName: 'First name',
  fullName: 'Name',
  FOOTBALL_TENNIS: 'Football tennis',
  FRI: 'Fri',
  FRIDAY: 'Friday',
  from: 'From',
  GOLF: 'Golf',
  goToHomepage: 'Go to homepage',
  GRASS: 'Grass',
  halfHourSlot: 'Half hour reservation',
  HANDBALL: 'Handball',
  hidden: 'Hidden',
  hours: 'Hours',
  ICE: 'Ice',
  id: 'ID',
  image: 'Image',
  INDOOR: 'Indoor',
  info: 'Info',
  JANUARY: 'January',
  JULY: 'July',
  JUNE: 'June',
  lastName: 'Last name',
  lat: 'Lat',
  latitude: 'Latitude',
  legend: 'Legend',
  length: 'Length',
  lng: 'Lng',
  loading: 'Loading',
  login: 'Login',
  logout: 'Logout',
  longitude: 'Longitude',
  makeSureCorrectCredentials: 'Make sure you entered correct email and password',
  manualEntry: 'Manual entry',
  MARCH: 'March',
  maxDistanceMeters: 'Max distance (meters)',
  maxRating: 'Max rating',
  MAY: 'May',
  meters: 'meters',
  minRating: 'Min rating',
  minutes: 'minutes',
  MON: 'Mon',
  MONDAY: 'Monday',
  multipleSports: 'Multiple sports',
  name: 'Name',
  newCenter: 'New center',
  noCourtsAvailable: 'No courts available',
  noCoverImage: 'No cover image set',
  noHoursAdded: 'No hours added',
  noOffersAvailable: 'No offers available',
  noOpeningHours: 'No opening hours available',
  noPremiumUsers: 'No premium users',
  noRecurringReservationsAvailable: 'No recurring reservations available',
  noReservationsAvailable: 'No reservations available',
  noResults: 'No results',
  noTimesOffAvailable: 'No times off available',
  NOVEMBER: 'November',
  number: 'Number',
  OCTOBER: 'October',
  offers: 'Offers',
  ON_SITE: 'On-Site',
  openingHours: 'Opening hours',
  operationSuccessful: 'Operation completed successfully',
  OUTDOOR: 'Outdoor',
  PADEL: 'Padel',
  parking: 'Parking',
  password: 'Password',
  paymentMethod: 'Payment methods',
  paymentSuccessful: 'Paid',
  period: 'Period',
  person: 'Person',
  personOrEmail: 'Person/Email',
  phoneNumber: 'Phone number',
  premiumUsers: 'Premium users',
  price: 'Price',
  pricePerHour: 'Price (per hour)',
  proceed: 'Proceed',
  proceedAddingTimeOff: 'Are you sure you want to proceed with adding time off',
  QUARTZ_SAND: 'Quartz sand',
  query: 'Query',
  range: 'Range',
  rating: 'Rating',
  recurringReservation: 'Recurring reservation',
  recurringReservations: 'Recurring reservations',
  refresh: 'Refresh',
  repetition: 'Repetition',
  reservation: 'Reservation',
  reservationDetails: 'Reservation details',
  reservationCardPaid: 'Reservation paid by card inside mobile application',
  reservationColors: 'Reservation colors',
  reservationEmailNotifications: 'Reservation email notifications',
  reservationPaid: 'Reservation paid in sport center',
  reservationPhoneNumberRequired: 'Mandatory phone number for reservation',
  reservations: 'Reservations',
  reservationsAlreadyScheduled: 'There are reservations already scheduled for that period',
  reservationsAlreadyScheduledDelete: 'There are reservations already scheduled for that period that will be deleted',
  reservationsOutsideOfOpeningHours: 'There are reservations which will now be outside of opening hours',
  reservationTime: 'Reservation time',
  reserved: 'Reserved',
  reservedUsingMobile: 'Reserved using mobile application',
  role: 'Role',
  RUBBER: 'Rubber',
  SAND: 'Sand',
  SAT: 'Sat',
  SATURDAY: 'Saturday',
  save: 'Save',
  saveSorting: 'Save sorting',
  screen: 'Screen',
  search: 'Search',
  seeMoreResults: 'See more results',
  selectCity: 'Select city',
  selectCountry: 'Select country',
  selectCourt: 'Select court',
  selectDaysOfWeek: 'Select days of week',
  selectDuration: 'Select duration',
  selectNeighborhood: 'Select neighborhood',
  selectSport: 'Select sport',
  sendNotification: 'Send notification',
  SEPTEMBER: 'September',
  SOCCER: 'Football',
  sport: 'Sport',
  sportsCenterOwners: 'Sports center owners',
  sports: 'Sports',
  sportsCenter: 'Sports center',
  SPORTS_CENTER: 'Sports center owner',
  sportsCenterSuccessfullyDeleted: 'Sports center successfully deleted!',
  SQUASH: 'Squash',
  start: 'Start',
  stripeAccountId: 'Stripe Account ID',
  SUN: 'Sun',
  SUNDAY: 'Sunday',
  sureDeletingUser: 'Are you sure you want to delete this user',
  sureToDelete: 'Are you sure you want to delete',
  sureToDeleteConfirm: 'After this step, the center will be permanently deleted! Do you want to continue?',
  sureToDeleteOffer: 'Are you sure you want to delete offer',
  sureToDeletePremiumUser: 'Are you sure you want to delete premium user',
  sureToDeleteRecurringReservation: 'Are you sure you want to delete recurring reservations',
  sureToDeleteReservation: 'Are you sure you want to delete the reservation',
  sureToDeleteTimeOff: 'Are you sure you want to delete time off',
  surfaceType: 'SurfaceType',
  TABLE_TENNIS: 'Table tennis',
  TATAMI: 'Tatami',
  TENNIS: 'Tennis',
  text: 'Text',
  thatsAllResults: 'That\'s all results.',
  THU: 'Thu',
  THURSDAY: 'Thursday',
  time: 'Time',
  timeOff: 'Time off',
  timesOff: 'Times off',
  timeZone: 'Time zone',
  selectTimeZone: 'Select time zone',
  title: 'Title',
  to: 'To',
  today: 'Today',
  type: 'Type',
  TUE: 'Tue',
  TUESDAY: 'Tuesday',
  unexpectedError: 'Unexpected error occurred',
  unsupportedFormat: 'Unsupported format',
  uploadImage: 'Upload image',
  uploadCoverImage: 'Upload cover image',
  USER: 'User',
  users: 'Users',
  userSuccessfullyDeleted: 'User successfully deleted',
  visible: 'Visible',
  VOLLEYBALL: 'Volleyball',
  website: 'Website',
  WED: 'Wed',
  WEDNESDAY: 'Wednesday',
  width: 'Width',
  WOOD: 'Wood',
  reservationsFromAdmin: 'Reservations created by the administrator',
  reservationsFromApp: 'Reservations made through the mobile application',
  backgroundColor: 'Background color',
  textColor: 'Text color',
  preview: 'Preview',
  enableCustomColor: 'Enable custom colors',
  gallery: 'Gallery',
  enableGallery: 'Enable gallery',
  centerInformation: 'Center information',
  reservationCancellation: 'Reservation cancellation',
  timeBeforeStart: 'Time before start (h)',
  enabled: 'Enabled',
  searchReservations: 'Search reservations',
  select: 'Select',
  resFilterAll: 'All',
  resFilterAllReservation: 'All reservations',
  resFilterCanceled: 'Canceled',
  resFilterSuccessful: 'Successful',
  resFilterSuccessfulAndCanceled: 'Successful and canceled',
  resFilterAllPaymentMethods: 'All payment methods',
  resFilterCash: 'Cash',
  resFilterCard: 'Card',
  resFilterRecurring: 'Recurring reservations',
  resFilterFromAdmin: 'Created by admin',
  resFilterFromMobile: 'Reserved by mobile app',
  labelReservationType: 'Reservation type',
  labelReservationStatus: 'Reservation status',
  labelTimePeriod: 'Time period',
  allSportsCenter: 'All sports centers',
  reservationCreatedAt: 'Time of reservation creation',
  cancelledAt: 'Reservation cancellation time',
  cancelledBy: 'Cancelled by',
  status: 'Status',
  RESERVED: 'Reserved',
  COMPLETED: 'Completed',
  CANCELED: 'Canceled',
  yes: 'Yes',
  no: 'No',
  noData: 'No data',
  menu: 'Menu',
  aboutCenter: 'About center',
  general: 'General',
  workingTime: 'Working time',
  table: 'Table',
  colorSettings: 'Color settings',
  sportsCourts: 'Sports courts',
  superAdmin: 'Superadmin',
  view: 'View',
  statistics: 'Statistics',
  cache: 'Cache',
  clear: 'Clear',
  clearCache: 'Clear cache',
  hits: 'Hits',
  misses: 'Misses',
  hitPercentage: 'Hit percentage',
  missPercentage: 'Miss percentage',
  gets: 'Gets',
  averageGetTime: 'Average get time',
  premium: 'Premium',
  premiumsOnly: 'Premium only',
  blocked: 'Blocked',
  blockedOnly: 'Blocked only',
  blockedUsers: 'Blocked users',
  block: 'Block',
  unblock: 'Unlock',
  centerUsers: 'Center users',
  createdAt: 'Created at',
  lastModifiedAt: 'Last modified at',
  properties: 'Properties',
  PENDING_APPROVAL: 'Pending approval',
  APPROVED: 'Approved',
  REJECTED: 'Rejected',
  NOTIFICATIONS_SENT: 'Sent',
  EXPIRED: 'Expired',
  notifications: 'Notifications',
  myNotifications: 'My notifications',
  notificationRequest: 'Notification request',
  notificationRequests: 'Notification requests',
  body: 'Body',
  allStatuses: 'All statuses',
  approve: 'Approve',
  reject: 'Reject',
  reason: 'Reason',
  active: 'Active',
  sendingTime: 'Sending time',
  notificationSendingTime: 'Notification sending time',
  confirmation: 'Confirmation',
  sureApproveSendingNotification: 'Are you sure you want to approve sending the notification',
  sureRejectSendingNotification: 'Are you sure you want to reject sending the notification',
  sureCancelSendingNotification: 'Are you sure you want to cancel sending the notification',
  immediatelyAfterApproval: 'Immediately after approval',
  rejectionReason: 'Rejection reason',
  sendRequest: 'Send request',
  clickOnNotification: 'Click on notification',
  screenInsideApp: 'Screen inside application',
  appScreen: 'Screen',
  SPORTS_CENTER_DETAILS: 'Center information',
  SPORTS_CENTER_SEARCH: 'Available reservations',
  SPORTS_CENTER_GALLERY: 'Gallery',
  SPORTS_CENTER_NOTIFICATIONS: 'Notifications',
  notificationAppearance: 'Notification appearance',
  sendForReview: 'Send for review',
  atTheExactTime: 'At the exact time',
  clickOnNotificationDescription: 'The goal of sending a notification is to inform the user directly. By clicking on the sent notification, the user can be taken to a specific screen within the application or taken to a specific web page. Below, select the desired type of notification.',
  invalidWebsite: 'Invalid website',
  invalidTime: 'Invalid time',
  fillAllFields: 'Please fill in all fields',
  reservationsGap: 'Reservations gap',
  allowReservationsGap: 'Allow reservations gap',
  recurringStatusActive: 'Active',
  recurringStatusExpired: 'Expired',
  forbid: 'Zabrani',
  sureToSendNotificationToEveryone: 'Are you sure you want to send a notification to all users?',
  maskUsersEmail: 'Mask users email',
  confirmBlock: 'Confirm block',
  confirmUnblock: 'Confirm unblock',
  blockUserReason: 'Reason for blocking',
  unblockUserReason: 'Reason for unblocking',
  enterReason: 'Enter reason',
  blockUntil: 'Block until',
  blockedUntil: 'Blocked until',
  noShow: 'Missed',
  userDidNotAppear: 'User did not appear',
  additionalInfo: 'Additional info',
  maxDaysAheadForReservation: 'Maximum Days in Advance for Reservation',
  anyone: 'Anyone'
}

const hr: { [key: string]: string } = {
  activate: 'Aktiviraj',
  activateCourt: 'Aktiviraj teren',
  addCancellationReason: 'Unesite razlog otkazivanja',
  addCenter: 'Dodaj centar',
  addCourt: 'Dodaj teren',
  addDescription: 'Dodaj opis',
  addedReservations: 'Dodano ?1 rezervacija',
  addNewCenter: 'Dodaj novi centar',
  addOffer: 'Dodaj ponudu',
  addOpeningHours: 'Dodaj radno vrijeme',
  addPremiumUser: 'Dodaj povlaštenog korisnika',
  addRecurringReservation: 'Dodaj ponavljajuću rezervaciju',
  addReservation: 'Dodaj rezervaciju',
  address: 'Adresa',
  addSportsCenterAdmin: 'Dodaj administratora sportskog centra',
  addTimeOff: 'Dodaj vrijeme nedostupnosti',
  ADMINISTRATOR: 'Administrator',
  superAdmins: 'Super-administratori',
  allCities: 'Svi gradovi',
  allCountries: 'Sve države',
  allNeighborhoods: 'Svi kvartovi',
  allSports: 'Svi sportovi',
  APRIL: 'Travanj',
  ARTIFICIAL_GRASS: 'Umjetna trava',
  AUGUST: 'Kolovoz',
  BADMINTON: 'Badminton',
  bar: 'Kafić',
  BASKETBALL: 'Košarka',
  BOWLING: 'Kuglanje',
  CAGEBALL: 'Cageball',
  cancel: 'Otkaži',
  cancellationReason: 'Razlog otkazivanja',
  CARD: 'Kartica',
  cardReservationDeleteWarning: 'Upozorenje!\n Ova rezervacija je prethodno plaćena. Ukoliko želite otkazati navedenu rezervaciju, korisniku morate osigurati zamjenski termin!',
  centers: 'Centri',
  CLAY: 'Zemlja',
  CLIMBING: 'Penjanje',
  close: 'Zatvori',
  CONCRETE: 'Beton',
  confirm: 'Potvrdi',
  confirmDeleting: 'Potvrdi brisanje',
  confirmDeletingOpeningHours: 'Jeste li sigurni da želite obrisati radno vrijeme',
  confirmEditing: 'Potvrdi uređivanje',
  confirmEditingOpeningHours: 'Jeste li sigurni da želite nastaviti s uređivanjem radnog vremena',
  confirmSavingOpeningHours: 'Jeste li sigurni da želite nastaviti sa spremanjem radnog vremena',
  configuration: 'Konfiguracija',
  consoleOnlyAvailableToAdmins: 'Konzola je dostupna samo administratorima',
  couldNotFetchSportsCenterDetails: 'Došlo je do pogreške prilikom dohvaćanja detalja sportskog centra',
  couldNotLogYouIn: 'Prijava neuspješna',
  court: 'Teren',
  courts: 'Tereni',
  allCourts: 'Svi tereni',
  courtType: 'Tip terena',
  coverImage: 'Naslovna slika',
  currency: 'Valuta',
  dateRange: 'Raspon datuma',
  dayOfWeek: 'Dan u tjednu',
  days: 'Dani',
  deactivate: 'Deaktiviraj',
  deactivateCourt: 'Deaktiviraj teren',
  DECEMBER: 'Prosinac',
  delete: 'Obriši',
  deleteImage: 'Obriši sliku',
  deleteCoverImage: 'Obriši naslovnu sliku',
  deleteOffer: 'Obriši ponudu',
  deleteOpeningHours: 'Obriši radno vrijeme',
  deleteRecurringReservation: 'Obriši ponavljajuću rezervaciju',
  deleteReservation: 'Obriši rezervaciju',
  deleteSportsCenter: 'Obriši sportski centar',
  deleteTimeOff: 'Obriši vrijeme nedostupnosti',
  deleteUser: 'Obriši korisnika',
  description: 'Opis',
  dimensions: 'Dimenzije',
  duration: 'Trajanje',
  edit: 'Uredi',
  editCenter: 'Uredi center',
  editCourt: 'Uredi teren',
  editOffer: 'Uredi ponudu',
  editOpeningHours: 'Uredi radno vrijeme',
  editReservation: 'Uredi rezervaciju',
  editTimeOff: 'Uredi vrijeme nedostupnosti',
  email: 'E-pošta',
  end: 'Kraj',
  errorDeletingSportsCenter: 'Došlo je do pogreške prilikom brisanja sportskog centra',
  errorDeletingUser: 'Došlo je do pogreške prilikom brisanja korisnika',
  errorFetchingResults: 'Došlo je do pogreške prilikom dohvata rezultata',
  errorOccurred: 'Došlo je do pogreške',
  everyWeek1: 'Svaki tjedan',
  everyWeek2: 'Svaki drugi tjedan',
  everyWeek3: 'Svaki treći tjedan',
  FEBRUARY: 'Veljača',
  firstName: 'Ime',
  fullName: 'Ime',
  FOOTBALL_TENNIS: 'Nogotenis',
  FRI: 'Pet',
  FRIDAY: 'Petak',
  from: 'Od',
  GOLF: 'Golf',
  goToHomepage: 'Idi na naslovnicu',
  GRASS: 'Trava',
  halfHourSlot: 'Rezervacije na pola sata',
  HANDBALL: 'Rukomet',
  hidden: 'Skriven',
  hours: 'Sati',
  ICE: 'Led',
  id: 'ID',
  image: 'Slika',
  INDOOR: 'Unutarnji',
  info: 'Info',
  JANUARY: 'Siječanj',
  JULY: 'Srpanj',
  JUNE: 'Lipanj',
  lastName: 'Prezime',
  lat: 'Geo. širina',
  latitude: 'Geografska širina',
  legend: 'Legenda',
  length: 'Duljina',
  lng: 'Geo. dužina',
  loading: 'Učitavanje',
  login: 'Prijava',
  logout: 'Odjava',
  longitude: 'Geografska dužina',
  makeSureCorrectCredentials: 'Provjerite jeste li unijeli točne podatke',
  manualEntry: 'Ručni unos',
  MARCH: 'Ožujak',
  maxDistanceMeters: 'Maksimalna udaljenost (u metrima)',
  maxRating: 'Maks. ocjena',
  MAY: 'Svibanj',
  meters: 'metara',
  minRating: 'Min. ocjena',
  minutes: 'minuta',
  MON: 'Pon',
  MONDAY: 'Ponedjeljak',
  multipleSports: 'Višestruki sportovi',
  name: 'Ime',
  newCenter: 'Novi centar',
  noCourtsAvailable: 'Nema dostupnih terena',
  noCoverImage: 'Naslovna slika nije postavljena',
  noHoursAdded: 'Nema dodanih sati',
  noOffersAvailable: 'Nema dostupnih ponuda',
  noOpeningHours: 'Nema dostupnih radnih vremena',
  noPremiumUsers: 'Nema povlaštenih korisnika',
  noRecurringReservationsAvailable: 'Nema dostupnih ponavljajućih rezervacija',
  noReservationsAvailable: 'Nema dostupnih rezervacija',
  noResults: 'Nema rezultata',
  noTimesOffAvailable: 'Nema vremena nedostupnosti',
  NOVEMBER: 'Studeni',
  number: 'Broj',
  OCTOBER: 'Listopad',
  offers: 'Ponude',
  ON_SITE: 'Gotovina',
  openingHours: 'Radna vremena',
  operationSuccessful: 'Akcija uspješno izvršena',
  OUTDOOR: 'Vanjski',
  PADEL: 'Padel',
  parking: 'Parking',
  password: 'Lozinka',
  paymentMethod: 'Način plaćanja',
  paymentSuccessful: 'Plaćeno',
  period: 'Period',
  person: 'Osoba',
  personOrEmail: 'Osoba/E-pošta',
  phoneNumber: 'Broj telefona',
  premiumUsers: 'Povlašteni korisnici',
  price: 'Cijena',
  pricePerHour: 'Cijena (po satu)',
  proceed: 'Nastavi',
  proceedAddingTimeOff: 'Jeste li sigurni da želite nastaviti s dodavanjem vremena nedostupnosti',
  QUARTZ_SAND: 'Kvarcni pjesak',
  query: 'Pojam',
  range: 'Raspon',
  rating: 'Ocjena',
  recurringReservation: 'Ponavljajuća rezervacija',
  recurringReservations: 'Ponavljajuće rezervacije',
  refresh: 'Osvježi',
  repetition: 'Ponavljanje',
  reservation: 'Rezervacija',
  reservationDetails: 'Detalji rezervacije',
  reservationCardPaid: 'Rezervacija plaćena karticom preko mobilne aplikacije',
  reservationColors: 'Boja rezervacija',
  reservationEmailNotifications: 'Email obavijesti o rezervacijama',
  reservationPaid: 'Rezervacija plaćena u sportskom centru',
  reservationPhoneNumberRequired: 'Obavezan broj telefona za rezervaciju',
  reservations: 'Rezervacije',
  reservationsAlreadyScheduled: 'Već postoje rezervacije u tom vremenskom intervalu',
  reservationsAlreadyScheduledDelete: 'Već postoje rezervacije u tom vremenskom intervalu koje će biti obrisane',
  reservationsOutsideOfOpeningHours: 'Postoje rezervacije koje će biti izvan radnog vremena',
  reservationTime: 'Vrijeme rezervacije',
  reserved: 'Rezervirano',
  reservedUsingMobile: 'Rezervirano putem mobilne aplikacije',
  role: 'Uloga',
  RUBBER: 'Guma',
  SAND: 'Pijesak',
  SAT: 'Sub',
  SATURDAY: 'Subota',
  save: 'Spremi',
  saveSorting: 'Spremi sortiranje',
  screen: 'Zaslon',
  search: 'Pretraga',
  seeMoreResults: 'Učitaj još rezultata',
  selectCity: 'Odaberi grad',
  selectCountry: 'Odaberi državu',
  selectCourt: 'Odaberi teren',
  selectDaysOfWeek: 'Odaberi dane u tjednu',
  selectDuration: 'Odaberi trajanje',
  selectNeighborhood: 'Odaberi kvart',
  selectSport: 'Odaberi sport',
  sendNotification: 'Pošalji notifikaciju',
  SEPTEMBER: 'Rujan',
  SOCCER: 'Nogomet',
  sport: 'Sport',
  sportsCenterOwners: 'Vlasnici sportskih centara',
  sports: 'Sportovi',
  sportsCenter: 'Sportski centar',
  SPORTS_CENTER: 'Vlasnik sportskog centra',
  sportsCenterSuccessfullyDeleted: 'Sportski centar uspješno obrisan',
  SQUASH: 'Skvoš',
  start: 'Početak',
  stripeAccountId: 'Stripe Account ID',
  SUN: 'Ned',
  SUNDAY: 'Nedjelja',
  sureDeletingUser: 'Jeste li sigurni da želite obrisati korisnika',
  sureToDelete: 'Jeste li sigurni da želite obrisati',
  sureToDeleteConfirm: 'Nakon ovog koraka centar će biti nepovratno obrisan! Želite li nastaviti?',
  sureToDeleteOffer: 'Jeste li sigurni da želite obrisati ponudu',
  sureToDeletePremiumUser: 'Jeste li sigurni da želite obrisati povlaštenog korinsika',
  sureToDeleteRecurringReservation: 'Jeste li sigurni da želite obrisati ponavljajuću rezervaciju',
  sureToDeleteReservation: 'Jeste li sigurni da želite obrisati rezervaciju',
  sureToDeleteTimeOff: 'Jeste li sigurni da želite obrisati vrijeme nedostupnosti',
  surfaceType: 'Podloga',
  TABLE_TENNIS: 'Stolni tenis',
  TATAMI: 'Tatami',
  TENNIS: 'Tenis',
  text: 'Tekst',
  thatsAllResults: 'To su svi rezultati',
  THU: 'Čet',
  THURSDAY: 'Četvrtak',
  time: 'Vrijeme',
  timeOff: 'Vrijeme nedostupnosti',
  timesOff: 'Vremena nedostupnosti',
  timeZone: 'Vremenska zona',
  selectTimeZone: 'Odaberi vremensku zonu',
  title: 'Naslov',
  to: 'Do',
  today: 'Danas',
  type: 'Tip',
  TUE: 'Uto',
  TUESDAY: 'Utorak',
  unexpectedError: 'Došlo je do pogreške',
  unsupportedFormat: 'Nepodržani format',
  uploadImage: 'Učitaj sliku',
  uploadCoverImage: 'Učitaj naslovnu sliku',
  USER: 'Korisnik',
  users: 'Korisnici',
  userSuccessfullyDeleted: 'Korisnik uspješno obrisan',
  visible: 'Vidljiv',
  VOLLEYBALL: 'Odbojka',
  website: 'Web stranica',
  WED: 'Sri',
  WEDNESDAY: 'Srijeda',
  width: 'Širina',
  WOOD: 'Parket',
  reservationsFromAdmin: 'Rezervacije kreirane od strane administratora',
  reservationsFromApp: 'Rezervacije ostvarene putem mobilne aplikacije',
  backgroundColor: 'Boja pozadine',
  textColor: 'Boja teksta',
  preview: 'Pregled',
  enableCustomColor: 'Omogući prilagođene boje',
  gallery: 'Galerija',
  enableGallery: 'Ukljući galeriju',
  centerInformation: 'Informacije centra',
  reservationCancellation: 'Otkazivanje rezervacije',
  timeBeforeStart: 'Vrijeme prije početka termina (h)',
  enabled: 'Omogućeno',
  searchReservations: 'Pretraga rezervacija',
  select: 'Odaberi',
  resFilterAll: 'Sve',
  resFilterAllReservation: 'Sve rezervacije',
  resFilterCanceled: 'Otkazane',
  resFilterSuccessful: 'Ostvarene',
  resFilterSuccessfulAndCanceled: 'Ostvarene i otkazane',
  resFilterAllPaymentMethods: 'Svi načini plaćanja',
  resFilterCash: 'Gotovina',
  resFilterCard: 'Kartica',
  resFilterRecurring: 'Ponavljajuće rezervacije',
  resFilterFromAdmin: 'Uneseno od strane admina',
  resFilterFromMobile: 'Rezervirano putem mobilne aplikacije',
  labelReservationType: 'Tip rezervacije',
  labelReservationStatus: 'Status rezervacije',
  labelTimePeriod: 'Vremenski period',
  allSportsCenter: 'Svi sportski centri',
  reservationCreatedAt: 'Vrijeme ostvarivanja rezervacije',
  cancelledAt: 'Vrijeme otkazivanja rezervacije',
  cancelledBy: 'Otkazao',
  status: 'Status',
  RESERVED: 'Rezervirano',
  COMPLETED: 'Dovršeno',
  CANCELED: 'Otkazano',
  yes: 'Da',
  no: 'Ne',
  noData: 'Nema podataka',
  menu: 'Izbornik',
  aboutCenter: 'O centru',
  general: 'Općenito',
  workingTime: 'Radno vrijeme',
  table: 'Tablica',
  colorSettings: 'Postavke boja',
  sportsCourts: 'Sportski tereni',
  superAdmin: 'Superadministrator',
  view: 'Pogledaj',
  statistics: 'Statistika',
  cache: 'Predmemorija',
  clear: 'Očisti',
  clearCache: 'Očisti predmemoriju',
  hits: 'Pogotci',
  misses: 'Promašaji',
  hitPercentage: 'Postotak pogodaka',
  missPercentage: 'Postotak promašaja',
  gets: 'Pozivi',
  averageGetTime: 'Prosječno vrijeme poziva',
  premium: 'Povlašten',
  premiumsOnly: 'Samo povlašteni',
  blocked: 'Blokiran',
  blockedOnly: 'Samo blokirani',
  blockedUsers: 'Blokirani korisnik',
  block: 'Blokiraj',
  unblock: 'Odblokiraj',
  centerUsers: 'Korisnici centra',
  createdAt: 'Vrijeme kreiranja',
  lastModifiedAt: 'Vrijeme zadnje izmjene',
  properties: 'Atributi',
  PENDING_APPROVAL: 'Čeka se odobrenje',
  APPROVED: 'Odobreno',
  REJECTED: 'Odbijeno',
  NOTIFICATIONS_SENT: 'Poslano',
  EXPIRED: 'Isteklo',
  notifications: 'Notifikacije',
  myNotifications: 'Moje notifikacije',
  notificationRequest: 'Zahtjev za notifikaciju',
  notificationRequests: 'Zahtjevi za notifikaciju',
  body: 'Tijelo',
  allStatuses: 'Svi statusi',
  approve: 'Odobri',
  reject: 'Odbij',
  reason: 'Razlog',
  active: 'Aktivno',
  sendingTime: 'Vrijeme slanja',
  notificationSendingTime: 'Vrijeme slanja notifikacije',
  confirmation: 'Potvrda',
  sureApproveSendingNotification: 'Jeste li sigurni da želite odobriti slanje notifikacije',
  sureRejectSendingNotification: 'Jeste li sigurni da želite odbiti slanje notifikacije',
  sureCancelSendingNotification: 'Jeste li sigurni da želite otkazati slanje notifikacije',
  immediatelyAfterApproval: 'Odmah nakon odobrenja',
  rejectionReason: 'Razlog odbijanja',
  sendRequest: 'Pošalji zahtjev',
  clickOnNotification: 'Klik na notifikaciju',
  screenInsideApp: 'Ekran unutar aplikacije',
  appScreen: 'Ekran',
  SPORTS_CENTER_DETAILS: 'Informacije o centru',
  SPORTS_CENTER_SEARCH: 'Dostupne rezervacije',
  SPORTS_CENTER_GALLERY: 'Galerija',
  SPORTS_CENTER_NOTIFICATIONS: 'Notifikacije',
  notificationAppearance: 'Izgled notifikacije',
  sendForReview: 'Pošalji na pregled',
  atTheExactTime: 'U točno određeno vrijeme',
  clickOnNotificationDescription: 'Cilj slanja notifikacije je direktno obavještavanje korisnika. Pritiskom na poslanu notifikaciju korisnika je moguće odvesti na određeni ekran unutar aplikacije ili ga odvesti na određenu web stranicu. U nastavku odaberi željeni tip notifikacije.',
  invalidWebsite: 'Neispravna web stranica',
  invalidTime: 'Neispravno vrijeme',
  fillAllFields: 'Potrebno je ispuniti sva polja.',
  reservationsGap: 'Praznina u rezervacijama',
  allowReservationsGap: 'Dozvoli praznine',
  recurringStatusActive: 'Aktivne',
  recurringStatusExpired: 'Istekle',
  forbid: 'Zabrani',
  sureToSendNotificationToEveryone: 'Jeste li sigurni da želite poslati notifikaciju svim korisnicima?',
  maskUsersEmail: 'Maskiraj email korisnika',
  confirmBlock: 'Potvrdi blokiranje',
  confirmUnblock: 'Potvrdi odblokiranje',
  blockUserReason: 'Razlog blokiranja',
  unblockUserReason: 'Razlog odblokiranja',
  enterReason: 'Unesi razlog',
  blockUntil: 'Blokiraj do',
  blockedUntil: 'Blokiran do',
  noShow: 'Propušteno',
  userDidNotAppear: 'Korisnik se nije pojavio',
  additionalInfo: 'Dodatne informacije',
  maxDaysAheadForReservation: 'Najveći broj dana unaprijed za rezervaciju',
  anyone: 'Bilo tko'
}

const translate = (key: string) => {
  const lang = localStorage.getItem('language') || 'HR';
  return (lang.startsWith('HR') ? hr : en)[key] || `???${key}???`;
}

export default translate;