import React from 'react';
import {Badge, Modal, Table} from 'react-bootstrap';
import translate from '../../../helpers/translations';
import {sportIcons} from "../../../helpers/sportIcons";
import {formatDateRangeLong, formatDateValueLong} from "../../../helpers/date";
import Iphone from "../../../components/Icons/Iphone";
import Repeat from "../../../components/Icons/Repeat";
import Person from "../../../components/Icons/Person";
import { FaCcVisa, FaCcMastercard, FaCcDiscover, FaCcAmex, FaCcDinersClub, FaCcJcb, FaGooglePay, FaApplePay } from 'react-icons/fa';


interface Props {
  reservation: Reservation;
  showSportsCenter: boolean;
  onHide: () => any;
}

const getUserString = (reservation: Reservation) => {
  let userString = '-';
  if (reservation.user && (reservation.user.firstName || reservation.user.lastName)) {
    userString = `${reservation.user.firstName ? reservation.user.firstName : ''} ${reservation.user.lastName ? reservation.user.lastName : ''}`;
  } else if (reservation.fullName) {
    userString = `${reservation.fullName}`;
  }
  return userString;
};

const getStatusBadge = (reservation: Reservation) => {
  switch (reservation.status) {
    case "RESERVED":
      return <Badge bg='success'>{translate('RESERVED')}</Badge>
    case "COMPLETED":
      return <Badge bg='success'>{translate('COMPLETED')}</Badge>
    case "CANCELED":
      return <Badge bg='danger'>{translate('CANCELED')}</Badge>
    case "NO_SHOW":
      return <Badge bg='danger'>{translate('noShow')}</Badge>
  }
}

const getPaymentMethodDetails = (cardPayment: CardPayment) => {
  if(!cardPayment.lastFour && !cardPayment.brand && !cardPayment.walletType) return undefined
  return <>&nbsp;
    (&nbsp;
    {cardPayment.lastFour && '****' + cardPayment.lastFour}&nbsp;
    {cardPayment.brand && getBrandIcon(cardPayment.brand)}&nbsp;
    {cardPayment.walletType && getWalletTypeIcon(cardPayment.walletType)}
    &nbsp;)
  </>
}

const getBrandIcon = (brand: string)=> {
  switch (brand) {
    case 'visa': return <FaCcVisa/>
    case 'mastercard': return <FaCcMastercard/>
    case 'amex': return <FaCcAmex/>
    case 'discover': return <FaCcDiscover/>
    case 'diners': return <FaCcDinersClub/>
    case 'jcb': return <FaCcJcb/>
    default: return ''
  }
}

const getWalletTypeIcon = (walletType: string)=> {
  switch (walletType) {
    case 'apple_pay': return <FaApplePay/>
    case 'google_pay': return <FaGooglePay/>
    default: return ''
  }
}

const ReservationDetailsModal: React.FC<Props> = ({showSportsCenter, reservation, onHide}) => {
  return <Modal show={true} onHide={onHide} size='lg'>
    <Modal.Header closeButton><strong>{translate('reservationDetails')}</strong></Modal.Header>
    <Modal.Body className='p-4'>
      <Table style={{verticalAlign: 'middle'}}>
        <tbody>
        <tr>
          <th style={{width: '30%', paddingRight: '10px'}}>{translate('status')}</th>
          <td style={{width: '70%'}}>
            {getStatusBadge(reservation)}
          </td>
        </tr>
        <tr>
          <th style={{width: '30%', paddingRight: '10px'}}>{translate('sport')}</th>
          <td style={{width: '70%'}}>
            <img height={20} width={20}
                 src={sportIcons[reservation.sport.toLowerCase().replaceAll('-', '_') as keyof typeof sportIcons]}
                 alt={reservation.sport} style={{margin: '1px'}}
            />&nbsp;&nbsp;{translate(reservation.sport)}
          </td>
        </tr>
        <tr>
          <th style={{width: '30%', paddingRight: '10px'}}>{translate('USER')}</th>
          <td style={{width: '70%'}}>{getUserString(reservation)}</td>
        </tr>
        <tr>
          <th style={{width: '30%', paddingRight: '10px'}}>{translate('email')}</th>
          <td style={{width: '70%'}}>{reservation.user && reservation.user.email ? reservation.user.email : (reservation.email ? reservation.email : '-')}</td>
        </tr>
        <tr>
          <th style={{width: '30%', paddingRight: '10px'}}>{translate('phoneNumber')}</th>
          <td style={{width: '70%'}}>{reservation.user && reservation.user.phoneNumber ? reservation.user.phoneNumber : (reservation.phoneNumber ? reservation.phoneNumber : '-')}</td>
        </tr>
        <tr>
          <th style={{width: '30%', paddingRight: '10px'}}>{translate('court')}</th>
          <td style={{width: '70%'}}>{reservation.court.name}</td>
        </tr>
        {showSportsCenter &&
            <tr>
              <th style={{width: '30%', paddingRight: '10px'}}>{translate('sportsCenter')}</th>
              <td style={{width: '70%'}}>{reservation.sportsCenter.name}</td>
            </tr>
        }
        <tr>
          <th style={{width: '30%', paddingRight: '10px'}}>{translate('time')}</th>
          <td style={{width: '70%'}}>{formatDateRangeLong(reservation.fromDateTime, reservation.toDateTime)}</td>
        </tr>
        <tr>
          <th style={{width: '30%', paddingRight: '10px'}}>{translate('price')}</th>
          <td style={{width: '70%'}}>{reservation.price} {reservation.currency}</td>
        </tr>
        <tr>
          <th style={{width: '30%', paddingRight: '10px'}}>{translate('labelReservationType')}</th>
          <td>
            {reservation.type === "MOBILE" ?
                <Iphone style={{marginRight: '3px', verticalAlign: 'text-bottom'}}/> : null}
            {reservation.type === "RECURRING" ?
                <Repeat style={{marginRight: '3px', verticalAlign: 'text-bottom'}}/> : null}
            {reservation.type === "SPORTS_CENTER" ?
                <Person style={{marginRight: '3px', verticalAlign: 'text-bottom'}}/> : null}
          </td>
        </tr>
        <tr>
          <th style={{width: '30%', paddingRight: '10px'}}>{translate('paymentMethod')}</th>
          <td style={{width: '70%'}}>
            {translate(reservation.paymentMethod)}&nbsp;
            {reservation.cardPayment && getPaymentMethodDetails(reservation.cardPayment)}
          </td>
        </tr>
        <tr>
          <th style={{width: '30%', paddingRight: '10px'}}>{translate('paymentSuccessful')}</th>
          <td style={{width: '70%'}}>{translate(reservation.isPaid ? 'yes' : 'no')}</td>
        </tr>
        <tr>
          <th style={{width: '30%', paddingRight: '10px'}}>{translate('reservationCreatedAt')}</th>
          <td style={{width: '70%'}}>{formatDateValueLong(reservation.createdAt)}</td>
        </tr>
        {reservation.canceledAt &&
            <tr>
              <th style={{width: '30%', paddingRight: '10px'}}>{translate('cancelledAt')}</th>
              <td style={{width: '70%'}}>{formatDateValueLong(reservation.canceledAt)}</td>
            </tr>
        }
        {reservation.canceledByRole &&
            <tr>
              <th style={{width: '30%', paddingRight: '10px'}}>{translate('cancelledBy')}</th>
              <td style={{width: '70%'}}>{translate(reservation.canceledByRole)}</td>
            </tr>
        }
        {reservation.cancelReason && reservation.cancelReason.trim() &&
            <tr>
              <th style={{width: '30%', paddingRight: '10px'}}>{translate('cancellationReason')}</th>
              <td style={{ width: '70%', whiteSpace: 'pre-wrap' }}>
                {reservation.cancelReason}
              </td>
            </tr>
        }
        </tbody>
      </Table>
    </Modal.Body>
  </Modal>
}

export default ReservationDetailsModal;