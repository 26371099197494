import axios from 'axios';

const api = axios.create({
  baseURL: window.location.origin
});

api.interceptors.request.use(request => {
  return {
    ...request,
    headers: {
      ...(request.headers || {}),
      'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
      'Accept-Language': localStorage.getItem('language') || 'HR'
    }
  }
});

api.interceptors.response.use(response => response, async (error) => {
  if (axios.isAxiosError(error) && error.response?.status === 401) {
    return (async () => {
      const response = await refresh();
      if (response.status === 200) {
        const { accessToken } = response.data;
        localStorage.setItem('accessToken', accessToken);
        if (error.config.headers?.Authorization) {
          error.config.headers.Authorization = `Bearer ${accessToken}`;
          return api(error.config);
        }
      }
      throw error;
    })();
  }
  throw error;
});


// sports center
export const getSportsCenterOld = (id: string | number) => api.get<SportsCenterOld>(`/api/sports-center/${id}?offers=true`)

export const createOffer = (sportsCenterId: number, data: any) => api.post(`/api/sports-center/${sportsCenterId}/offer`, data);
export const updateOffer = (sportsCenterId: number, offerId: number, data: any) => api.put(`/api/sports-center/${sportsCenterId}/offer/${offerId}`, data);

export const sendNotification = (data: any) => api.post<number>(`api/fcm/send-notification`, data);

const getSportsCenterUsersQueryString = (filter?: SportsCenterUserFilter) => {
  let query = ''
  if(filter?.user) query += '&user=' + filter.user
  if(filter?.isBlocked != undefined) query += '&isBlocked=' + filter.isBlocked
  if(filter?.isPremium != undefined) query += '&isPremium=' + filter.isPremium
  return query;
}

const getSportsCenterNotificationRequestsQueryString = (filter?: SportsCenterNotificationRequestsFilter) => {
  let query = ''

  if(filter?.status) query += '&status=' + filter.status
  if(filter?.sportsCenterId) query += '&sportsCenterId=' + filter.sportsCenterId

  return query;
}

const getRecurringReservationQueryString = (filter?: RecurringReservationFilter) => {
  let query = ''

  if(filter?.user) query += '&user=' + filter.user
  if(filter?.active != undefined) query += '&active=' + filter.active
  if(filter?.sport) query += '&sport=' + filter.sport

  return query;
}

const getReservationQueryString = (filter?: ReservationFilter): string => {
  let query = '';

  if (filter?.sportsCenterId) query += `&sportsCenterId=${filter.sportsCenterId}`;
  if (filter?.courtId) query += `&courtId=${filter.courtId}`;
  if (filter?.userId) query += `&userId=${filter.userId}`;
  if (filter?.createdByUserId) query += `&createdByUserId=${filter.createdByUserId}`;
  if (filter?.user) query += `&user=${encodeURIComponent(filter.user)}`;
  if (filter?.fromDateTime) query += `&fromDateTime=${encodeURIComponent(filter.fromDateTime)}`;
  if (filter?.toDateTime) query += `&toDateTime=${encodeURIComponent(filter.toDateTime)}`;
  if (filter?.sport) query += `&sport=${encodeURIComponent(filter.sport)}`;
  if (filter?.paymentMethod) query += `&paymentMethod=${encodeURIComponent(filter.paymentMethod)}`;
  if (filter?.status) query += `&status=${encodeURIComponent(filter.status)}`;
  if (filter?.type) query += `&type=${encodeURIComponent(filter.type)}`;
  if (filter?.dayOfWeek) query += `&dayOfWeek=${encodeURIComponent(filter.dayOfWeek)}`;
  if (filter?.fromTime) query += `&fromTime=${encodeURIComponent(filter.fromTime)}`;
  if (filter?.toTime) query += `&toTime=${encodeURIComponent(filter.toTime)}`;
  if (filter?.canceledByRole) query += `&canceledByRole=${encodeURIComponent(filter.canceledByRole)}`;
  if (filter?.sortBy) query += `&sortBy=${encodeURIComponent(filter.sortBy)}`;

  return query;
};

const getSportsCenterQueryString = (filter?: SportsCenterFilter): string => {
  let query = '';

  if (filter?.search) query += `&search=${encodeURIComponent(filter.search)}`;
  if (filter?.visibleOnly) query += `&visibleOnly=${filter.visibleOnly}`;
  if (filter?.name) query += `&name=${encodeURIComponent(filter.name)}`;
  if (filter?.address) query += `&address=${encodeURIComponent(filter.address)}`;
  if (filter?.countryId) query += `&countryId=${encodeURIComponent(filter.countryId)}`;
  if (filter?.cityId) query += `&cityId=${encodeURIComponent(filter.cityId)}`;
  if (filter?.neighborhoodId) query += `&neighborhoodId=${encodeURIComponent(filter.neighborhoodId)}`;
  if (filter?.sport) query += `&sport=${encodeURIComponent(filter.sport)}`;
  if (filter?.parking) query += `&parking=${filter.parking}`;
  if (filter?.bar) query += `&bar=${filter.bar}`;
  if (filter?.minRating) query += `&minRating=${filter.minRating}`;
  if (filter?.maxRating) query += `&maxRating=${filter.maxRating}`;
  if (filter?.latitude) query += `&latitude=${filter.latitude}`;
  if (filter?.longitude) query += `&longitude=${filter.longitude}`;
  if (filter?.maxDistanceMeters) query += `&maxDistanceMeters=${filter.maxDistanceMeters}`;
  if (filter?.favoritesOnly) query += `&favoritesOnly=${filter.favoritesOnly}`;
  if (filter?.userId) query += `&userId=${filter.userId}`;
  if (filter?.paymentMethod) query += `&paymentMethod=${encodeURIComponent(filter.paymentMethod)}`;
  if (filter?.halfHourSlotSupported) query += `&halfHourSlotSupported=${filter.halfHourSlotSupported}`;
  if (filter?.dayOfWeek) query += `&dayOfWeek=${encodeURIComponent(filter.dayOfWeek)}`;
  if (filter?.sortBy) query += `&sortBy=${encodeURIComponent(filter.sortBy)}`;

  return query;
};


// auth
export const login = (email: string, password: string) => api.post<Tokens>(`/auth/login`, { email, password });
export const refresh = () => api.get<AccessToken>(`/auth/refresh?refreshToken=${localStorage.getItem('refreshToken')}`);

// person
export const getProfile = () => api.get<Person>(`/api/person/me`);
export const getPersons = (query: string, role: string | null, pageNumber: number, pageSize: number) => api.get<Person[]>(`/api/person`, { params: { query, role, pageNumber, pageSize } });
export const createSportsCenterAdministrator = (data: any) => api.post('/api/person/', data);
export const deletePerson = (id: number) => api.delete(`/api/person/${id}`);
export const blockPerson = (id: number, data: BlockUserForm) => api.post(`/api/person/${id}/block`, data);
export const unblockPerson = (id: number, data: BlockUserForm) => api.post(`/api/person/${id}/unblock`, data);

export const getSportsCentersAdmin = (page: number, size: number, filter?: SportsCenterFilter) => api.get<SportsCenter[]>(`/api/web/admin/sports-center?page=${page}&size=${size}${getSportsCenterQueryString(filter)}`);
export const getSportsCentersLabelsAdmin = () => api.get<SportsCenterLabel[]>('/api/web/admin/sports-center/labels');


export const deleteCourt = (sportsCenterId: number, courtId: number) => api.delete(`/api/web/admin/sports-center/${sportsCenterId}/court/${courtId}`);
export const createCourt = (sportsCenterId: number, data: any) => api.post(`/api/web/admin/sports-center/${sportsCenterId}/court`, data);
export const updateCourt = (sportsCenterId: number, courtId: number, data: any) => api.put(`/api/web/sports-center/${sportsCenterId}/court/${courtId}`, data);

export const getReservations = (sportsCenterId: number, page: number, size: number, filter?: ReservationFilter)=> api.get<Reservation[]>(`/api/web/sports-center/${sportsCenterId}/reservation?page=${page}&size=${size}${getReservationQueryString(filter)}`);
export const getReservationsAdmin = (page: number, size: number, filter?: ReservationFilter)=> api.get<Reservation[]>(`/api/web/admin/reservation?page=${page}&size=${size}${getReservationQueryString(filter)}`);
export const getReservationsByDate = (sportsCenterId: number, date: string) => api.get<Reservation[]>(`/api/web/sports-center/${sportsCenterId}/reservation/date/${date}`);
export const getReservationsOutsideOpeningHours = (sportsCenterId: number, openingHours: OpeningHours[]) => api.post<Reservation[]>(`/api/web/sports-center/${sportsCenterId}/reservation/outside-opening-hours`, openingHours);
export const deleteReservation = (sportsCenterId: number, id: number, reason: string) => api.delete(`api/web/sports-center/${sportsCenterId}/reservation/${id}?reason=${encodeURIComponent(reason)}`);
export const createReservation = (sportsCenterId: number, data: ReservationForm) => api.post(`api/web/sports-center/${sportsCenterId}/reservation`, data);
export const updateReservation = (sportsCenterId: number, data: ReservationUpdateForm) => api.put(`api/web/sports-center/${sportsCenterId}/reservation`, data);
export const updateReservationNoShow = (sportsCenterId: number, reservationId: number, noShow: boolean) => api.put(`api/web/sports-center/${sportsCenterId}/reservation/${reservationId}/no-show?noShow=${noShow}`);


export const getOpeningHours = (sportsCenterId: number) => api.get<OpeningHours[]>(`/api/web/sports-center/${sportsCenterId}/opening-hours`);
export const createOpeningHours = (sportsCenterId: number, data: OpeningHours[]) => api.post<OpeningHours[]>(`/api/web/sports-center/${sportsCenterId}/opening-hours`, data);

export const getSportsCenterNotificationRequests = (sportsCenterId: number, page: number, size: number, status?: string) => api.get<SportsCenterNotificationRequests[]>(`/api/web/sports-center/${sportsCenterId}/notification-request?page=${page}&size=${size}${status ? '&status=' + status : ''}`);
export const getAllSportsCenterNotificationRequestsAdmin = (page: number, size: number, filter?: SportsCenterNotificationRequestsFilter) => api.get<SportsCenterNotificationRequests[]>(`/api/web/admin/sports-center-notification-request?page=${page}&size=${size}${getSportsCenterNotificationRequestsQueryString(filter)}`);
export const createSportsCenterNotificationRequest = (sportsCenterId: number, data: any) => api.post(`/api/web/sports-center/${sportsCenterId}/notification-request`, data);
export const updateNotificationRequestStatus = (sportsCenterId: number, requestId: number, status: string, reason?: string) => api.put(`/api/web/sports-center/${sportsCenterId}/notification-request/${requestId}/status/${status}${reason ? '?reason=' + reason : ''}`);

export const getSportsCenterUsers = (sportsCenterId: number, page: number, size: number, filter?: SportsCenterUserFilter) => api.get<SportsCenterUser[]>(`/api/web/sports-center/${sportsCenterId}/users?page=${page}&size=${size}${getSportsCenterUsersQueryString(filter)}`);
export const setUserAsPremium = (sportsCenterId: number, userId: number) => api.put<void>(`/api/web/sports-center/${sportsCenterId}/premium-users/user/${userId}`);
export const removeUserAsPremium = (sportsCenterId: number, userId: number) => api.delete<void>(`/api/web/sports-center/${sportsCenterId}/premium-users/user/${userId}`);
export const blockUser = (sportsCenterId: number, userId: number) => api.put<void>(`/api/web/sports-center/${sportsCenterId}/blocked-users/user/${userId}`);
export const unBlockUser = (sportsCenterId: number, userId: number) => api.delete<void>(`/api/web/sports-center/${sportsCenterId}/blocked-users/user/${userId}`);

export const getTimeOffs = (sportsCenterId: number) => api.get<TimeOff[]>(`/api/web/sports-center/${sportsCenterId}/time-off`);
export const createTimeOff = (sportsCenterId: number, data: any) => api.post<TimeOff>(`/api/web/sports-center/${sportsCenterId}/time-off`, data);
export const updateTimeOff = (sportsCenterId: number, timeOffId: number, data: any) => api.put<TimeOff>(`/api/web/sports-center/${sportsCenterId}/time-off/${timeOffId}`, data);
export const deleteTimeOff = (sportsCenterId: number, timeOffId: number) => api.delete(`/api/web/sports-center/${sportsCenterId}/time-off/${timeOffId}`);

export const getReservationCancellationDeadline = (sportsCenterId: number) => api.get<ReservationCancellationDeadline[]>(`/api/web/admin/sports-center/${sportsCenterId}/sport-reservation-configuration/reservation-cancellation-deadline`);
export const updateReservationCancellationDeadline = (sportsCenterId: number, data: ReservationCancellationDeadline[]) => api.post<ReservationCancellationDeadline[]>(`/api/web/admin/sports-center/${sportsCenterId}/sport-reservation-configuration/reservation-cancellation-deadline`, data);

export const getHalfHourSports = (sportsCenterId: number) => api.get<HalfHourSport[]>(`/api/web/admin/sports-center/${sportsCenterId}/sport-reservation-configuration/half-hour-reservation-days`);
export const updateHalfHourSports = (sportsCenterId: number, data: HalfHourSport[]) => api.post<HalfHourSport[]>(`/api/web/admin/sports-center/${sportsCenterId}/sport-reservation-configuration/half-hour-reservation-days`, data);

export const getPaymentMethods = (sportsCenterId: number) => api.get<AllowedPaymentMethods[]>(`/api/web/sports-center/${sportsCenterId}/sport-reservation-configuration/allowed-payment-methods`);
export const updatePaymentMethods = (sportsCenterId: number, data: AllowedPaymentMethods[]) => api.post<AllowedPaymentMethods[]>(`/api/web/sports-center/${sportsCenterId}/sport-reservation-configuration/allowed-payment-methods`, data);

export const getMaxDaysAheadForReservations = (sportsCenterId: number) => api.get<MaxDaysAheadForReservation[]>(`/api/web/admin/sports-center/${sportsCenterId}/sport-reservation-configuration/max-days-ahead-for-reservation`);
export const updateMaxDaysAheadForReservations = (sportsCenterId: number, data: MaxDaysAheadForReservation[]) => api.post<AllowedPaymentMethods[]>(`/api/web/admin/sports-center/${sportsCenterId}/sport-reservation-configuration/max-days-ahead-for-reservation`, data);

export const getReservationGapRestrictions = (sportsCenterId: number) => api.get<ReservationGapRestriction[]>(`/api/web/admin/sports-center/${sportsCenterId}/sport-reservation-configuration/reservation-gap-restriction`);
export const updateReservationGapRestrictions = (sportsCenterId: number, data: ReservationGapRestriction[]) => api.post<AllowedPaymentMethods[]>(`/api/web/admin/sports-center/${sportsCenterId}/sport-reservation-configuration/reservation-gap-restriction`, data);

export const getRecurringReservations = (sportsCenterId: number, page: number, size: number, filter?: RecurringReservationFilter) => api.get<RecurringReservation[]>(`/api/web/sports-center/${sportsCenterId}/recurring-reservations?page=${page}&size=${size}${getRecurringReservationQueryString(filter)}`);
export const createRecurringReservation = (sportsCenterId: number, data: RecurringReservationForm) => api.post<number>(`api/web/sports-center/${sportsCenterId}/recurring-reservations`, data);
export const updateRecurringReservation = (sportsCenterId: number, id: number, data: RecurringReservationForm) => api.put<number>(`api/web/sports-center/${sportsCenterId}/recurring-reservations/${id}`, data);
export const deleteRecurringReservations = (sportsCenterId: number, id: number) => api.delete(`api/web/sports-center/${sportsCenterId}/recurring-reservations/${id}`);

export const uploadCoverImage = (sportsCenterId: number, data: any) => api.post(`/api/web/admin/sports-center/${sportsCenterId}/cover-image`, data);
export const deleteCoverImage = (sportsCenterId: number) => api.delete(`/api/web/admin/sports-center/${sportsCenterId}/cover-image`);

export const getSportsCenterGallery = (sportsCenterId: number) => api.get<SportsCenterGallery[]>(`/api/web/admin/sports-center/${sportsCenterId}/gallery`);
export const createSportsCenterGallery = (sportsCenterId: number, data: any) => api.post(`/api/web/admin/sports-center/${sportsCenterId}/gallery`, data);
export const deleteSportsCenterGallery = (id: string, sportsCenterId: number) => api.delete(`/api/web/admin/sports-center/${sportsCenterId}/gallery/${id}`);
export const updateSportsCenterGallerySortOrder = (sportsCenterId: number, data: any) => api.put(`/api/web/admin/sports-center/${sportsCenterId}/gallery/sort-order`, data);

export const getReservationColors = (sportsCenterId: number) => api.get<IReservationColors>(`api/web/sports-center/${sportsCenterId}/reservation-colors`);
export const saveReservationColors = (sportsCenterId: number, data: IReservationColors) => api.post<IReservationColors>(`api/web/sports-center/${sportsCenterId}/reservation-colors`, data);

export const getOffers = (sportsCenterId: number) => api.get<Offer[]>(`/api/web/sports-center/${sportsCenterId}/offers`);
export const deleteOffer = (sportsCenterId: number, offerId: number) => api.delete(`/api/web/sports-center/${sportsCenterId}/offers/${offerId}`);

export const getSportsCenter = (id: string | number) => api.get<SportsCenter>(`/api/web/sports-center/${id}`)
export const updateSportsCenter = (id: number, data: UpdateSportsCenterRequest) => api.put(`/api/web/sports-center/${id}`, data);
export const createSportsCenterAdmin = (data: CreateSportsCenterRequest) => api.post<SportsCenter>(`/api/web/admin/sports-center`, data);
export const updateSportsCenterAdmin = (id: number, data: UpdateSportsCenterRequest) => api.put(`/api/web/admin/sports-center/${id}`, data);
export const deleteSportsCenterAdmin = (id: number) => api.delete(`/api/web/admin/sports-center/${id}`);

export const getCacheStats = () => api.get(`api/web/admin/cache/stats`);
export const clearCache = (cacheName: string) => api.put(`api/web/admin/cache/clear/${cacheName}`);
export const clearCacheFull = () => api.put(`api/web/admin/cache/clear`);
export const getConfiguration = () => api.get<Configuration>(`/api/web/configuration`);
