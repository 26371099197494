import React from 'react';
import {Alert} from 'react-bootstrap';
import translate from '../../../helpers/translations';
import CenterInformation from "./CenterInformation";
import ReservationCancellationDeadline from "./ReservationCancellationDeadline";
import HalfHourReservations from "../HalfHourReservations/HalfHourReservations";
import ReservationGapRestriction from "./ReservationGapRestriction";
import MaxDaysAheadForReservation from "./MaxDaysAheadForReservation";

interface Props {
  sportsCenter: SportsCenterOld;
  refresh: () => any;
}

const Configuration: React.FC<Props> = ({ sportsCenter, refresh }) => {
  return <div className='d-flex flex-column my-4'>
    <Alert style={{backgroundColor: '#DCDCDC'}} variant='secondary' className="fw-bold mb-1 shadow-sm">{translate('centerInformation')}</Alert>
    <CenterInformation sportsCenterId={sportsCenter.id} refresh={refresh}/>
    <Alert style={{backgroundColor: '#DCDCDC'}} variant='secondary' className="fw-bold mb-0 mt-5 shadow-sm">{translate('reservationsGap')}</Alert>
    <ReservationGapRestriction sportsCenterId={sportsCenter.id} refresh={refresh}/>
    <Alert style={{backgroundColor: '#DCDCDC'}} variant='secondary' className="fw-bold mb-0 mt-5 shadow-sm">{translate('maxDaysAheadForReservation')}</Alert>
    <MaxDaysAheadForReservation sportsCenterId={sportsCenter.id} refresh={refresh}/>
    <Alert style={{backgroundColor: '#DCDCDC'}} variant='secondary' className="fw-bold mb-0 mt-5 shadow-sm">{translate('reservationCancellation')}</Alert>
    <ReservationCancellationDeadline sportsCenterId={sportsCenter.id} refresh={refresh}/>
    {sportsCenter.halfHourSlot && <>
      <Alert style={{backgroundColor: '#DCDCDC'}} variant='secondary' className="fw-bold mb-0 mt-5 shadow-sm">{translate('halfHourSlot')}</Alert>
      <HalfHourReservations sportsCenterId={sportsCenter.id} refresh={refresh}/>
    </>}
  </div>;
}

export default Configuration;