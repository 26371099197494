import React, {useEffect, useState} from 'react';
import {Alert, Button, Col, Row} from 'react-bootstrap';
import {getSportsCentersAdmin} from '../../helpers/api';
import {useConfiguration} from '../../helpers/configuration';
import translate from '../../helpers/translations';
import Splash from '../Splash';
import CentersForm from './CentersForm';
import CentersResults from './CentersResults';
import {useNavMenu} from "../../components/NavMenu/NavMenuProvider";

const PAGE_SIZE = 20;

const Centers: React.FC = () => {
    const configuration = useConfiguration();
    const { setActiveHeaderKey, setActiveSidebarKey, setSportsCenter } = useNavMenu();

    const [results, setResults] = useState<SportsCenter[]>([]);
    const [hasMoreResults, setHasMoreResults] = useState<boolean>(false);
    const [errorMsg, setErrorMsg] = useState<string | null>(null);

    const [pageNumber, setPageNumber] = useState<number>(0);
    const [filters, setFilters] = useState<SportsCenterFilter>({sortBy: "CREATED_DESC"});

    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        setActiveHeaderKey('centers')
        setActiveSidebarKey(undefined)
        setSportsCenter(undefined)
    }, []);

    const search = () => {
        setLoading(true);
        setErrorMsg(null);
        setPageNumber(0);
        getSportsCentersAdmin(0, PAGE_SIZE, filters)
            .then(({data}) => {
                setLoading(false);
                setResults(data);
                setHasMoreResults(data.length % PAGE_SIZE === 0);
            })
            .catch(({response: {data}}) => {
                setLoading(false);
                setResults([]);
                setHasMoreResults(false);
                if (data && data.message) {
                    setErrorMsg(data.message);
                } else {
                    setErrorMsg(translate('errorFetchingResults') + '.');
                }
            })
    }

    useEffect(() => {
        configuration && search();
    }, [configuration]); // eslint-disable-line react-hooks/exhaustive-deps

    const loadMoreResults = () => {
        getSportsCentersAdmin(pageNumber + 1, PAGE_SIZE, filters)
            .then(({data}) => {
                if (data.length) {
                    const newResults = results.concat(data);
                    setResults(newResults);
                    setPageNumber(pageNumber + 1);
                    setHasMoreResults(newResults.length % PAGE_SIZE === 0);
                } else {
                    setHasMoreResults(false);
                }
            })
            .catch(({response: {data}}) => {
                setResults([]);
                setHasMoreResults(false);
                if (data && data.message) {
                    setErrorMsg(data.message);
                } else {
                    setErrorMsg(translate('errorFetchingResults') + '.');
                }
            })
    }

    if (!configuration) {
        return <Splash/>;
    }

    const countries = configuration.countries;
    const cities = filters.countryId ? (countries.find(c => c.id === filters.countryId)?.cities || []) : configuration.countries.flatMap(c => c.cities);
    const neighborhoods = filters.cityId ? (cities.find(c => c.id === filters.cityId)?.neighborhoods || []) : cities.flatMap(c => c.neighborhoods);

    return <>
            {errorMsg && <Alert variant='danger' className='mt-4' style={{fontSize: '0.85rem'}}>
                <div style={{fontWeight: '700'}}>{translate('errorFetchingResults')}.</div>
                {errorMsg}
            </Alert>}
            <CentersForm
                filters={filters}
                setFilters={setFilters}
                cities={cities}
                countries={countries}
                neighborhoods={neighborhoods}
                loading={loading}
                onSubmit={search}
            />
            {results.length === 0 && <Row>
                <Col>
                    <h3 className='text-muted mt-5 text-center'>{loading ? `${translate('loading')}...` : translate('noResults')}</h3>
                </Col>
            </Row>}
            {results.length !== 0 && <>
                <CentersResults results={results} refresh={search}/>
                {hasMoreResults && <Row>
                    <Col className='d-flex justify-content-center'>
                        <Button variant='link' className='mt-3 mb-5'
                                onClick={loadMoreResults}>{translate('seeMoreResults')}</Button>
                    </Col>
                </Row>}
                {!hasMoreResults && <Row>
                    <Col className='d-flex justify-content-center'>
                        <div className='mt-3 mb-5'>{translate('thatsAllResults')}.</div>
                    </Col>
                </Row>}
            </>}

    </>
}

export default Centers;