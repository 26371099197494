import React, { useEffect, useState } from 'react';
import { Alert, Button, Col, Container, Form, Modal, Row, Spinner } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import {createRecurringReservation, updateRecurringReservation} from '../../../helpers/api';
import { dateToDateValue, dateValueToDate, dateToTimeValue, timeValueToDate, END_OF_DAY } from '../../../helpers/date';
import { DAYS_OF_WEEK } from '../../../helpers/enums';
import { useToastNotifications } from '../../../helpers/notifications';
import Select from 'react-select';
import translate from '../../../helpers/translations';

interface Props {
  recurringReservation: RecurringReservation | null;
  sportsCenterId: number;
  courts: CourtOld[];
  openingHours: OpeningHoursOld[];
  halfHourSlot: boolean;
  onHide: () => any;
  refresh: () => any;
}

const mapRecurringReservationToForm = (reservation: RecurringReservation | null): RecurringReservationForm => {
  if (!reservation) {
    return { repeatEvery: 1 };
  }

  return {
    id: reservation.id,
    courtId: reservation.courtId,
    sport: reservation.sport,
    startDate: reservation.startDate,
    endDate: reservation.endDate,
    days: reservation.days,
    fromTime: reservation.fromTime,
    toTime: reservation.toTime,
    repeatEvery: reservation.recurringEveryWeeks,
    email: reservation.email,
    fullName: reservation.fullName,
    phoneNumber: reservation.phoneNumber,
    isPaid: reservation.paymentSuccessful,
    additionalInfo: reservation.price ? `${reservation.price} ${reservation.currency}` : undefined,
  };
};

const RecurringReservationModal: React.FC<Props> = ({ recurringReservation, sportsCenterId, halfHourSlot, courts, openingHours, onHide, refresh }) => {
  const { newToastNotification } = useToastNotifications();
  const [availableSports, setAvailableSports] = useState<Sport[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const [form, setForm] = useState<RecurringReservationForm>({ repeatEvery: 1 });


  const submit = () => {
    setErrorMsg(null);
    setLoading(true);

    const request = form.id
        ? updateRecurringReservation(sportsCenterId, form.id, form)
        : createRecurringReservation(sportsCenterId, form);

    request
      .then(({ data }) => {
        setLoading(false);
        onHide();
        refresh();
        newToastNotification(translate('addRecurringReservation'), translate('addedReservations').replaceAll('?1', `${data}`));
        setForm({ repeatEvery: 1 });
      })
      .catch(({ response: { data } }) => {
        setLoading(false);
        if (data && data.message) {
          setErrorMsg(data.message);
        } else {
          setErrorMsg(translate('unexpectedError') + '.');
        }
      })
  }

  useEffect(() => {
    setForm(mapRecurringReservationToForm(recurringReservation))
  }, [sportsCenterId, recurringReservation]);

  useEffect(() => {
    const court = courts.find(c => c.id === form.courtId);
    const availableSports = court ? court.sports : [];
    if (availableSports.length === 1 && form.sport !== availableSports[0]) {
      setForm({ ...form, sport: availableSports[0] });
    }
    setAvailableSports(availableSports);
  }, [courts, form]);

  return <Modal show>
    <Modal.Header>
      <strong>{translate('addRecurringReservation')}</strong>
    </Modal.Header>
    <Modal.Body>
      <Form>
        <Container>
          {errorMsg && <Row>
            <Col>
              <Alert variant='danger' className='mt-2' style={{ fontSize: '0.85rem' }}>
                <div style={{ fontWeight: '700' }}>{errorMsg}</div>
              </Alert>
            </Col>
          </Row>}
          <Row className='mt-2'>
            <Col>
              <Form.Select value={form.courtId === null ? '' : form.courtId} onChange={e => setForm({ ...form, courtId: e.target.value === '' ? undefined : parseInt(e.target.value), sport: undefined })}>
                <option key='' value=''>{translate('selectCourt')}</option>
                {courts.map(c => <option key={c.id} value={c.id}>{c.name}</option>)}
              </Form.Select>
            </Col>
          </Row>
          <Row className='mt-2'>
            <Col>
              {availableSports.length === 1 && <Form.Control disabled value={translate(availableSports[0])} />}
              {availableSports.length !== 1 && <Form.Select value={form.sport === null ? '' : form.sport} onChange={e => setForm({ ...form, sport: e.target.value === '' ? undefined : e.target.value as Sport })}>
                <option key='' value=''>{translate('selectSport')}</option>
                {availableSports.map(s => <option key={s} value={s as Sport}>{translate(s)}</option>)}
              </Form.Select>}
            </Col>
          </Row>
          <Row className='mt-2'>
            <Col xs={12}>
              <Form.Label>{translate('dateRange')}</Form.Label>
            </Col>
            <Col sm={6}>
              <DatePicker
                placeholderText={translate('start')}
                selected={form.startDate ? dateValueToDate(form.startDate) : null}
                onChange={date => setForm({ ...form, startDate: date ? dateToDateValue(date).substring(0, 10) : '' })}
                dateFormat='dd.MM.yyyy.'
                timeFormat='dd.MM.yyyy.'
                customInput={<Form.Control />}
              />
            </Col>
            <Col sm={6}>
              <DatePicker
                placeholderText={translate('end')}
                selected={form.endDate ? dateValueToDate(form.endDate) : null}
                onChange={date => setForm({ ...form, endDate: date ? dateToDateValue(date).substring(0, 10) : '' })}
                dateFormat='dd.MM.yyyy.'
                timeFormat='dd.MM.yyyy.'
                customInput={<Form.Control />}
              />
            </Col>
          </Row>
          <Row className='mt-2'>
            <Col xs={12}>
              <Form.Label>{translate('reservationTime')}</Form.Label>
            </Col>
            <Col>
              <Select
                isMulti
                placeholder={translate('selectDaysOfWeek')}
                options={DAYS_OF_WEEK.map(dow => ({label: translate(dow), value: dow as DayOfWeek}))}
                value={form.days ? form.days.map(dow => ({label: translate(dow), value: dow})) : []}
                onChange={selected => setForm({...form, days: selected.map(s => s.value), fromTime: undefined, toTime: undefined})}
                />
            </Col>
          </Row>
          <Row className='mt-2'>
            <Col sm={6}>
              <DatePicker
                placeholderText={translate('from')}
                selected={form.fromTime ? timeValueToDate(form.fromTime) : undefined}
                onChange={date => setForm({ ...form, fromTime: date ? dateToTimeValue(date) : undefined})}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={halfHourSlot ? 30 : 60}
                dateFormat='HH:mm'
                timeFormat='HH:mm'
                filterTime={time => {
                  const timeString = dateToTimeValue(time);
                  return form.days ? form.days.every(dow => openingHours.some(oh => oh.dayOfWeek === dow && timeString >= oh.from && timeString <= oh.to)) : false;
                }}
                customInput={<Form.Control />}
              />
            </Col>
            <Col sm={6}>
              <DatePicker
                placeholderText={translate('to')}
                selected={form.toTime ? timeValueToDate(form.toTime) : undefined}
                onChange={date => setForm({ ...form, toTime: date ? dateToTimeValue(date) : undefined })}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={halfHourSlot ? 30 : 60}
                dateFormat='HH:mm'
                timeFormat='HH:mm'
                filterTime={time => {
                  const timeString = dateToTimeValue(time);
                  return form.days ? form.days.every(dow => openingHours.some(oh => oh.dayOfWeek === dow && timeString >= oh.from && timeString <= oh.to)) : false;
                }}
                injectTimes={[END_OF_DAY]}
                customInput={<Form.Control />}
              />
            </Col>
          </Row>
          <Row className='mt-2'>
            <Col xs={12}>
              <Form.Label>{translate('repetition')}</Form.Label>
            </Col>
            <Col xs={12}>
              {
                [1, 2, 3].map(value => (
                    <Form.Check
                        key={value}
                        value={value}
                        type="radio"
                        label={translate("everyWeek" + value)}
                        onChange={() => setForm({...form, repeatEvery: value})}
                        checked={form.repeatEvery === value}
                    />
                ))
              }
            </Col>
          </Row>
          {/* <Row className='mt-2'>
            <Col>
              <Form.Label>Description</Form.Label>
              <Form.Control as='textarea' placeholder='Add your description here' rows={3} value={form.description} onChange={e => setForm({ ...form, description: e.target.value })} />
            </Col>
          </Row> */}
          <Row className='mt-2'>
            <Col>
              <Form.Label>{translate('email')}</Form.Label>
              <Form.Control value={form ? form.email : ''} onChange={e => form && setForm({ ...form, email: e.target.value })} />
            </Col>
          </Row>
          <Row className='mt-2'>
            <Col>
              <Form.Label>{translate('fullName')}</Form.Label>
              <Form.Control value={form ? form.fullName : ''} onChange={e => form && setForm({ ...form, fullName: e.target.value })} />
            </Col>
          </Row>
          <Row className='mt-2'>
            <Col>
              <Form.Label>{translate('phoneNumber')}</Form.Label>
              <Form.Control value={form ? form.phoneNumber : ''} onChange={e => form && setForm({ ...form, phoneNumber: e.target.value })} />
            </Col>
          </Row>
          <Row className='mt-2'>
            <Col md={12} className='my-2 d-flex'>
              <Form.Label>{translate('paymentSuccessful')}&nbsp;&nbsp;</Form.Label>
              <Form.Check checked={form.isPaid} onChange={e => form && setForm({ ...form, isPaid: ((e.target as HTMLInputElement).checked) })} />
            </Col>
          </Row>
        </Container>
      </Form>
    </Modal.Body>
    <Modal.Footer>
      {loading && <Spinner animation='border' variant='secondary' className='mx-auto' />}
      {!loading && <>
        <Button variant='secondary' onClick={() => onHide()}>{translate('close')}</Button>
        <Button variant='primary' onClick={() => submit()}>{translate('save')}</Button>
      </>}
    </Modal.Footer>
  </Modal>;
};

export default RecurringReservationModal;