import React, {useEffect, useRef, useState} from 'react';
import { Alert, Button, Card, Col, Container, Form, Modal, Row } from 'react-bootstrap';
import { RiArrowLeftSLine, RiArrowRightSLine, RiCloseFill } from 'react-icons/ri';
import ConfirmationModal from '../../../components/ConfirmationModal';
import { deleteReservation, getReservationsByDate } from '../../../helpers/api';
import {dateEquals, dateToDateValue, dateToTimeValue, formatDateValue, padded} from '../../../helpers/date';
import { DAYS_OF_WEEK, MONTHS } from '../../../helpers/enums';
import { useToastNotifications } from '../../../helpers/notifications';
import { sportIcons } from '../../../helpers/sportIcons';
import Splash from '../../Splash';
import CenterCourtModal from './CenterCourtModal';
import CenterReservationModal from './CenterReservationModal';
import CenterReservationUpdateModal from './CenterReservationUpdateModal';
import './CenterReservations.css';
import translate from '../../../helpers/translations';
import Iphone from "../../../components/Icons/Iphone";
import Repeat from "../../../components/Icons/Repeat";
import Person from "../../../components/Icons/Person";
import CreditCard from "../../../components/Icons/CreditCard";
import Checkmark from "../../../components/Icons/Checkmark";
import { RxCrossCircled } from "react-icons/rx";


const ROW_HEIGHT = 17.5;
const COURT_WIDTH = 200;
const TABLE_SIDE_PADDING = 30;

interface Props {
  sportsCenter: SportsCenterOld;
  selected: boolean;
  refresh: () => any;
}

interface Entry { from: number; durationMinutes: number; reservation: Reservation }
interface Entries { [hour: number]: { [courtId: number]: Entry | null } }

const bottom = (rowIndex: number) => `${ROW_HEIGHT * rowIndex + 0.5}px`;
const left = (colIndex: number) => `${colIndex * COURT_WIDTH + 60}px`;
const position = (rowIndex: number, colIndex: number) => ({ bottom: bottom(rowIndex), left: left(colIndex) });

const isAllowedTime = (openingHours: OpeningHoursOld[], selectedDate: Date, time: string) => {
  const selectedDayOfWeek = DAYS_OF_WEEK[(selectedDate.getDay() + 6) % 7];
  return openingHours.some(oh => oh.dayOfWeek === selectedDayOfWeek && time >= oh.from && time < oh.to);
}

const isAllowedTimeInReservation = (openingHours: OpeningHoursOld[], selectedDate: Date, time: string) => {
  const selectedDayOfWeek = DAYS_OF_WEEK[(selectedDate.getDay() + 6) % 7];
  return openingHours.some(oh => oh.dayOfWeek === selectedDayOfWeek && time >= oh.from && time <= oh.to);
}


const hasOffer = (sportsCenter: SportsCenterOld, courtId: number, selectedDate: Date, time: string) => {
  const selectedDayOfWeek = DAYS_OF_WEEK[(selectedDate.getDay() + 6) % 7];
  return sportsCenter.offers.some(offer =>
      offer.courts.some(court =>
          court.id === courtId && offer.hours.some(oh =>
              oh.dayOfWeek === selectedDayOfWeek && time >= oh.from && time < oh.to
          )
      )
  );
};

const CenterReservationsHalfHour: React.FC<Props> = ({ sportsCenter, refresh, selected }) => {
  const { newToastNotification } = useToastNotifications();
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const [deletingErrorMsg, setDeletingErrorMsg] = useState<string | null>(null);
  const [datePage, setDatePage] = useState<Date[]>([]);
  const [selectedDatePage, setSelectedDatePage] = useState<number>(0);
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [hours, setHours] = useState<number[]>(Array.from({ length: 24 }, (value, index) => index));
  const [entries, setEntries] = useState<Entries | null>(null);
  const [selectedReservation, setSelectedReservation] = useState<Reservation | null>();
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [showUpdateReservation, setShowUpdateReservation] = useState<boolean>(false);
  const [reservationForm, setReservationForm] = useState<ReservationForm | null>(null);
  const [allowedDurations, setAllowedDurations] = useState<number[]>([]);
  const [description, setDescription] = useState<string | null>(null);
  const [deleteInProgress, setDeleteInProgress] = useState<boolean>(false);
  const [selectedCourt, setSelectedCourt] = useState<CourtOld | null>(null);
  const [applicableTimesOff, setApplicableTimesOff] = useState<{ start: number, duration: number }[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [currentTimeStyle, setCurrentTimeStyle] = useState<{ top: number, left: number, width: number } | null>(null);
  const [currentTime, setCurrentTime] = useState<Date>(new Date());
  const tableContainerDivRef = useRef<HTMLDivElement>(null);
  const tableHeaderRef = useRef<HTMLTableRowElement>(null);

  const openingHours: OpeningHoursOld[] = sportsCenter.openingHours
  const courts: CourtOld[] = sportsCenter.courts.filter(c => c.active)
  const timesOff: TimeOffOld[] = sportsCenter.timesOff
  const reservationColors = sportsCenter.reservationColors;

  const loadReservations = () => {
    setErrorMsg(null);
    setLoading(true)
    const date = `${padded(selectedDate.getFullYear())}-${padded(selectedDate.getMonth() + 1)}-${padded(selectedDate.getDate())}`;
    getReservationsByDate(sportsCenter.id, date)
      .then(({ data }) => {
        const entries: Entries = {};
        hours.forEach(h => {
          entries[h] = {};
          courts.forEach(c => entries[h][c.id] = null);
        });
        data
          .filter(r => !r.status.startsWith('CANCELLED'))
          .forEach(r => {
            const from = new Date(r.fromDateTime);
            const to = new Date(r.toDateTime);
            const durationMinutes = Math.floor((to.getTime() - from.getTime()) / 60_000);
            for (let date = new Date(from); date.getTime() < to.getTime(); date = new Date(date.getTime() + 30 * 60000)) {
              entries[date.getHours() + date.getMinutes() / 60][r.court.id] = { from: from.getHours() + from.getMinutes() / 60, durationMinutes: durationMinutes, reservation: r };
            }
          });
        setEntries(entries);
      })
      .catch(({ response: { data } }) => {
        setEntries([]);
        if (data && data.message) {
          setErrorMsg(data.message);
        } else {
          setErrorMsg(translate('unexpectedError') + '.');
        }
      })
        .finally(() => setLoading(false))
  }

  useEffect(() => {
    let page: Date[] = [];

    const now = new Date();
    const daysInPast = -selectedDatePage * 7 + ((now.getDay() + 6) % 7);

    for (let i = -daysInPast; i < -daysInPast + 7; i++) {
      const date = new Date(now);
      date.setDate(date.getDate() + i);
      page.push(date);
    }
    setDatePage(page)

  }, [selectedDatePage])

  useEffect(() => {
    const interval = setInterval(() => {
      loadReservations();
    }, 30000); // Refresh every 30 second

    return () => clearInterval(interval); // Cleanup on unmount
  }, [selectedDate]);

  function calcAndSetCurrentTime() {
    if(!selected) return;
    const now = new Date();
    setCurrentTime(now);
    const from = new Date();
    from.setHours(hours[0] % 1 == 0 ? hours[0] : hours[0] - 0.5);
    from.setMinutes(hours[0] % 1 == 0 ? 0 : 30);
    const to = new Date();
    to.setHours(hours[hours.length - 1] % 1 == 0 ? hours[hours.length - 1] : hours[hours.length - 1] - 0.5);
    to.setMinutes(hours[hours.length - 1] % 1 == 0 ? 0 : 30);

    if (tableContainerDivRef.current && tableHeaderRef.current && dateEquals(now, selectedDate) && now.getHours() >= from.getHours() && now.getHours() <= to.getHours()) {
      const top = tableHeaderRef.current.clientHeight + ((now.getHours() + now.getMinutes() / 60.0) - (from.getHours() + from.getMinutes() / 60.0))  * ROW_HEIGHT * 2;
      const tableHeaderWidth = tableHeaderRef.current.clientWidth
      const tableContainerDivWidth = tableContainerDivRef.current.clientWidth - 2 * TABLE_SIDE_PADDING
      const tableVisibleWidth = Math.min(tableHeaderWidth, tableContainerDivWidth);
      setCurrentTimeStyle({
        top: top,
        left: tableVisibleWidth == tableContainerDivWidth ? TABLE_SIDE_PADDING + 58 : (tableContainerDivRef.current.clientWidth - tableHeaderWidth) / 2 + 58,
        width: tableVisibleWidth - 58
      })
    } else {
      setCurrentTimeStyle(null);
    }
  }

  useEffect(() => {
    calcAndSetCurrentTime()
    if(!selected) return;
    const interval = setInterval(() => {
      calcAndSetCurrentTime()
    }, 1000);
    return () => clearInterval(interval);
  }, [selected, selectedDate, hours]);

  useEffect(() => {
    let minHour = 23;
    let maxHour = 0;
    openingHours.forEach(oh => {
      const hourFrom = parseInt(oh.from);
      let hourTo = parseInt(oh.to);
      if(oh.to == '23:59') {
        hourTo = 24
      }
      if (hourFrom < minHour) {
        minHour = hourFrom;
      }
      if (hourTo > maxHour) {
        maxHour = hourTo;
      }
    });
    setHours(Array.from({ length: (maxHour - minHour) * 2}, (value, index) => minHour + (index / 2)));
  }, [openingHours]);

  useEffect(loadReservations, [sportsCenter.openingHours,  sportsCenter.courts, hours, sportsCenter.id, selectedDate]);

  useEffect(() => {

    let midnight = new Date(selectedDate);
    midnight.setHours(0);
    midnight.setMinutes(0);
    midnight.setSeconds(0);

    let timeOffHours = [];
    for (let i = 0; i <= 23; i++) {
      const date = new Date(midnight);
      date.setHours(i);
      const dateString = dateToDateValue(date);
      if (hours.includes(i) && timesOff.some(timeOff => dateString >= timeOff.from && dateString < timeOff.to)) {
        timeOffHours.push(date.getHours());
      }
    }

    const applicableTimesOff = [];
    if (timeOffHours.length !== 0) {
      for (let i = 0; i < timeOffHours.length; i++) {
        const start = timeOffHours[i];
        let duration = 1;
        while (timeOffHours[i + 1] === (timeOffHours[i] + 1)) {
          i++;
          duration++;
        }
        applicableTimesOff.push({ start, duration });
      }
    }

    setApplicableTimesOff(applicableTimesOff);
  }, [hours, timesOff, selectedDate]);

  if (!entries) {
    return <Splash />;
  }

  const onDelete = () => {
    if (selectedReservation) {
      setDeletingErrorMsg(null);
      setDeleteInProgress(true);
      deleteReservation(selectedReservation.sportsCenter.id, selectedReservation.id, description || '')
        .then(() => {
          setDeleteInProgress(false);
          setShowDeleteModal(false);
          setSelectedReservation(null);
          setDescription('')
          loadReservations();
          newToastNotification(translate('deleteReservation'), translate('operationSuccessful'));
        })
        .catch(({ response: { data } }) => {
          setDeleteInProgress(false);
          setDeletingErrorMsg((data && data.message) ? data.message : translate('unexpectedError'));
        })
    }
  }

  const isSlotAvailable = (h: number, courtId: number) => {
    return (
        entries[h] &&
        !entries[h][courtId] &&
        isAllowedTime(openingHours, selectedDate, h % 1 == 0 ? `${padded(h)}:00` : `${padded(h - 0.5)}:30`) &&
        hasOffer(sportsCenter, courtId, selectedDate, h % 1 == 0 ? `${padded(h)}:00` : `${padded(h - 0.5)}:30`) &&
        !applicableTimesOff.some(({start, duration}) => h >= start && h < (start + duration))
    )
  }

  const reservationColorStyles = (reservation: Reservation) => {
    if(reservationColors && reservationColors.customColorEnabled) {
      switch (reservation.type) {
        case "RECURRING":
          return {backgroundColor: reservationColors.recurringBackgroundColor, color: reservationColors.recurringTextColor, fill: reservationColors.recurringTextColor}
        case "MOBILE":
          return {backgroundColor: reservationColors.fromAppBackgroundColor, color: reservationColors.fromAppTextColor, fill: reservationColors.fromAppTextColor}
        case "SPORTS_CENTER":
          return {backgroundColor: reservationColors.fromAdminBackgroundColor, color: reservationColors.fromAdminTextColor, fill: reservationColors.fromAdminTextColor}
      }
    }
    return {}
  }

  return <>
    {errorMsg && <Alert variant='danger' className='mt-4' style={{fontSize: '0.85rem'}}>
      <div style={{fontWeight: '700'}}>{errorMsg}</div>
    </Alert>}
    {loading && <Splash/>}
    <div className='d-flex flex-column'>
      <div className='text-center mt-1 mb-1'>
        <div className='text-muted date-header-container'>
          {translate(DAYS_OF_WEEK[(selectedDate.getDay() + 6) % 7])},&nbsp;
          {selectedDate.getDate()}.&nbsp;
          {translate(MONTHS[selectedDate.getMonth()])}&nbsp;
          {selectedDate.getFullYear()}.
        </div>
      </div>
      <Button className="d-lg-none btn-sm mb-1" variant='primary' style={{alignSelf: 'center', width: '90px'}}
              onClick={() => loadReservations()}>
        {translate('refresh')}
      </Button>
      <div className="date-buttons-container mb-4" style={{marginLeft: 0, marginRight: 0, paddingLeft: 0}}>
        <div className="row justify-content-center align-items-center">
          <div className="col-12 col-lg-12 px-0">
            <div className="d-flex justify-content-center flex-wrap">
              <span className="d-none d-lg-block" style={{width: '90px'}}/>
              <span className="d-flex justify-content-center">
                <Button variant='link' className='p-0 text-secondary'
                        style={{fontSize: '2rem', width: '10vw', maxWidth: '2rem'}}
                        onClick={() => setSelectedDatePage(selectedDatePage - 1)}><RiArrowLeftSLine/></Button>
                {datePage.map(date => <div key={date.toISOString()} className='d-flex flex-column'>
                  <Button
                      key={date.toISOString()}
                      variant={dateEquals(date, selectedDate) ? 'secondary' : 'outline-secondary'}
                      className='shadow-none my-0'
                      style={{marginLeft: 1, marginRight: 1}}
                      onClick={() => setSelectedDate(date)}
                  >
                    <div className="button-header">{date.getDate()}</div>
                    <div className='button-body'
                         style={{marginTop: '-0.75rem'}}>{translate(DAYS_OF_WEEK[(date.getDay() + 6) % 7].substring(0, 3))}</div>
                  </Button>
                  {
                      dateEquals(date, new Date()) &&
                      <div className='button-text-below' style={{marginLeft: '2px'}}>
                        {translate('today')}
                      </div>
                  }
                </div>)}
                <Button variant='link' className='text-secondary p-0'
                        style={{fontSize: '2rem', width: '10vw', maxWidth: '2rem'}}
                        onClick={() => setSelectedDatePage(selectedDatePage + 1)}><RiArrowRightSLine/>
                </Button>
              </span>
              <Button className="d-none d-lg-block " variant='primary'
                      style={{alignSelf: 'center', width: '90px', maxWidth: '90px'}}
                      onClick={() => loadReservations()}>
                {translate('refresh')}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div ref={tableContainerDivRef}
         style={{position: 'relative', paddingLeft: TABLE_SIDE_PADDING, paddingRight: TABLE_SIDE_PADDING}}>
      {currentTimeStyle &&
          <>
            <div style={{
              position: 'absolute', padding: 0, margin: 0, zIndex: '5', pointerEvents: 'none',
              width: currentTimeStyle.width,
              border: 'none', height: '1.5px', color: 'red', backgroundColor: 'red', opacity: '70%',
              top: currentTimeStyle.top - 1, left: currentTimeStyle.left,
            }}/>
            <div style={{
              position: 'absolute', padding: 0, margin: 0, zIndex: '5', pointerEvents: 'none',
              width: '6px', height: '6px', borderRadius: '50%', backgroundColor: 'red', opacity: '70%',
              top: currentTimeStyle.top - 3, left: currentTimeStyle.left,
            }}/>
            <div style={{
              position: 'absolute', padding: 0, margin: 0, zIndex: '5', pointerEvents: 'none',
              border: 'none', height: '2px', color: 'red', fontSize: '14px',
              top: currentTimeStyle.top - 10, left: currentTimeStyle.left + currentTimeStyle.width + 4,
            }}>
              {dateToTimeValue(currentTime)}
            </div>
          </>
      }
      <div className='shadow' style={{
        position: 'relative',
        margin: '0 auto',
        width: '100%',
        maxWidth: courts.length * COURT_WIDTH + 62,
        border: 'solid 1px #dee2e6'
      }}>
        <div className="reservations" style={{padding: '0 auto'}}>
          <table className='reservations-table m-0 p-0'>
            <thead>
            <tr ref={tableHeaderRef}>
              <th className="sticky reservations-first-col-first-row bg-white p-0" style={{height: '17.5px'}}/>
              {courts.map((c, i) => <th key={c.id} className='reservations-col text-center p-1'
                                        style={{zIndex: 3, border: 'solid 1px #dee2e6', height: '17.5px'}}>
                <span className='d-flex flex-column' style={{textAlign: 'center'}}>
                    <Button variant='link' className='text-muted p-0' style={{lineHeight: '1.25rem'}}
                            onClick={() => setSelectedCourt(c)}>{c.name}</Button>
                    <div className='text-muted small fw-light'>{translate('number')}: {c.number || '-'}</div>
                    <div className='text-muted small fw-light'
                         style={{marginTop: '-0.5rem'}}>{translate(c.courtType)}, {translate(c.surfaceType)}</div>
                    <div className='mt-auto mb-1'>
                      {c.sports
                          .map(s => s.toLowerCase().replaceAll('-', '_'))
                          .map(name => <img height={20} width={20} key={name}
                                            src={sportIcons[name as keyof typeof sportIcons]} alt={name}
                                            style={{margin: '1px'}}/>)}
                    </div>
                </span>
              </th>)}
            </tr>
            </thead>
            <tbody>
            {hours.length !== 0 && hours.map(h => {
              const time = h % 1 == 0 ? `${padded(h)}:00` : `${padded(h - 0.5)}:30`;
              return <tr key={time}>
                <td className="sticky reservations-first-col p-0" style={{zIndex: 3, height: '17.5px'}}>
                  <div style={{
                    fontSize: '14px',
                    position: "absolute",
                    top: '-10px',
                    right: '10px'
                  }}>{h % 1 == 0 ? time : ''}</div>
                </td>
                {courts.map(c => <td key={`${h}-${c.id}`} className='text-center p-0' valign='middle' style={{
                  border: 'solid 1px #dee2e6',
                  borderTop: h % 1 != 0 ? 'none' : 'solid 1px #dee2e6',
                  borderBottom: 'solid 1px #dee2e6'
                }}>
                  {((!c.active || !isAllowedTime(openingHours, selectedDate, time) || !hasOffer(sportsCenter, c.id, selectedDate, time)) && !applicableTimesOff.some(to => h >= to.start && h < (to.start + to.duration)) &&
                      <div style={{
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        fontSize: '10px'
                      }}><RiCloseFill className='opacity-50'/></div>)}
                </td>)}
              </tr>;
            })}
            </tbody>
          </table>
          {hours.length !== 0 && hours.flatMap((h, hourIndex) => {
            if (!isAllowedTime(openingHours, selectedDate, h % 1 == 0 ? `${padded(h)}:00` : `${padded(h - 0.5)}:30`)) {
              return [];
            }
            return courts.map((c, courtIndex) => {
              const entry = entries[h] && entries[h][c.id];
              if (!c.active || !hasOffer(sportsCenter, c.id, selectedDate, h % 1 == 0 ? `${padded(h)}:00` : `${padded(h - 0.5)}:30`)) {
                return null;
              } else if (entry) {
                if (entry.from !== h) {
                  return null;
                } else {
                  let title;
                  if (entry.reservation.user && (entry.reservation.user.firstName || entry.reservation.user.lastName)) {
                    title = `${entry.reservation.user.firstName ? entry.reservation.user.firstName : ''} ${entry.reservation.user.lastName ? entry.reservation.user.lastName : ''}`;
                  } else if (entry.reservation.fullName) {
                    title = `${entry.reservation.fullName}`;
                  } else if (entry.reservation.user && entry.reservation.user.email) {
                    title = entry.reservation.user.email;
                  } else if (entry.reservation.email) {
                    title = entry.reservation.email;
                  } else if (entry.reservation.phoneNumber) {
                    title = entry.reservation.phoneNumber;
                  }

                  return <Button
                      key={`${hourIndex}-${courtIndex}`}
                      size='sm'
                      variant='secondary'
                      className='reservation'
                      onClick={() => setSelectedReservation(entry.reservation)}
                      style={{
                        ...reservationColorStyles(entry.reservation), ...position(hours.length - hourIndex - Math.ceil(entry.durationMinutes / 30), courtIndex),
                        height: `${Math.floor(entry.durationMinutes / 30 * ROW_HEIGHT)}px`
                      }}>
                    <Card.Body className='d-flex justify-content-center align-items-center'
                               style={{fontSize: (entry.durationMinutes == 30 || entry.durationMinutes == 29) ? '10px' : '12px', lineHeight: '12px'}}>
                      <div style={{
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        display: 'flex',
                        alignItems: 'center',
                      }}>
                        {entry.reservation.status == 'NO_SHOW' &&
                            <RxCrossCircled
                                size={(entry.durationMinutes == 30 || entry.durationMinutes == 29) ? 14 : 16}
                                style={{ marginRight: '3px', verticalAlign: 'text-bottom', marginBottom: '1px'}}
                            />
                        }
                        {entry.reservation.type === "MOBILE" ?
                            <Iphone height={(entry.durationMinutes == 30 || entry.durationMinutes == 29) ? 14 : undefined}
                                    style={{ marginRight: '3px', verticalAlign: 'text-bottom', marginBottom: '1px'}}
                            /> : null}
                        {entry.reservation.type === "SPORTS_CENTER" ?
                            <Person height={(entry.durationMinutes == 30 || entry.durationMinutes == 29) ? 14 : undefined}
                                style={{ marginRight: '3px', verticalAlign: 'text-bottom',
                                  marginBottom: (entry.durationMinutes == 30 || entry.durationMinutes == 29) ? undefined : '1px',
                                  marginTop: (entry.durationMinutes == 30 || entry.durationMinutes == 29) ? '-3px' : undefined
                                }}
                            /> : null
                        }
                        {entry.reservation.paymentMethod === "CARD" ?
                            <CreditCard height={(entry.durationMinutes == 30 || entry.durationMinutes == 29) ? 14 : undefined}
                              style={{ marginRight: '3px', verticalAlign: 'text-bottom',
                                marginBottom: (entry.durationMinutes == 30 || entry.durationMinutes == 29) ? undefined : '1px',
                                marginTop: (entry.durationMinutes == 30 || entry.durationMinutes == 29) ? '-3px' : undefined
                              }}
                            /> : null
                        }
                        {entry.reservation.paymentMethod !== "CARD" && entry.reservation.isPaid ?
                            <Checkmark height={(entry.durationMinutes == 30 || entry.durationMinutes == 29) ? 14 : undefined}
                               style={{ marginRight: '3px', verticalAlign: 'text-bottom',
                                 marginBottom: (entry.durationMinutes == 30 || entry.durationMinutes == 29) ? undefined : '1px',
                                 marginTop: (entry.durationMinutes == 30 || entry.durationMinutes == 29) ? '-3px' : undefined
                               }}
                            /> : null
                        }
                        {entry.reservation.type === "RECURRING" ?
                            <Repeat height={(entry.durationMinutes == 30 || entry.durationMinutes == 29) ? 14 : undefined}
                              style={{ marginRight: '3px', verticalAlign: 'text-bottom',
                                marginBottom: (entry.durationMinutes == 30 || entry.durationMinutes == 29) ? undefined : '1px',
                                marginTop: (entry.durationMinutes == 30 || entry.durationMinutes == 29) ? '-3px' : undefined
                              }}
                            /> : null
                        }
                        {title || translate('reserved')}
                        {!title && <>&nbsp;({translate('manualEntry')})</>}
                      </div>
                    </Card.Body>
                  </Button>;
                }
              } else {
                return <Button
                    key={`${hourIndex}-${courtIndex}`}
                    size='sm'
                    variant='secondary'
                    className='bg-secondary reservation add d-flex flex-column justify-content-center align-items-center'
                    onClick={() => {
                      const allowedDurations = [];
                      for (let i = 0.5; h + i <= 24; i = i + 0.5) {
                        if (
                            entries[h + i - 0.5]
                            && !entries[h + i - 0.5][c.id]
                            && isAllowedTimeInReservation(openingHours, selectedDate, (h + i) % 1 == 0 ? `${padded((h + i))}:00` : `${padded((h + i) - 0.5)}:30`)
                            && hasOffer(sportsCenter, c.id, selectedDate, (h + i - 0.5) % 1 == 0 ? `${padded((h + i - 0.5))}:00` : `${padded((h + i) - 1)}:30`)
                            && !applicableTimesOff.some(({
                                                           start,
                                                           duration
                                                         }) => (h + i - 0.5) >= start && (h + i - 0.5) < (start + duration))) {
                          allowedDurations.push(i);
                        }
                      }
                      setAllowedDurations(allowedDurations);

                      const from = new Date(selectedDate);
                      from.setHours(h % 1 == 0 ? h : h - 0.5);
                      from.setMinutes(h % 1 == 0 ? 0 : 30);
                      from.setSeconds(0);

                      const to = new Date(selectedDate);
                      const duration = allowedDurations.find(d => d == 1) || 0.5
                      const hour = h + duration;
                      to.setHours(hour % 1 == 0 ? hour : hour - 0.5);
                      to.setMinutes(hour % 1 == 0 ? 0 : 30);
                      to.setSeconds(0);

                      setReservationForm({
                        paymentMethod: 'ON_SITE',
                        sport: c.sports[0] || '',
                        fromDateTime: dateToDateValue(from),
                        toDateTime: dateToDateValue(to),
                        courtId: c.id,
                        additionalInfo: '',
                        email: '',
                        fullName: '',
                        phoneNumber: '',
                        isPaid: false
                      });
                    }}
                    style={{
                      ...position(hours.length - hourIndex - (isSlotAvailable(h + 0.5, c.id) ? 2 : 1), courtIndex),
                      fontSize: '12px',
                      height: isSlotAvailable(h + 0.5, c.id) ? '35px' : '17.5px'
                    }}>
                  <div>{translate('addReservation')}</div>
                  <div style={{marginTop: '-0.33rem'}}>+</div>
                </Button>
              }
            });
          })
          }
          {
            applicableTimesOff.flatMap(({start, duration}) => {
              return <div
                  className='reservation bg-danger d-flex justify-content-center align-items-center opacity-75'
                  key={`${start}`} style={{
                marginLeft: '60px',
                bottom: bottom((hours[hours.length - 1] - start - duration) * 2 + 1),
                width: `${courts.length * 200}px`,
                height: bottom(duration * 2)
              }}
              >
                <h3 className='text-white px-4'>{translate('timeOff')}</h3></div>;
            })
          }
        </div>
      </div>
    </div>

    <div className='card shadow d-flex flex-column border'
         style={{padding: '10px', marginTop: '20px', borderRadius: '10px'}}>
      <div className='d-flex flex-row align-items-center' style={{margin: '5px 5px 5px 15px'}}>
        <div className='d-flex flex-row align-items-center justify-content-center' style={{width: '45px'}}>
          <Iphone height={18} width={16}/>&nbsp;
          {reservationColors && reservationColors.customColorEnabled && <div style={{
            backgroundColor: reservationColors.fromAppBackgroundColor,
            width: '15px',
            height: '15px',
            borderRadius: '5px'
          }}/>}
        </div>
        <div className='d-flex flex-row align-items-center' style={{width: '100%'}}>
          <div style={{textAlign: 'center', paddingLeft: '10px', paddingRight: '10px'}}>-</div>
          {translate('reservedUsingMobile')}
        </div>
      </div>
      <div className='d-flex flex-row align-items-center' style={{margin: '5px 5px 5px 15px'}}>
        <div className='d-flex flex-row align-items-center justify-content-center' style={{width: '45px'}}>
          <Repeat height={16} width={16}/>&nbsp;
          {reservationColors && reservationColors.customColorEnabled && <div style={{
            backgroundColor: reservationColors.recurringBackgroundColor,
            width: '15px',
            height: '15px',
            borderRadius: '5px'
          }}/>}
        </div>
        <div className='d-flex flex-row align-items-center' style={{width: '100%'}}>
          <div style={{textAlign: 'center', paddingLeft: '10px', paddingRight: '10px'}}>-</div>
          {translate('recurringReservation')}
        </div>
      </div>
      <div className='d-flex flex-row align-items-center' style={{margin: '5px 5px 5px 15px'}}>
        <div className='d-flex flex-row align-items-center justify-content-center' style={{width: '45px'}}>
          <Person height={22} width={20}/>&nbsp;
          {reservationColors && reservationColors.customColorEnabled && <div style={{
            backgroundColor: reservationColors.fromAdminBackgroundColor,
            width: '15px',
            height: '15px',
            borderRadius: '5px'
          }}/>}
        </div>
        <div className='d-flex flex-row align-items-center' style={{width: '100%'}}>
          <div style={{textAlign: 'center', paddingLeft: '10px', paddingRight: '10px'}}>-</div>
          {translate('reservationsFromAdmin')}
        </div>
      </div>
      <div className='d-flex flex-row align-items-center' style={{margin: '5px 5px 5px 15px'}}>
        <div className='d-flex flex-row align-items-center justify-content-center' style={{width: '45px'}}>
          <CreditCard height={18} width={18}/>&nbsp;
        </div>
        <div className='d-flex flex-row align-items-center' style={{width: '100%'}}>
          <div style={{textAlign: 'center', paddingLeft: '10px', paddingRight: '10px'}}>-</div>
          {translate('reservationCardPaid')}
        </div>
      </div>
      <div className='d-flex flex-row align-items-center' style={{margin: '5px 5px 5px 15px'}}>
        <div className='d-flex flex-row align-items-center justify-content-center' style={{width: '45px'}}>
          <Checkmark height={18} width={18}/>&nbsp;
        </div>
        <div className='d-flex flex-row align-items-center' style={{width: '100%'}}>
          <div style={{textAlign: 'center', paddingLeft: '10px', paddingRight: '10px'}}>-</div>
          {translate('reservationPaid')}
        </div>
      </div>
      <div className='d-flex flex-row align-items-center' style={{margin: '5px 5px 5px 15px'}}>
        <div className='d-flex flex-row align-items-center justify-content-center' style={{width: '45px'}}>
          <RxCrossCircled size={18}/>&nbsp;
        </div>
        <div className='d-flex flex-row align-items-center' style={{width: '100%'}}>
          <div style={{textAlign: 'center', paddingLeft: '10px', paddingRight: '10px'}}>-</div>
          {translate('userDidNotAppear')}
        </div>
      </div>
    </div>
    {
        selectedReservation && !showDeleteModal && !showUpdateReservation && <Modal show>
          <Modal.Header>
            <Modal.Title>
              {selectedReservation.type === "RECURRING" ?
                  <Repeat style={{marginRight: '10px', verticalAlign: 'textBottom'}}/> : []}
              {translate('reservation')} ({translate('id')}={selectedReservation.id})
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className='my-3'>
            <div><strong>{translate('sport')}:</strong> {translate(selectedReservation.sport)}</div>
            <div>
              <strong>{translate('range')}:</strong> {formatDateValue(selectedReservation.fromDateTime)} - {formatDateValue(selectedReservation.toDateTime)}
            </div>
            <div><strong>{translate('price')}:</strong> {selectedReservation.price} {selectedReservation.currency}</div>
            {!selectedReservation.user && <div><strong>{translate('email')}: </strong>{selectedReservation.email ? selectedReservation.email : '-'}</div>}
            {!selectedReservation.user && <div><strong>{translate('fullName')}: </strong>{selectedReservation.fullName ? selectedReservation.fullName : '-'}</div>}
            {!selectedReservation.user && <div><strong>{translate('phoneNumber')}: </strong>{selectedReservation.phoneNumber ? selectedReservation.phoneNumber : '-'}</div>}
            {selectedReservation.user && <div>
              <div><strong>{translate('person')}:</strong></div>
              {selectedReservation.user.firstName && selectedReservation.user.lastName && <div>{selectedReservation.user.firstName} {selectedReservation.user.lastName}</div>}
              <div>{selectedReservation.user.email}</div>
              {selectedReservation.user.phoneNumber && <div>{selectedReservation.user.phoneNumber}</div>}
              {!selectedReservation.user.phoneNumber && selectedReservation.phoneNumber &&
                  <div>{selectedReservation.phoneNumber}</div>
              }
            </div>}
            {selectedReservation.additionalInfo &&
                <>
                  <div><strong>{translate('additionalInfo')}:</strong></div>
                  <div>{selectedReservation.additionalInfo}</div>
                </>
            }
          </Modal.Body>
          <Modal.Footer>
            <Button variant='secondary' onClick={() => setSelectedReservation(null)}>{translate('close')}</Button>
            <Button onClick={() => setShowUpdateReservation(true)} variant='primary'>
              {translate('edit')}
            </Button>
            <Button variant='danger' onClick={() => {
              setShowDeleteModal(true);
            }}>{translate('delete')}</Button>
          </Modal.Footer>
        </Modal>
    }
    <CenterReservationModal
        sportsCenterId={sportsCenter.id}
        show={!!reservationForm}
        courts={courts}
        allowedDurations={allowedDurations}
        halfHourSlot={sportsCenter.halfHourSlot}
        form={reservationForm}
        setForm={setReservationForm}
        refresh={() => {
          setReservationForm(null);
          loadReservations();
        }}
        onHide={() => setReservationForm(null)}
    />
    {showUpdateReservation && !!selectedReservation &&
        <CenterReservationUpdateModal
            sportsCenterId={sportsCenter.id}
            show={true}
            courts={courts}
            reservation={selectedReservation}
            openingHours={openingHours}
            halfHourSlot={sportsCenter.halfHourSlot}
            refresh={() => {
              setReservationForm(null);
              loadReservations();
            }}
            onHide={() => {
              setSelectedReservation(null)
              setShowUpdateReservation(false)
            }}
        />
    }
    {selectedReservation && showDeleteModal && <ConfirmationModal
        title={translate('confirmDeleting')}
        body={<>
          <Container>
            {deletingErrorMsg && <Alert variant='danger' style={{fontSize: '0.85rem'}}>
              <div style={{fontWeight: '700'}}>{deletingErrorMsg}</div>
            </Alert>}
            {
                selectedReservation.paymentMethod === "CARD" &&
                <>
                  <Row><Col><b><i>{translate('cardReservationDeleteWarning')}</i></b></Col></Row>
                  <Row>&nbsp;</Row>
                </>
            }
            <Row>
              <Col>
                {translate('sureToDeleteReservation')}?
              </Col>
            </Row>
            <Row className='mt-3'>
              <Col>
                <Form>
                  <Form.Group>
                    <Form.Label>{translate('cancellationReason')}</Form.Label>
                    <Form.Control
                        as="textarea"
                        placeholder={translate("addCancellationReason")}
                        rows={3}
                        value={description || ""}
                        onChange={(e) => setDescription(e.target.value)}
                        style={{ whiteSpace: "pre-wrap" }} // Ensures new lines are preserved when displayed later
                    />
                  </Form.Group>
                </Form>
              </Col>
            </Row>
          </Container>
        </>}
        confirmButtonVariant='danger'
        inProgress={deleteInProgress}
        onConfirm={onDelete}
        onCancel={() => {
          setShowDeleteModal(false);
          setDeleteInProgress(false);
        }}
    />}
    <CenterCourtModal
        show={selectedCourt != null}
        court={selectedCourt}
        sportsCenterId={sportsCenter.id}
        onHide={() => setSelectedCourt(null)}
        refresh={refresh}
    />
  </>;
}

export default CenterReservationsHalfHour;